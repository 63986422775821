import CryptoJS from 'crypto-js';
import axios from 'axios';

const { REACT_APP_API_URL, REACT_APP_COOKIES_JWT } = process.env;

axios.interceptors.request.use((req) => {
  const JWTCookie = document.cookie
    .split(';')
    .find((cookie) => cookie.indexOf(REACT_APP_COOKIES_JWT) !== -1);
  const JWT = JWTCookie ? JWTCookie.split('=')[1] : '';

  const timestamp = new Date().getTime();
  const origin = 'dashboard';

  const signature = {
    timestamp: `${timestamp}${origin}`,
    origin,
  };

  req.params = {
    ...req.params,
    signature: CryptoJS.SHA1(JSON.stringify(signature)).toString(),
    timestamp,
    app: origin,
  };

  req.url = `${REACT_APP_API_URL}${req.url}`;
  req.headers.authorization = `Bearer ${JWT}`;

  return req;
});

const api = {
  findAll: (endpoint, config = {}) => axios.get(endpoint, config),
  findOne: (endpoint, id) => axios.get(`${endpoint}/${id}`),
  update: (endpoint, id, body) => axios.patch(`${endpoint}/${id}`, body),
  create: (endpoint, body) => axios.post(endpoint, body),
  delete: (endpoint, id) => axios.delete(endpoint + '/' + id),
  deleteWithBody: (endpoint, body) => axios.delete(endpoint, { data: body }),

  login: (body) => axios.post('/auth', body),
  sendPasswordRecoveryEmail: (body) => axios.post('/recover', body),
  verifyPasswordRecoveryToken: (body) => axios.post('/validatetoken', body),
  changePassword: (body) => axios.post('/reset/password', body),

  users: {
    update: (id, body) => axios.patch(`/userupdate/${id}`, body),

    devices: {
      getAll: (id) =>
        axios.get(
          `/users?filter=` +
            JSON.stringify({
              where: {
                usersId: id,
              },
              attributes: [],
              include: ['devices'],
            })
        ),

      create: (body) => axios.post(`/usersdevices`, body),
      delete: (id) => axios.delete(`/usersdevices/${id}`),
    },
  },

  categories: {
    getAll: () =>
      axios.get(
        '/categories?filter=' +
          JSON.stringify({
            order: ['name'],
          })
      ),
    findByName: (name, attributes, where) =>
      axios.get(
        `/categories?filter=${encodeURI(
          JSON.stringify({
            where: {
              name: {
                $iLike: `%${name}%`,
              },
              ...(where ? where : {}),
            },
            ...(attributes ? { attributes } : {}),
          })
        )}`
      ),
  },

  subcategories: {
    getAll: () => axios.get('/subcategories'),
    getByCategory: (categoryId) =>
      axios.get(
        '/subcategories?filter=' +
          JSON.stringify({
            where: {
              fk_categoriesId: categoryId,
            },
            order: ['name'],
          })
      ),
    findByName: (name, attributes, where) =>
      axios.get(
        `/subcategories?filter=${encodeURI(
          JSON.stringify({
            where: {
              name: {
                $iLike: `%${name}%`,
              },
              ...(where ? where : {}),
            },
            ...(attributes ? { attributes } : {}),
          })
        )}`
      ),
  },

  products: {
    rating: {
      getByProduct: (id) =>
        axios.get(
          '/productsratings?filter=' +
            JSON.stringify({
              where: {
                fk_productsId: id,
              },
              include: ['user', 'ratings_images'],
            })
        ),
    },
    findByName: ({ name, attributes, filter, active = true, where }) =>
      axios.get(
        `/products?filter=${encodeURI(
          JSON.stringify({
            where: {
              ...(name ? { name: { $iLike: `%${name}%` } } : {}),
              ...(active ? { active: '1' } : {}),
              ...where,
            },
            ...(attributes ? { attributes } : {}),
            ...(filter ? filter : {}),
          })
        )}`
      ),
    getOne: (id) =>
      axios.get(
        '/products?filter=' +
          JSON.stringify({
            where: {
              productsId: id,
            },
            include: [
              {
                association: 'subcategory',
                attributes: ['name', 'productsSubcategoriesId'],
              },
              {
                association: 'images',
                attributes: ['url', 'productsImagesId', 'order'],
              },
              {
                association: 'category',
                attributes: ['name', 'productsCategoriesId'],
              },
              { association: 'variants', include: 'stock' },
            ],
          })
      ),

    getAll: () => axios.get('/products'),

    create: (body) => axios.post('/products', body),
    update: (id, body) => axios.patch(`/products/${id}`, body),
    deleteOne: (id) => axios.delete(`/products/${id}`),

    importFromCSV: (body) => {
      const bodyFormData = new FormData();
      for (const key in body) {
        bodyFormData.append(key, body[key]);
      }

      return axios.post('/csv/products', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
  },

  productImages: {
    deleteOne: (id) => axios.delete(`/productimages/${id}`),
    createOne: (body) => axios.post('/productimages', body),
  },

  productVariants: {
    deleteOne: (id) => axios.delete(`/productsvariants/${id}`),
    update: (id, body) => axios.patch(`/productsvariants/${id}`, body),
    create: (body) => axios.post(`/productsvariants`, body),

    getOne: (id) => {
      return axios.get(
        '/productsvariants?filter=' +
          JSON.stringify({
            where: {
              productsVariantsId: id,
            },
            include: ['stock', 'product'],
          })
      );
    },
  },

  branchOffices: {
    getByStore: (storeId) => {
      return axios.get(
        '/storesbranches?filter=' +
          JSON.stringify({
            where: {
              fk_storesId: storeId,
            },
            include: [
              {
                association: 'state',
                attributes: ['name'],
              },
              {
                association: 'city',
                attributes: ['name'],
              },
            ],
          })
      );
    },

    create: (body) => axios.post('/storesbranches', body),
    getOne: (id) => axios.get(`/storesbranches/${id}`),
    update: (id, body) => axios.patch(`/storesbranches/${id}`, body),
    delete: (id) => axios.delete(`/storesbranches/${id}`),
  },

  defaultValues: {
    getAll: () => axios.get('/defaultvalues'),
  },

  variantsStocks: {
    create: (body) => axios.post('/productsvariantsstocks', body),
    update: (id, body) => axios.patch(`/productsvariantsstocks/${id}`, body),
    delete: (id) => axios.delete(`/productsvariantsstocks/${id}`),
  },

  orders: {
    update: (id, body) => axios.patch(`/orders/${id}`, body),

    getAll: () => {
      return axios.get(
        '/orders?filter=' +
          JSON.stringify({
            include: [
              {
                association: 'user',
                attributes: ['firstname', 'lastname'],
              },
              {
                association: 'details',
              },
            ],
          })
      );
    },

    getByStore: (storeId, dateRange = null) => {
      return axios.get(
        '/orders?filter=' +
          JSON.stringify({
            include: [
              {
                association: 'user',
                attributes: ['firstname', 'lastname'],
              },
              {
                association: 'details',
                where: {
                  fk_storesId: storeId,
                  ...(dateRange
                    ? {
                        createdAt: {
                          $gt: dateRange.gt,
                          $lt: dateRange.lt,
                        },
                      }
                    : null),
                },
                attributes: [
                  'ordersDetailsId',
                  'name',
                  'qty',
                  'image',
                  'price',
                  'discount',
                  'fk_purchaseStatusesId',
                ],
                include: [
                  {
                    association: 'variant',
                    attributes: [
                      'image',
                      'sku',
                      'values',
                      'price',
                      'discount',
                      'productsVariantsId',
                    ],
                    include: [
                      {
                        association: 'product',
                        attributes: [
                          'name',
                          'hasVariant',
                          'isFragile',
                          'freeShipping',
                        ],
                        include: [
                          {
                            association: 'images',
                            attributes: ['url'],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    association: 'store_branch',
                    attributes: ['name'],
                  },
                ],
              },
            ],
          })
      );
    },

    statusLog: {
      create: (body) => axios.post('/ordershistories', body),
    },
  },

  ordersDetails: {
    update: (id, body) => axios.patch(`/orderdetails/${id}`, body),

    getByStore: (id, filters) => {
      const { dateRange, status } = filters || {};

      return axios.get(
        '/orderdetails?filter=' +
          JSON.stringify({
            where: {
              fk_storesId: id,
              ...(dateRange
                ? {
                    createdAt: {
                      $gt: dateRange.gt,
                      $lt: dateRange.lt,
                    },
                  }
                : null),
              ...(status
                ? {
                    fk_purchaseStatusesId: status,
                  }
                : null),
            },
            include: [
              {
                association: 'order',
                include: ['user', 'orders_histories'],
              },
              {
                association: 'variant',
                include: [
                  {
                    association: 'product',
                    include: ['category'],
                  },
                ],
              },
            ],
          })
      );
    },

    statusLog: {
      create: (body) => axios.post(`/ordersdetailshistories`, body),
    },
  },

  stores: {
    rating: {
      getByStore: (id) =>
        axios.get(
          '/storesratings?filter=' +
            JSON.stringify({
              where: {
                fk_storesId: id,
              },
              include: ['user'],
            })
        ),
    },
    getOne: (id) => axios.get(`/stores/${id}`),
    update: (id, body) => axios.patch(`/stores/${id}`, body),
    findByName: (name, attributes, include, where) =>
      axios.get(
        `/stores?filter=${encodeURI(
          JSON.stringify({
            where: {
              name: {
                $iLike: `%${name}%`,
              },
              active: true,
              ...(where ? where : {}),
            },
            ...(include ? include : {}),
            ...(attributes ? { attributes } : {}),
          })
        )}`
      ),
  },

  storeBanners: {
    getByStore: (id) =>
      axios.get(
        '/storesbanners?filter=' +
          JSON.stringify({
            where: {
              fk_storesId: id,
            },
          })
      ),

    create: (body) => axios.post('/storesbanners', body),
    deleteOne: (id) => axios.delete(`/storesbanners/${id}`),
  },

  states: {
    getByCountry: (countryId) =>
      axios.get(
        '/states?filter=' +
          JSON.stringify({
            where: {
              fk_countriesId: countryId,
            },
          })
      ),
  },

  cities: {
    getByState: (stateId) =>
      axios.get(
        '/cities?filter=' +
          JSON.stringify({
            where: {
              fk_statesId: stateId,
            },
          })
      ),
  },

  payment: {
    getPaymentLink: (id) => axios.get(`/paymentlink/${id}`),
    getPaymentStore: (filter) =>
      axios.get(`/paymentstores?filter=${JSON.stringify(filter)}`),
    createPaymentLink: (body) => axios.post('/paymentlink', body),
    getPaymentVouchers: (filter) =>
      axios.get(`/paymentvouchers?filter=${JSON.stringify(filter)}`),
    getPaymentVouchersCSVReport: (body) => axios.post('/vouchers-report', body, { responseType: 'blob' }), 
    getPaymentVouchersPDFReport: (body) => axios.post('/transactions-report', body, { responseType: 'blob' }), 
    getPaymentVouchersByDate: ({
      paymentStoreId,
      status,
      end,
      start,
      limit,
      attributes,
    }) =>
      axios.get(
        `/paymentvouchers?filter=${JSON.stringify({
          where: {
            npe: { $is: null },
            fk_paymentStoresId: paymentStoreId,
            ...(status && { status }),
            ...((end || start) && {
              createdAt: {
                ...(start != null
                  ? {
                      $gt: new Date(start).toISOString(),
                    }
                  : {}),
                ...(end != null
                  ? {
                      $lt: new Date(end).toISOString(),
                    }
                  : {}),
              },
            }),
          },
          ...(limit && { limit }),
          ...(attributes && { attributes }),
          order: [['createdAt', 'desc']],
        })}`
      ),
    createOpenLink: (body) => axios.post('/vouchertemplates', body),
    getOpenLink: (id) =>
      axios.get(`/vouchertemplates/${id}`, { params: { onlyqr: true } }),
    updateOpenLink: (id, body) => axios.patch(`/vouchertemplates/${id}`, body),
  },

  recurringPayments: {
    getRecurringPayment:(id) => axios.get(`/invoicing/${id}`),
    createRecurringPayment: (body) => axios.post('/invoicing', body),
    resendInvoice: (id) => axios.post('/invoicing/resend', { id }),
    updateRecurringPayment: (id, body) => axios.patch(`/invoicing/${id}`, body),
  },

  employees: {
    create: (body) => axios.post('/employees/generate', body),
    login: (body) => axios.post('/employees/auth', body),
    delete: (id) => axios.delete(`/employees/${id}`),
    getAll: (body) => axios.post('/employees/list', body),
  },

  purchasestatuses: {
    getAll: () =>
      axios.get(
        `/purchasestatuses?filter=${JSON.stringify({
          attributes: ['purchaseStatusesId', 'name'],
        })}`
      ),
  },

  paymentMethods: {
    getByType: (type) =>
      axios.get(
        `/paymentmethods?filter=${JSON.stringify({
          where: {
            type,
            active: true,
          },
        })}`
      ),
    getByID: (id) => axios.get(`/paymentmethods/${id}`),
    switchPaymentGateway: (gateway,userId) => axios.patch('/paymentmethods/1', {
      gateway,
      updatedBy: userId,
    }),
  },

  oneSignal: {
    sendNotification: (body) => axios.post('/onesignal/send', body),
    notify: (body) => axios.post('/onesignal/send/notify', body),
  },

};

export default api;
