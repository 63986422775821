import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

import { STATUS } from '../assets/resources/status';
import api from '../utils/api';
import { formatTimestamp, getOrderCode } from '../utils/misc';
import colors from '../assets/resources/colors';

import Tabs from 'components/Tabs/Tabs';
import Table from 'components/Table/Table';
import ReturnApproval from 'components/ReturnApproval/ReturnApproval';

const returnsTable = {
  options: {
    id: 'ordersDetailsId',
  },
  columns: [
    { text: '', key: 'image', className: 'text-center', isImage: true },
    { text: 'Producto', key: 'name', className: 'text-center' },
    { text: 'Código Orden', key: 'orderCode', className: 'text-center' },
    {
      text: 'Aprobar',
      isChoice: true,
      className: 'text-center',
      hidden: status => ![STATUS.REFUND_PENDING, STATUS.RETURN_PENDING].includes(status),
    },
    { text: 'Comprador', key: 'buyer', className: 'text-center' },
    { text: 'Dirección de envío', key: 'deliveryAddress', className: 'text-center' },
    { text: 'Departamento', key: 'state', className: 'text-center' },
    { text: 'Fecha', key: 'updatedAtDisplay', className: 'text-center' },
    { text: 'Estado', key: 'status', className: 'text-center', isStatus: true },
  ],
};

function Returns({ onlyPending, onTotalChange }) {
  const { REACT_APP_TITLE, REACT_APP_COOKIES_STORE_ID, REACT_APP_COOKIES_USER_ID } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_STORE_ID, REACT_APP_COOKIES_USER_ID]);

  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  /* Set page title */
  useEffect(() => {
    document.title = `Devoluciones | ${REACT_APP_TITLE}`;
  }, []);

  const [returnInView, setReturnInView] = useState();
  const [returnInViewType, setReturnInViewType] = useState();
  const [activeTab, setActiveTab] = useState('returns');
  const [returns, setReturns] = useState();
  const [getReturns, setGetReturns] = useState(1);
  const [refunds, setRefunds] = useState();
  const [isSaving, setIsSaving] = useState(false);

  /* Get returns and refunds */
  useEffect(() => {
    api.ordersDetails
      .getByStore(cookies[REACT_APP_COOKIES_STORE_ID], { status: STATUS.FOR_RETURNS.concat(STATUS.FOR_REFUNDS) })
      .then(res => {
        let data = res.data.map(detail => {
          const { variant, order, updatedAt, fk_purchaseStatusesId } = detail;
          const { apartment, address, city, referencePoint, state, user, ordersId } = order;
          const { firstname, lastname } = user;

          return {
            ...detail,
            orderId: ordersId,
            orderCode: getOrderCode(ordersId),
            image: variant.image,
            buyer: `${firstname} ${lastname}`,
            deliveryAddress: `${apartment} ${address} ${city} ${referencePoint}`,
            updatedAtDisplay: formatTimestamp(updatedAt),
            state,
            status: fk_purchaseStatusesId,
          };
        });

        if (onlyPending) {
          data = data.filter(order => [STATUS.REFUND_PENDING, STATUS.RETURN_PENDING].includes(order.status));
          data = data.sort((a, b) => (a.orderId > b.orderId ? 1 : a.orderId < b.orderId ? -1 : 0));
        }

        onTotalChange && onTotalChange(data.length);

        const returns = data.filter(detail => STATUS.FOR_RETURNS.includes(detail.fk_purchaseStatusesId));
        const refunds = data.filter(detail => STATUS.FOR_REFUNDS.includes(detail.fk_purchaseStatusesId));

        setReturns(returns);
        setRefunds(refunds);
      })
      .catch(err => {
        console.error(err);
        toast.warning(`[SERVER_ERROR] ${err}`);
      });
  }, [getReturns]);

  const updateReturnStatus = (returnId, val) => {
    setIsSaving(true);

    const messages = {
      [STATUS.REFUND_APPROVED]: { text: 'Reembolso aprobado', type: 'success' },
      [STATUS.REFUND_DENIED]: { text: 'Reeembolso denegado', type: 'warning' },
      [STATUS.RETURN_APPROVED]: { text: 'Devolución aprobada', type: 'success' },
      [STATUS.RETURN_DENIED]: { text: 'Devolución denegada', type: 'warning' },
    };

    api.ordersDetails
      .update(returnId, {
        fk_purchaseStatusesId: val,
        updatedBy: userID,
      })
      .then(() => {
        return api.ordersDetails.statusLog.create({
          fk_purchaseStatusesId: val,
          fk_ordersDetailsId: returnId,
          createdBy: userID,
        });
      })
      .then(() => {
        const { type, text } = messages[val];
        toast[type](text);
      })
      .catch(err => {
        console.error(err);
        toast.warning(`[SERVER_ERROR] ${err}`);
      })
      .finally(() => {
        setGetReturns(getReturns + 1);
        setReturnInView(null);
        setIsSaving(false);
      });
  };

  return (
    <div className='content'>
      {!onlyPending && (
        <div className='row'>
          <div className='col-12'>
            <h3 className='text-dark-blue font-size-2x font-weight-bold'>Devoluciones y reembolsos</h3>
          </div>
        </div>
      )}
      <div className={`row ${!onlyPending ? 'mt-4' : ''}`}>
        <div className='col-12'>
          <div>
            <Tabs
              onChange={tab => setActiveTab(tab)}
              tabs={[
                { text: 'Devoluciones', key: 'returns' },
                { text: 'Reembolsos', key: 'refunds' },
              ]}
              activeTab={activeTab}
            />
            <div className='row mt-4'>
              <div className='col-12'>
                {returns ? (
                  <Table
                    columns={returnsTable.columns}
                    data={activeTab === 'returns' ? returns : refunds}
                    onChoiceChange={(orderDetailId, newVal) =>
                      updateReturnStatus(
                        orderDetailId,
                        newVal
                          ? activeTab === 'returns'
                            ? STATUS.RETURN_APPROVED
                            : STATUS.REFUND_APPROVED
                          : activeTab === 'returns'
                          ? STATUS.RETURN_DENIED
                          : STATUS.REFUND_DENIED
                      )
                    }
                    onRowClick={id => {
                      const arrayToSearch = activeTab === 'returns' ? returns : refunds;
                      setReturnInView({ ...arrayToSearch.find(item => item.ordersDetailsId === id) });
                      setReturnInViewType(activeTab === 'returns' ? 'return' : 'refund');
                    }}
                    options={returnsTable.options}
                    pageSize={10}
                    isSaving={isSaving}
                  />
                ) : (
                  <div className='p-5 m-5 d-flex justify-content-center align-items-center' style={{ flexGrow: '1' }}>
                    <ClimbingBoxLoader color={colors.green} size='25' />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {returnInView && (
        <ReturnApproval
          type={returnInViewType}
          returnInView={returnInView}
          updateReturnStatus={val => {
            updateReturnStatus(returnInView.ordersDetailsId, val);
          }}
          onCloseRequest={() => setReturnInView(null)}
        />
      )}
    </div>
  );
}
export default Returns;
