import React, { useEffect, useState } from 'react';

import addSVG from 'assets/icons/add-black.svg';
import deleteSVG from 'assets/icons/delete.svg';

function ProductAttributes({onProductAttributeChange, onProductAttributeDelete}) {

  const [attributes, setAttributes] = useState([{attributes: "", values: ""}]);

  useEffect(() =>{
    if (onProductAttributeChange) {
      onProductAttributeChange(attributes)
    }
  }, [attributes]);

  const addAttribute = () =>{
    setAttributes([...attributes, {attributes: "", values: ""}]);
  };

  const deleteProductAttributes = (index) =>{
    setAttributes([
      ...attributes.slice(0, index),
      ...attributes.slice(index + 1)
    ])
  }

  const setAttributeValue = (value, attributeIndex, key) =>{
    setAttributes([
      ...attributes.slice(0, attributeIndex),
      {
        ...attributes[attributeIndex],
        [key]: value
      },
      ...attributes.slice(attributeIndex + 1),
    ]);
  }

  return (
    attributes &&
    <div style={{ overflowX: 'auto' }}>
      <div className="d-flex">
        <div className="d-inline-block mt-3" style={{ overflow: 'auto', width: '100%', scrollBehavior: 'smooth', paddingBottom: '0.5rem' }}>
          <div className="d-flex bg-beige py-2" style={{ borderBottomLeftRadius: '0.5rem', borderTopLeftRadius: '0.5rem', overflow: 'show' }}>
            <div className="d-inline-block mt-3 subcategories-table" style={{ overflow: 'auto', width: '100%' }}>
              <div className="d-flex bg-beige py-2 font-weight-bold text-black" style={{ borderBottomRightRadius: '0.5rem', borderTopRightRadius: '0.5rem' }}>
                <div style={{ flexBasis: '20%', flexGrow: '1' }} className="d-flex justify-content-center align-items-center">
                  Atributo
                </div>
                <div style={{ flexBasis: '70%', flexGrow: '1' }} className="d-flex justify-content-center align-items-center">
                  Valores
                </div>
                <div style={{ flexBasis: '10%', flexGrow: '1' }} className="px-2 d-flex align-items-center justify-content-center">
                  <button
                    type="button"
                    className="p-1 opt-btn"
                    data-tip="Añadir atributo"
                    onClick={addAttribute}
                  >
                    <img src={addSVG} alt="" style={{ height: '16px' }} />
                  </button>
                </div>
              </div>
              {
                attributes.map((attr, index) =>{
                  return(
                    <div key={'attribute-' + index} className="py-2 d-flex w-100">
                      <div style={{ flexBasis: '20%', flexGrow: '1' }} className="px d-flex align-items-center-2">
                        <input
                          type="text"
                          className="rounded p-1 w-100"
                          value={attr.attributes}
                          placeholder="Atributo"
                          onChange={(e) => { setAttributeValue(e.target.value, index, 'attributes') }}
                        />
                      </div>
                      <div style={{ flexBasis: '70%', flexGrow: '1' }} className="px-2 d-flex align-items-center">
                        <input
                          type="text"
                          name="values"
                          className="rounded p-1 w-100"
                          placeholder="Escriba cada valor separado por una coma. Ej: rojo, azul, amarillo"
                          value={attr.values}
                          onChange={(e) => { setAttributeValue(e.target.value, index, 'values') }}
                        />
                      </div>
                      <div style={{ flexBasis: '10%', flexShrink: '1' }} className="d-flex justify-content-center align-items-center">
                        <button
                          type="button"
                          className="p-2 opt-btn"
                          data-tip="Eliminar atributo"
                          onClick={() => deleteProductAttributes(index)}
                        >
                          <img src={deleteSVG} alt="" style={{ height: '16px' }} />
                        </button>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductAttributes;