import React, { useEffect, useState } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import PaginatedTable from 'components/TableWithPagination/Table';
import ReactTooltip from 'react-tooltip';
import SweetAlert from 'react-bootstrap-sweetalert';
import { VOUCHER_ICONS } from 'utils/misc';
import { VOUCHER_STATUS } from 'utils/misc';
import api from 'utils/api';
import { formatPrice } from 'utils/misc';
import { getOrderCode } from 'utils/misc';
import { parseDate } from 'utils/misc';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import PaymentReport from 'components/ReportGenerator';

function Vouchers() {
  const {
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  } = process.env;
  const [cookies] = useCookies([
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  ]);
  const [modal, setModal] = useState({
    isOpen: false,
    data: null,
  });

  const tableProps = {
    rowId: 'paymentVouchersId',
    baseURL: '/paymentvouchers',
    baseFilter: {
      where: {
        fk_paymentStoresId: cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID],
      },
      // attributes: [
      //   'paymentVouchersId',
      //   'amount',
      //   'ref',
      //   'createdAt',
      //   'status',
      //   'store',
      //   'firstname',
      //   'lastname',
      //   'email',
      // ],
      order: [['createdAt', 'desc']],
    },
    pageSize: 15,
    columns: [
      {
        title: 'Estado',
        objectProperty: 'status',
        searchable: true,
        searchType: 'select',
        searchOptions: [
          {
            value: '',
            label: 'TODOS',
          },
          ...Object.keys(VOUCHER_STATUS).map((key) => ({
            value: key,
            label: VOUCHER_STATUS[key],
          })),
        ],
        middleware: (status) => (
          <div
            style={{
              backgroundColor: VOUCHER_ICONS[status].color,
              fontWeight: 600,
            }}
            className="rounded p-1 text-white text-center w-100"
          >
            {VOUCHER_STATUS[status] || ''}
          </div>
        ),
      },
      {
        title: 'Código',
        objectProperty: 'paymentVouchersId',
        searchable: true,
        searchType: 'number-equal',
        sortable: true,
        middleware: (value) => `PTY-${getOrderCode(value)}`,
        searchMiddleware: (searchTerm) =>
          parseInt(searchTerm.replace(/PTY-/, ''), 16) / 64,
      },
      {
        title: 'Cantidad',
        objectProperty: 'amount',
        searchable: true,
        searchType: 'number',
        middleware: (value) => formatPrice(value),
      },
      {
        title: 'Concepto',
        objectProperty: 'concept',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
      {
        title: 'Fecha Creación',
        objectProperty: 'createdAt',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (createdAt) => parseDate(createdAt),
      },
      {
        title: 'Fecha Pagado',
        objectProperty: 'payedAt',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (payedAt) => (payedAt ? parseDate(payedAt) : 'N/A'),
      },
      {
        title: 'Nombre',
        objectProperty: 'firstname',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Apellido',
        objectProperty: 'lastname',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Teléfono',
        objectProperty: 'phone',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Email',
        objectProperty: 'email',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Acción',
        middleware: (data) => {
          const userId = cookies[REACT_APP_COOKIES_USER_ID];

          if (data?.status !== 'COMPLETED' && +data?.createdBy === +userId) {
            return (
              <button
                data-tip="Ver link de pago"
                onClick={() => getPaymentVoucher(data.paymentVouchersId)}
              >
                <i className="text-center material-icons">launch</i>
                <ReactTooltip />
              </button>
            );
          }
        },
      },
    ],
  };

  function getPaymentVoucher(id) {
    api.payment
      .getPaymentLink(id)
      .then((res) => {
        setModal({
          isOpen: true,
          data: res.data,
        });
      })
      .catch((error) => {
        toast.error('Error al generar el link de pago');
      });
  }

  const closeModal = () => setModal({ isOpen: false, data: null });

  // Set title
  useEffect(() => {
    document.title = `Historial de comprobantes | ${process.env.REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content d-flex flex-column" style={{ flexGrow: 1 }}>
      {modal.isOpen && (
        <SweetAlert
          showConfirm={false}
          onConfirm={closeModal}
          onCancel={closeModal}
        >
          <div>
            <h2>Link de pago</h2>
            <div className="row">
              <div className="col-12 mb-4">
                <CopyToClipboard
                  text={`${modal.data?.link}`}
                  onCopy={() => toast.success('Link copiado')}
                >
                  <div
                    style={{
                      backgroundColor: '#26DB9480',
                      cursor: 'pointer',
                    }}
                    className="px-2 py-4 mx-2 mt-4 rounded-lg row flex justify-content-center align-items-center"
                  >
                    <p
                      style={{
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                      }}
                      className="col-10"
                    >
                      {`${modal.data?.link}`}
                    </p>
                    <div className=" col-2 ">
                      <i className="material-icons text-left">content_copy</i>
                    </div>
                  </div>
                </CopyToClipboard>
              </div>
              <img
                style={{
                  width: 300,
                  height: 300,
                  objectFit: 'contain',
                }}
                className="col-12"
                src={modal.data?.qr}
                alt="qr"
              />
            </div>
          </div>
        </SweetAlert>
      )}
      <div className="row">
        <div className="col-12 col-lg-8 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Historial de comprobantes
          </h3>
        </div>
      </div>
      <PaymentReport className="my-4" />
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          <div className="pt-md-2">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vouchers;
