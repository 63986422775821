import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import download from 'downloadjs';

import { useCookies } from 'react-cookie';
import { BounceLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import api from 'utils/api';
import { getStoreNameFromJWT } from 'utils/misc';

function PaymentReport({ className }) {
  const {} = process.env;
  const { REACT_APP_COOKIES_PAYMENT_STORE_ID, REACT_APP_COOKIES_JWT } =
    process.env;

  const [cookies] = useCookies([
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
    REACT_APP_COOKIES_JWT,
  ]);
  const paymentStoreId = cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID];

  const openDownloadLink = ({ url, type }) => {
    const link = document.createElement('a');

    link.href = url;
    link.download = `payment-report.${type}`;
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const onSubmit = (values, { setSubmitting }) => {
    const jwt = cookies[REACT_APP_COOKIES_JWT];
    const storeName = getStoreNameFromJWT(jwt);

    const { dateLast, dateInit } = values;

    const _dateLast = new Date(dateLast);
    const _dateInit = new Date(dateInit);

    _dateInit.setDate(_dateInit.getDate() + 1);
    _dateInit.setHours(0, 0, 0, 0);

    _dateLast.setDate(_dateLast.getDate() + 1);
    _dateLast.setHours(23, 59, 59, 999);

    const { type } = values;
    if (type === 'csv') {
      api.payment
        .getPaymentVouchersCSVReport({
          storeId: +paymentStoreId,
          dateInit: _dateInit.toISOString(),
          dateLast: _dateLast.toISOString(),
          storeName,
        })
        .then((res) => {
          const now = Date.now();
          setSubmitting(false);
          download(
            res.data,
            `${now}-reporte-de-pagos.csv`,
            res.headers['content-type']
          );
        })
        .catch((err) => {
          setSubmitting(false);
          console.error(err);
          toast.warning(
            'Hubo un error al generar el reporte, por favor intenta de nuevo'
          );
        });
    } else {
      api.payment
        .getPaymentVouchersPDFReport({
          startDate: _dateInit.toISOString(),
          endDate: _dateLast.toISOString(),
          paymentStoresId: +paymentStoreId,
        })
        .then((res) => {
          const now = Date.now();
          setSubmitting(false);
          download(
            res.data,
            `${now}-reporte-de-pagos.pdf`,
            res.headers['content-type']
          );
        })
        .catch((err) => {
          setSubmitting(false);
          console.error(err);
          toast.warning(
            'Hubo un error al generar el reporte, por favor intenta de nuevo'
          );
        });
    }
  };

  const onValidate = (values) => {
    const errors = {};

    if (!values.dateInit) errors.dateInit = 'Requerido';
    if (!values.dateLast) errors.dateLast = 'Requerido';

    return errors;
  };

  return (
    <Formik
      initialValues={{
        dateInit: '',
        dateLast: '',
        type: 'csv',
      }}
      onSubmit={onSubmit}
      validate={onValidate}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <div className={className}>
            <p className="text-dark-blue font-weight-bold font-size-125x mb-2">
              Reporte de pagos
            </p>
            <div className="row">
              <div className="col-12 col-md-5 d-flex flex-column">
                <label htmlFor="dateInit">Inicio</label>
                <Field className="rounded p-2" type="date" name="dateInit" />
                <ErrorMessage
                  name="dateInit"
                  component="span"
                  className="text-danger"
                />
              </div>
              <div className="col-12 col-md-5 d-flex flex-column">
                <label htmlFor="dateLast">Final</label>
                <Field className="rounded p-2" type="date" name="dateLast" />
                <ErrorMessage
                  name="dateLast"
                  component="span"
                  className="text-danger"
                />
              </div>
              <div className="col-12 col-md-2 d-flex flex-column">
                <label>Tipo</label>
                <div>
                  <Field id="csv-type" type="radio" name="type" value="csv" />
                  <label className="mx-1" htmlFor="csv-type">
                    CSV
                  </label>
                </div>

                <div>
                  <Field id="pdf-type" type="radio" name="type" value="pdf" />
                  <label className="mx-1" htmlFor="pdf-type">
                    PDF
                  </label>
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting || !dirty || !isValid}
              className="w-100 bg-purple tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around mt-2"
            >
              {isSubmitting ? 'Generando reporte...' : 'Generar reporte'}
              <BounceLoader color="#fff" loading={isSubmitting} size="18" />
            </button>
            <hr />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default PaymentReport;
