import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import BounceLoader from 'react-spinners/BounceLoader';
import Switch from 'react-switch';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import Card from '../components/Card/Card';
import PasswordInput from '../components/PasswordInput/PasswordInput';

import api from 'utils/api';
import { toast } from 'react-toastify';
import { ENVIROMENTS } from 'utils/misc';
import ReactTooltip from 'react-tooltip';

const formTemplate = {
  currentPassword: {
    value: '',
    touched: false,
    errors: [],
  },
  newPassword: {
    minLength: 10,
    value: '',
    touched: false,
    errors: [],
  },
  passwordConfirmation: {
    minLength: 10,
    value: '',
    touched: false,
    errors: [],
    equalsTo: 'newPassword',
  },
};

function PaySettings() {
  const {
    REACT_APP_TITLE,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
  } = process.env;

  const [cookies] = useCookies([
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
  ]);

  useEffect(() => {
    document.title = `Ajustes | ${REACT_APP_TITLE}`;
  }, []);

  const [form, setForm] = useState({ ...formTemplate });
  const [isSaving, setIsSaving] = useState(false);
  const [employees, setEmployees] = useState([]);

  const validEnv = cookies[process.env.REACT_APP_VALID_ENVIROMENTS];

  const storeId =
    cookies[REACT_APP_COOKIES_STORE_ID] != null
      ? +cookies[REACT_APP_COOKIES_STORE_ID]
      : null;
  const paymentStoreId =
    cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID] != null
      ? +cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID]
      : null;

  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = () => {
    api.employees
      .getAll({
        fk_storesId: storeId,
        fk_paymentStoresId: paymentStoreId,
      })
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((err) => {
        console.error(err);
        toast.warning('[SERVER_ERROR] No se pudo obtener los empleados');
      });
  };

  const onChange = (field, value) => {
    const tmpForm = { ...form };

    tmpForm[field].value = value;

    tmpForm[field] = {
      ...tmpForm[field],
      errors: validateInput(field),
    };

    setForm({ ...tmpForm });
  };

  const validateInput = (field) => {
    const errors = [];
    const { value, minLength, equalsTo } = form[field];

    if (!value.trim()) {
      errors.push('Campo requerido');
    } else if (minLength && minLength > value.length) {
      errors.push(`Longitud mínima: ${minLength}`);
    } else if (equalsTo && value !== form[equalsTo].value) {
      errors.push('Contraseñas no coinciden');
    }

    return errors;
  };

  const onBlur = (field) => {
    const tmpForm = { ...form };

    tmpForm[field] = {
      ...tmpForm[field],
      touched: true,
      errors: validateInput(field),
    };

    setForm({ ...tmpForm });
  };

  const save = (event) => {
    event.preventDefault();

    const tmpForm = { ...form };
    let valid = true;

    for (const input in tmpForm) {
      tmpForm[input] = {
        ...tmpForm[input],
        touched: true,
        errors: validateInput(input),
      };
    }

    setForm({ ...tmpForm });
    for (const input in tmpForm) {
      if (tmpForm[input].errors.length > 0) {
        valid = false;
      }
    }

    if (!valid) {
      return;
    }

    setIsSaving(true);

    api.users
      .update(cookies[REACT_APP_COOKIES_USER_ID], {
        confirmation: form.currentPassword.value,
        password: form.newPassword.value,
        updatedBy: cookies[REACT_APP_COOKIES_USER_ID],
      })
      .then(() => {
        toast.success('¡Contraseña actualizada!');
        setForm({ ...formTemplate });
      })
      .catch((err) => {
        console.error(err);
        toast.warning('[SERVER_ERROR] No se pudo actualizar la contraseña');
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    api.employees
      .create({
        ...values,
        active: '1',
        createdBy: cookies[REACT_APP_COOKIES_USER_ID],
        fk_rolesId: 9,
        password: values.password ? values.password : null,
        fk_storesId: values.useMarketplace ? storeId : null,
        fk_paymentStoresId: values.usePay ? paymentStoreId : null,
      })
      .then(() => {
        toast.success('Empleado creado con éxito');
        resetForm();
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo crear el usuario');
      })
      .finally(() => {
        setSubmitting(false);
        getEmployees();
      });
  };

  return (
    <div className="content">
      <div className="row">
        <div className="col-12 mb-4">
          <p className="text-dark-blue font-size-2x font-weight-bold w-100 text-center text-md-left">
            Ajustes
          </p>
        </div>

        {/* employees */}
        <Card className="p-4 mx-3">
          <div className="col-12 row mx-0 p-0">
            <div className="col-12 col-md-6">
              <h4 className="font-weight-bold">Nuevo usuario empleado</h4>
              <Formik
                initialValues={{
                  firstname: '',
                  lastname: '',
                  password: null,
                  gender: '',
                  email: '',
                  useMarketplace: true,
                  usePay: true,
                }}
                enableReinitialize={true}
                validate={(values) => {
                  const errors = {};

                  if (!values.firstname) errors.firstname = 'Campo requerido';
                  if (!values.lastname) errors.lastname = 'Campo requerido';
                  if (!values.email) errors.email = 'Campo requerido';

                  if (values.useMarketplace == false && values.usePay == false)
                    errors.useMarketplace =
                      'Al menos uno de los dos campos debe estar seleccionado';

                  return errors;
                }}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="row my-3 space-y-3">
                      <div className="col-12 d-flex flex-column">
                        <label htmlFor="firstname">Nombre</label>
                        <Field
                          className="rounded p-2"
                          type="text"
                          name="firstname"
                        />
                        <ErrorMessage
                          className="text-red"
                          name="firstname"
                          component="span"
                        />
                      </div>

                      <div className="col-12 d-flex flex-column">
                        <label htmlFor="lastname">Apellido</label>
                        <Field
                          className="rounded p-2"
                          type="text"
                          name="lastname"
                        />
                        <ErrorMessage
                          className="text-red"
                          name="lastname"
                          component="span"
                        />
                      </div>

                      <div className="col-12 d-flex flex-column">
                        <label htmlFor="email">Email</label>
                        <Field
                          className="rounded p-2"
                          type="email"
                          name="email"
                        />
                        <ErrorMessage
                          className="text-red"
                          name="email"
                          component="span"
                        />
                      </div>

                      {[ENVIROMENTS.MARKETPLACE, ENVIROMENTS.BOTH].includes(
                        validEnv
                      ) && (
                        <div className="col-6 d-flex flex-column">
                          <label
                            htmlFor="useMarketplace"
                            className="text-center"
                          >
                            ¿Puede usar el marketplace?
                          </label>
                          <Field
                            className="rounded p-2"
                            type="checkbox"
                            name="useMarketplace"
                          />
                          <ErrorMessage
                            className="text-red"
                            name="useMarketplace"
                            component="span"
                          />
                        </div>
                      )}

                      {[ENVIROMENTS.PAYMENT, ENVIROMENTS.BOTH].includes(
                        validEnv
                      ) && (
                        <div className="col-6 d-flex flex-column">
                          <label htmlFor="usePay" className="text-center">
                            ¿Puede usar el módulo de pagos?
                          </label>
                          <Field
                            className="rounded p-2"
                            type="checkbox"
                            name="usePay"
                          />
                          <ErrorMessage
                            className="text-red"
                            name="usePay"
                            component="span"
                          />
                        </div>
                      )}

                      <div className="col-12 d-flex flex-column">
                        <label htmlFor="gender">Género</label>
                        <Field
                          className="rounded p-2"
                          type="text"
                          as="select"
                          name="gender"
                        >
                          <option value="">Seleccionar</option>
                          <option value="M">Masculino</option>
                          <option value="F">Femenino</option>
                          <option value="O">Otro</option>
                        </Field>
                      </div>

                      <div className="col-12 d-flex flex-column">
                        <label htmlFor="password">Contraseña</label>
                        <Field
                          className="rounded p-2"
                          type="password"
                          name="password"
                        />
                        <ErrorMessage
                          className="text-red"
                          name="password"
                          component="span"
                        />
                      </div>

                      <div className="col-12 d-flex flex-column">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          className="bg-green w-100 rounded tuyo-btn font-weight-bold text-white py-2 mt-3 d-flex align-items-center justify-content-around"
                        >
                          {isSubmitting ? 'Creando' : 'Crear usuario'}
                          <BounceLoader
                            color="#fff"
                            loading={isSubmitting}
                            size="18"
                          />
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="col-12 col-md-6">
              <h4 className="font-weight-bold">Empleados</h4>
              <div className="my-3">
                <table className="w-full mx-0">
                  <thead className="border-bottom">
                    <tr>
                      <th className="p-1">Usuario</th>
                      <th className="p-1">Nombre</th>
                      <th className="p-1">
                        <i
                          data-tip="Puede usar el modúlo de marketplace"
                          className="material-icons text-center m-1"
                        >
                          shopping_bag
                        </i>
                        <ReactTooltip effect="solid" />
                      </th>
                      <th className="p-1">
                        <i
                          data-tip="Puede usar el módulo de pagos"
                          className="material-icons text-center m-1"
                        >
                          payments
                        </i>
                        <ReactTooltip effect="solid" />
                      </th>
                      <th className="p-1" />
                    </tr>
                  </thead>
                  <tbody>
                    {employees.map((user) => (
                      <tr className="border-bottom">
                        <td className="p-1">{user.username}</td>
                        <td className="p-1 whitespace-break-spaces">
                          {`${user.firstname} \n ${user.lastname}`}
                        </td>
                        <td className="p-1">
                          <i className="material-icons text-center m-1">
                            {user.fk_storesId != null ? 'check' : 'close'}
                          </i>
                        </td>
                        <td className="p-1">
                          <i className="material-icons text-center m-1">
                            {user.fk_paymentStoresId != null
                              ? 'check'
                              : 'close'}
                          </i>
                        </td>
                        <td className="p-1">
                          <button
                            className="tuyo-btn bg-red w-8 h-8 flex justify-center items-center rounded"
                            onClick={() => {
                              api.employees
                                .delete(user.employeesId)
                                .then(() => {
                                  toast.success('Usuario eliminado');
                                })
                                .catch((error) => {
                                  console.error(error);
                                  toast.warning(
                                    'No se pudo eliminar el usuario'
                                  );
                                })
                                .finally(() => {
                                  getEmployees();
                                });
                            }}
                          >
                            <i className="material-icons text-white text-center text-lg">
                              delete
                            </i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card>

        {/* change password */}
        <div className="col-12 col-md-6 col-lg-5 col-xl-4 mt-3">
          <Card className="p-4">
            <h4 className="font-weight-bold">Cambiar contraseña</h4>
            <form onSubmit={(event) => save(event)}>
              <label htmlFor="currentPassword" className="mt-3">
                Contraseña actual
              </label>
              <PasswordInput
                value={form.currentPassword.value}
                id="currentPassword"
                autoComplete="current-password"
                onChange={(value) => onChange('currentPassword', value)}
                onBlur={() => onBlur('currentPassword')}
              />
              {form.currentPassword.errors.length > 0 &&
              form.currentPassword.touched ? (
                <p className="text-red font-size-075x pt-1 pl-1">
                  {form.currentPassword.errors}
                </p>
              ) : null}

              <label className="mt-3" htmlFor="newPassword">
                Nueva contraseña
              </label>
              <PasswordInput
                value={form.newPassword.value}
                id="newPassword"
                autoComplete="new-password"
                onChange={(value) => onChange('newPassword', value)}
                onBlur={() => onBlur('newPassword')}
              />
              {form.newPassword.errors.length > 0 &&
              form.newPassword.touched ? (
                <p className="text-red font-size-075x pt-1 pl-1">
                  {form.newPassword.errors}
                </p>
              ) : null}

              <label className="mt-3" htmlFor="passwordConfirmation">
                Confirmar contraseña
              </label>
              <PasswordInput
                value={form.passwordConfirmation.value}
                id="passwordConfirmation"
                autoComplete="new-password"
                onChange={(value) => onChange('passwordConfirmation', value)}
                onBlur={() => onBlur('passwordConfirmation')}
              />
              {form.passwordConfirmation.errors.length > 0 &&
              form.passwordConfirmation.touched ? (
                <p className="text-red font-size-075x pt-1 pl-1">
                  {form.passwordConfirmation.errors}
                </p>
              ) : null}
              <button
                disabled={isSaving}
                type="submit"
                className="bg-green w-100 rounded tuyo-btn font-weight-bold text-white py-2 mt-3 d-flex align-items-center justify-content-around"
              >
                {isSaving ? 'Actualizando' : 'Actualizar contraseña'}
                <BounceLoader color="#fff" loading={isSaving} size="18" />
              </button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PaySettings;
