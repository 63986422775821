import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js';

import Card from '../Card/Card';

import { formatAmount } from '../../utils/misc';

function PieChartCard({ title, labels, data, colors }) {
  let chartJS;
  const chart = useRef(null);
  const chartOptions = {
    tooltips: {
      titleFontSize: 0,
      displayColors: false,
      callbacks: {
        label: (tooltipItem, data) => {
          return ((data.datasets[0].data[tooltipItem.index] / data.datasets[0].data.reduce((acc, val) => acc += val, 0)) * 100).toFixed(2) + '%';
        }
      }
    },
    cutoutPercentage: 60,
    legend: {
      display: false
    }
  }

  useEffect(() => {
    if (chart.current) {
      const ctx = chart.current.getContext('2d');

      if (chartJS) {
        chartJS.data.datasets[0].data = data;
        chartJS.update();
      } else {
        chartJS = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: labels,
            datasets: [{
              backgroundColor: colors,
              data: data
            }]
          },
          options: chartOptions
        });
      }
    }
  }, [data]);

  return (
    <Card className="h-100">
      <div className="row">
        <div className="col-7 d-flex flex-column justify-content-center">
          <p className="font-weight-bold text-dark-blue font-size-125x">
            {title}
          </p>
          <div className="d-flex mt-2">
            <div className="rounded"
              style={{
                width: '2rem',
                backgroundColor: colors[0],
                marginRight: '0.5rem'
              }} />
            <div>
              <p className="font-size-08x">
                {labels[0]}
              </p>
              <p className="font-weight-bold font-size-08x">
                {formatAmount(data[0])}
              </p>
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="rounded"
              style={{
                width: '2rem',
                backgroundColor: colors[1],
                marginRight: '0.5rem'
              }} />
            <div>
              <p className="font-size-08x">
                {labels[1]}
              </p>
              <p className="font-weight-bold font-size-08x">
                {formatAmount(data[1])}
              </p>
            </div>
          </div>
        </div>
        <div className="col-5">
          <canvas
            ref={chart}
            style={{
              width: '100%',
              height: '150px'
            }} />
        </div>
      </div>
    </Card>
  );
}

export default PieChartCard;
