import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';

import AdminNavbar from 'components/Navbars/AdminNavbar';
import Sidebar from 'components/Sidebar/Sidebar';
import NewProduct from 'views/NewProduct';
import ViewProduct from 'views/ViewProduct';
import NewBranchOffice from 'views/NewBranchOffice';

import api from '../utils/api';

import routes from 'routes.js';
import EnvironmentContext from 'context/environment';
import { ENVIROMENTS } from 'utils/misc';
import NewCoupon from 'views/coupons/new';

class Admin extends Component {
  static contextType = EnvironmentContext;
  constructor(props) {
    super(props);

    this.state = {
      _notificationSystem: null,
      color: 'jappi',
      hasImage: false,
      fixedClasses: 'dropdown show-dropdown open',
      arePushNotificationsEnabled: null,
      userID: null,
    };
  }

  getRoutes = (routes) => {
    const { environment } = this.context;
    let allRoutes = [
      ...routes,
      {
        layout: '/admin',
        path: '/products/new',
        component: NewProduct,
        roles: ['admin'],
        enviroment: [ENVIROMENTS.MARKETPLACE],
      },
      {
        layout: '/admin',
        path: '/products/:id/view',
        component: ViewProduct,
        roles: ['admin'],
        enviroment: [ENVIROMENTS.MARKETPLACE],
      },
      {
        layout: '/admin',
        path: '/products/:id/edit',
        component: NewProduct,
        roles: ['admin'],
        enviroment: [ENVIROMENTS.MARKETPLACE],
      },
      {
        layout: '/admin',
        path: '/branch-offices/new',
        component: NewBranchOffice,
        roles: ['admin'],
        enviroment: [ENVIROMENTS.MARKETPLACE],
      },
      {
        layout: '/admin',
        path: '/branch-offices/:id',
        component: NewBranchOffice,
        roles: ['admin'],
        enviroment: [ENVIROMENTS.MARKETPLACE],
      },
      {
        layout: '/admin',
        path: '/coupons/new',
        component: NewCoupon,
        roles: ['admin'],
        enviroment: [ENVIROMENTS.MARKETPLACE],
      },
      {
        layout: '/admin',
        path: '/coupons/:id/edit',
        component: NewCoupon,
        roles: ['admin'],
        enviroment: [ENVIROMENTS.MARKETPLACE],
      },
    ];

    allRoutes = allRoutes.filter((route) =>
      route.enviroment?.includes(environment)
    );

    return allRoutes
      .filter((route) =>
        route.roles?.includes(jwt_decode(this.state.JWT).user.role.name)
      )
      .map((prop, key) => {
        if (prop.layout === '/admin') {
          return (
            <Route
              exact
              path={prop.layout + prop.path}
              render={(props) => <prop.component {...props} />}
              key={key}
            />
          );
        } else {
          return null;
        }
      })
      .concat([
        <Route key="redirect" exact path="/admin">
          <Redirect to="/admin/dashboard" />
        </Route>,
      ]);
  };

  filterRoutes = (routes) => {
    const { environment } = this.context;
    return routes.filter((route) => route.enviroment?.includes(environment));
  };

  async checkNotifications(userID) {
    try {
      let arePushNotificationsEnabled =
        await window.OneSignal?.isPushNotificationsEnabled();

      this.setState({ arePushNotificationsEnabled });

      if (!arePushNotificationsEnabled) {
        window.OneSignal.on('subscriptionChange', (subscribed) => {
          if (subscribed) {
            let devices = [];
            api.users.devices
              .getAll(userID)
              .then((res) => {
                devices = res.data[0].devices;
                return window.OneSignal.getUserId();
              })
              .then(async (OneSignalUserID) => {
                if (
                  devices.length < 1 ||
                  (devices.length > 0 &&
                    !devices.find((device) => device.uuid === OneSignalUserID))
                ) {
                  return api.users.devices.create({
                    uuid: OneSignalUserID,
                    platform: 'onesignal-web',
                    fk_usersId: userID,
                    createdBy: userID,
                  });
                }
              })
              .catch((err) => {
                console.error(err);
                toast.warning(`[SERVER_ERROR] ${err}`);
              });
          }
        });

        window.OneSignal.on(
          'notificationPermissionChange',
          (permissionChange) => {
            this.setState({
              arePushNotificationsEnabled: permissionChange.to === 'granted',
            });
          }
        );

        await window.OneSignal.showSlidedownPrompt({ force: true });
      }
    } catch (error) {
      console.error(error);
    }
  }

  componentDidMount() {
    const { REACT_APP_COOKIES_JWT, REACT_APP_COOKIES_USER_ID } = process.env;
    const { cookies } = this.props;
    const JWT = cookies.get(REACT_APP_COOKIES_JWT);
    const userID = cookies.get(REACT_APP_COOKIES_USER_ID);
    this.setState({ JWT, userID });

    const { init } = this.context;
    init();

    if (!JWT) {
      this.props.history.push({
        pathname: '/login',
        state: { nextLocation: this.props.history.location.pathname },
      });
    } else if (new Date().getTime() / 1000 > jwt_decode(JWT).exp) {
      this.props.history.push({ pathname: '/admin/logout' });
    } else {
      setTimeout(() => this.checkNotifications(userID), 5 * 1000);
    }
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 1025 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  render() {
    return this.state.JWT ? (
      <>
        {this.state.arePushNotificationsEnabled === false && (
          <div className="bg-red text-white font-weight-bold text-center py-2">
            Activa las notificaciones para que podamos notificarte de nuevas
            órdenes haciendo click{' '}
            <button
              className="text-white font-weight-bold"
              style={{ textDecoration: 'underline' }}
              onClick={() => this.checkNotifications(this.state.userID)}
            >
              aquí
            </button>
          </div>
        )}
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={this.filterRoutes(routes)}
            image={this.state.image}
            color={this.state.color}
            hasImage={this.state.hasImage}
          />
          <div id="main-panel" className="main-panel" ref="mainPanel">
            <AdminNavbar {...this.props} />
            <div
              className="p-3 p-md-4 p-xl-5 d-flex flex-column"
              style={{ flexGrow: '1' }}
            >
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
          </div>
        </div>
      </>
    ) : null;
  }
}

export default withCookies(Admin);
