import React, { useEffect, useState } from 'react';

import { BounceLoader } from 'react-spinners';
import Pagination from './Pagination';
import TableDateSelector from './DateSelector';
import api from 'utils/api';
import axios from 'axios';
import { toast } from 'react-toastify';

function PaginatedTable(props) {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filterWhere, setFilterWhere] = useState(props.baseFilter.where || {});
  const [filterOrder, setFilterOrder] = useState(props.baseFilter.order);

  useEffect(() => {
    if (props.remountCount > 0) {
      setFilterWhere({ ...filterWhere });
    }
  }, [props.remountCount]);

  // Request data for each page
  useEffect(() => {
    const { pageSize } = props;
    setRows(null);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const params = [
      'paginate=true',
      ...(page ? [`page=${page - 1}`] : []),
      ...(pageSize ? [`limit=${pageSize}`] : []),
      `filter=${JSON.stringify({
        ...props.baseFilter,
        where: filterWhere,
        order: filterOrder,
      })}`,
    ];

    api
      .findAll(`${props.baseURL}?${params.join('&')}`, {
        cancelToken: source.token,
      })
      .then(({ data }) => {
        let rows = data.vouchers || data.products || data.items;
        const { totalPages, totalItems } = data;

        if (props?.customSort) {
          rows = props.customSort(rows);
        }

        setRows(rows);
        setTotalRows(totalItems);
        setTotalPages(totalPages);
      })
      .catch((error) => {
        if (error.message !== 'Not necessary anymore') {
          console.error(error);
          toast.warning('No se pudo obtener la página');
        }
      });

    return () => {
      source.cancel('Not necessary anymore');
    };
  }, [page, filterWhere, filterOrder, props.keepPageOnUpdate]);

  // Go to first page when search or sort change
  useEffect(() => {
    setPage(1);
  }, [filterWhere, filterOrder]);

  function updateWhereText(field, newValue, isNumber) {
    const currentValue = filterWhere[field];

    if (newValue) {
      if (
        !currentValue ||
        (isNumber ? newValue : encodeURI('%' + newValue + '%')) !==
          (isNumber ? currentValue : currentValue['$iLike'])
      ) {
        setFilterWhere({
          ...filterWhere,
          [field]: isNumber
            ? newValue
            : {
                $iLike: encodeURI('%' + newValue + '%'),
              },
        });
      }
    } else {
      if (currentValue) {
        const tmpWhere = { ...filterWhere };
        delete tmpWhere[field];

        setFilterWhere(tmpWhere);
      }
    }
  }

  function updateWhereRange(field, newValue, operator, isDate) {
    if (newValue) {
      setFilterWhere({
        ...filterWhere,
        [field]: {
          ...filterWhere[field],
          [operator]: newValue + (isDate ? '-06:00' : ''),
        },
      });
    } else {
      const tmpWhere = { ...filterWhere };

      if (tmpWhere[field] && tmpWhere[field][operator]) {
        delete tmpWhere[field][operator];

        if (Object.keys(tmpWhere[field]).length < 1) {
          delete tmpWhere[field];
        }

        setFilterWhere(tmpWhere);
      }
    }
  }

  function updateDateRange(field, startDate, endDate, selected) {
    if (selected) {
      setFilterWhere((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          $between: [startDate, endDate],
        },
      }));
    } else {
      const tmpWhere = { ...filterWhere };
      delete tmpWhere[field];
      setFilterWhere(tmpWhere);
    }
  }

  function searchFilter(column) {
    const { searchType } = column;
    let filter = null;

    switch (searchType) {
      case 'text':
      case 'number-equal':
        filter = (
          <input
            className="w-100"
            type="text"
            onKeyPress={(event) => {
              const trimmedValue = event.target.value.trim();

              if (event.key === 'Enter') {
                updateWhereText(
                  column.gatewayObjectProperty || column.objectProperty,
                  column.searchMiddleware
                    ? column.searchMiddleware(trimmedValue)
                    : trimmedValue,
                  column.searchType === 'number-equal'
                );
              }
            }}
            onBlur={(event) => {
              const trimmedValue = event.target.value.trim();

              updateWhereText(
                column.gatewayObjectProperty || column.objectProperty,
                column.searchMiddleware
                  ? column.searchMiddleware(trimmedValue)
                  : trimmedValue,
                column.searchType === 'number-equal'
              );
            }}
          />
        );
        break;

      case 'timestamp':
      case 'number':
        filter = (
          <div
            className={
              column.searchType === 'number'
                ? 'd-flex justify-content-center w-100'
                : ''
            }
          >
            <input
              type={
                column.searchType === 'timestamp' ? 'datetime-local' : 'number'
              }
              style={column.searchType === 'number' ? { width: '4rem' } : {}}
              className={column.searchType === 'number' ? 'mr-2' : ''}
              onBlur={(event) => {
                updateWhereRange(
                  column.objectProperty,
                  event.target.value,
                  '$gte',
                  column.searchType === 'timestamp'
                );
              }}
            />
            <input
              type={
                column.searchType === 'timestamp' ? 'datetime-local' : 'number'
              }
              style={column.searchType === 'number' ? { width: '4rem' } : {}}
              onChange={(event) => {
                updateWhereRange(
                  column.objectProperty,
                  event.target.value,
                  '$lte',
                  column.searchType === 'timestamp'
                );
              }}
            />
          </div>
        );
        break;

      case 'date':
        filter = (
          <>
            <TableDateSelector
              onChange={(selection) => {
                updateDateRange(
                  column.objectProperty,
                  selection.startDate,
                  selection.endDate,
                  selection.selected
                );
              }}
            />
          </>
        );
        break;

      case 'select':
        filter = (
          <select
            className="w-100 rounded-0 py-0 px-1"
            style={{ height: 27 }}
            onChange={(event) => {
              updateWhereText(
                column.objectProperty,
                event.target.value,
                column.searchType === 'number-equal'
              );
            }}
          >
            <option value="" disabled>
              Seleccione una opción
            </option>
            {column.searchOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
        break;

      default:
        break;
    }

    return filter;
  }

  return (
    <div style={{ overflowX: 'scroll' }} className="w-100">
      <Pagination
        page={page}
        pageSize={props.pageSize}
        totalRows={totalRows}
        totalPages={totalPages}
        setPage={setPage}
      />
      <table style={{ fontSize: '0.875rem', minWidth: '100%' }}>
        <thead className="bg-green-mint">
          <tr>
            {props.columns.map((column, index) => (
              <th
                key={`${column.objectProperty}-${index}`}
                className="px-2 pt-2"
              >
                <div
                  className="d-flex align-items-center justify-content-between"
                >
                  <p className="flex-grow-1 text-center">{column.title}</p>
                  {column.sortable && (
                    <button
                      onClick={() => {
                        const [orderBy, orderMode] = filterOrder[0];
                        const newOrderBy =
                          column.gatewayObjectProperty || column.objectProperty;

                        if (orderBy === newOrderBy) {
                          if (orderMode === 'asc') {
                            setFilterOrder([[newOrderBy, 'desc']]);
                          } else {
                            setFilterOrder(props.baseFilter.order);
                          }
                        } else {
                          setFilterOrder([[newOrderBy, 'asc']]);
                        }
                      }}
                    >
                      {(column.gatewayObjectProperty ||
                        column.objectProperty) === filterOrder[0][0] ? (
                        filterOrder[0][1] === 'asc' ? (
                          <span className="material-icons-round align-middle">
                            keyboard_arrow_up
                          </span>
                        ) : (
                          <span className="material-icons-round align-middle">
                            keyboard_arrow_down
                          </span>
                        )
                      ) : (
                        <span className="material-icons-round align-middle">
                          unfold_more
                        </span>
                      )}
                    </button>
                  )}
                </div>
              </th>
            ))}
          </tr>
          <tr>
            {props.columns.map((column, index) =>
              column.searchable ? (
                <th
                  key={`${column.objectProperty}-${index}`}
                  className="pb-2 px-2"
                >
                  {searchFilter(column)}
                </th>
              ) : (
                <th key={`${column.objectProperty}-${index}`} />
              )
            )}
          </tr>
        </thead>
        <tbody>
          {rows ? (
            rows.length > 0 ? (
              rows.map((row, index) => (
                <tr
                  key={row[props.rowId]}
                  style={{
                    backgroundColor: index % 2 !== 0 ? 'rgba(0,0,0,0.07)' : '',
                    cursor: props.onRowClick ? 'pointer' : '',
                  }}
                  onClick={() => {
                    if (props.onRowClick) {
                      props.onRowClick(row);
                    }
                  }}
                >
                  {props.columns.map((column, index) => (
                    <td
                      className={`p-2 ${column.className || ''}`}
                      key={`${column.objectProperty}-${index}`}
                      style={{
                        maxWidth: (1 / props.columns.length) * 100 + '%',
                        minWidth: '10px',
                      }}
                    >
                      <p>
                        {column.middleware ? (
                          column.middleware(
                            column.objectProperty
                              ? row[column.objectProperty]
                              : row
                          )
                        ) : column.columnType === 'check' ? (
                          <i
                            style={{
                              color: row[column.objectProperty]
                                ? 'var(--tuyo-green)'
                                : 'gray',
                            }}
                            className="material-icons"
                          >
                            verified
                          </i>
                        ) : column.objectProperty ? (
                          row[column.objectProperty]
                        ) : (
                          JSON.stringify(row)
                        )}
                      </p>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={props.columns.length}
                  className="p-2 text-center"
                  style={{
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black',
                  }}
                >
                  {props.noDataMessage || 'No se encontraron datos'}
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td
                colSpan={props.columns.length}
                className="p-2 text-center w-full "
                style={{
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                }}
              >
                <div className="margin-auto my-4 d-flex justify-content-center">
                  <BounceLoader color="#01d781" size="26" />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        page={page}
        pageSize={props.pageSize}
        totalRows={totalRows}
        totalPages={totalPages}
        setPage={setPage}
      />
    </div>
  );
}

export default PaginatedTable;
