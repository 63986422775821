import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from 'components/Card/Card';

import api from '../../utils/api';
import { formatPrice } from 'utils/misc';

import circleCheckSVG from '../../assets/icons/check-circle-solid-green.svg';
import addSVG from '../../assets/icons/add.svg';
import PaginatedTable from 'components/TableWithPagination/Table';
import { useCookies } from 'react-cookie';

const TYPES = {
  TOTAL: { key: 'TOTAL', text: 'Total' },
  PRODUCTS: { key: 'PRODUCTS', text: 'Productos' },
  SHIPPING: { key: 'SHIPPING', text: 'Envío' },
  CUSTOM_SHIPPING: { key: 'CUSTOM_SHIPPING', text: 'Envío personalizado' },
};

const PAYMENT_METHODS = {
  BOTH: { key: 'BOTH', text: 'Ambos' },
  CARD: { key: 'CARD', text: 'Tarjeta' },
  CASH: { key: 'CASH', text: 'Efectivo' },
  TIGO_MONEY: { key: 'TIGO_MONEY', text: 'Tigo Money' },
};

function CouponsList() {
  const { REACT_APP_TITLE, REACT_APP_COOKIES_STORE_ID } = process.env;

  const history = useHistory();
  const [cookies] = useCookies([
    REACT_APP_COOKIES_STORE_ID,
  ]);

  const storeId = cookies[REACT_APP_COOKIES_STORE_ID];

  const [tableRemountCount, setTableRemountCount] = useState(0);

  function deleteCoupon(id) {
    api
      .delete('/coupons', id)
      .then(() => {
        toast.success('Cupón eliminado');
        setTableRemountCount(tableRemountCount + 1);
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo eliminar el cupón');
      });
  }

  const tableProps = {
    rowId: 'couponsId',
    baseURL: `/couponstore/${storeId}`,
    baseFilter: {
      attributes: [
        'couponsId',
        'couponCode',
        'type',
        'paymentMethod',
        'value',
        'minCost',
        'maxDiscount',
        'remainingUses',
        'usesPerUser',
        'active',
        'startline',
        'deadline',
        'onlyFirstOrder',
      ],
      order: [['couponsId', 'desc']],
    },
    pageSize: 10,
    columns: [
      {
        title: 'Código',
        objectProperty: 'couponCode',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Tipo',
        objectProperty: 'type',
        searchable: true,
        searchType: 'select',
        searchOptions: [
          {
            value: '',
            label: 'SIN FILTRO',
          },
          ...Object.entries(TYPES).map((type, index) => {
            return {
              value: type[1]?.key,
              label: type[1]?.text?.toUpperCase(),
            };
          }),
        ],
        middleware: (type) => TYPES[type]?.text?.toUpperCase(),
      },
      {
        title: 'Método de pago',
        objectProperty: 'paymentMethod',
        searchable: true,
        searchType: 'select',
        searchOptions: [
          {
            value: '',
            label: 'SIN FILTRO',
          },
          ...Object.entries(PAYMENT_METHODS).map((method, index) => {
            return {
              value: method[1]?.key,
              label: method[1]?.text?.toUpperCase(),
            };
          }),
        ],
        middleware: (paymentMethod) =>
          PAYMENT_METHODS[paymentMethod]?.text?.toUpperCase(),
      },
      {
        title: 'Valor',
        objectProperty: 'value',
        searchable: true,
        searchType: 'number',
        sortable: true,
        middleware: (value) =>
          value > 1
            ? formatPrice(value)
            : new Intl.NumberFormat('en-US', {
                style: 'percent',
              }).format(value),
      },
      {
        title: 'Compra mínima',
        objectProperty: 'minCost',
        searchable: true,
        searchType: 'number',
        sortable: true,
        middleware: (minCost) =>
          minCost > 0 ? formatPrice(minCost) : 'Sin mínimo',
      },
      {
        title: 'Descuento máximo',
        objectProperty: 'maxDiscount',
        searchable: true,
        searchType: 'number',
        sortable: true,
        middleware: (maxDiscount) =>
          maxDiscount > 0 ? formatPrice(maxDiscount) : 'Sin máximo',
      },
      {
        title: 'Usos disponibles',
        objectProperty: 'remainingUses',
        searchable: true,
        searchType: 'number',
        sortable: true,
        middleware: (remainingUses) =>
          remainingUses < 0 ? 'Sin límite' : remainingUses,
      },
      {
        title: 'Usos por usuario',
        objectProperty: 'usesPerUser',
        searchable: true,
        searchType: 'number',
        sortable: true,
        middleware: (usesPerUser) =>
          usesPerUser < 0 ? 'Sin límite' : usesPerUser,
      },
      {
        title: 'Solo primera compra',
        objectProperty: 'onlyFirstOrder',
        middleware: (onlyFirstOrder) =>
          onlyFirstOrder ? (
            <img src={circleCheckSVG} alt="" style={{ height: '18px' }} />
          ) : null,
      },
      {
        title: 'Activo',
        objectProperty: 'active',
        middleware: (active) =>
          active === 1 ? (
            <img src={circleCheckSVG} alt="" style={{ height: '18px' }} />
          ) : null,
      },
      {
        title: 'Inicio',
        objectProperty: 'startline',
        searchable: true,
        searchType: 'timestamp',
        sortable: true,
        middleware: (startline) =>
          startline
            ? new Date(startline).toLocaleString()
            : 'Sin fecha de inicio',
      },
      {
        title: 'Fin',
        objectProperty: 'deadline',
        searchable: true,
        searchType: 'timestamp',
        sortable: true,
        middleware: (deadline) =>
          deadline ? new Date(deadline).toLocaleString() : 'Sin fecha de fin',
      },
      {
        objectProperty: 'couponsId',
        middleware: (id) => (
          <button
            onClick={(event) => {
              event.stopPropagation();
              deleteCoupon(id);
            }}
          >
            🗑️
          </button>
        ),
      },
    ],
  };

  useEffect(() => {
    document.title = `Cupones | ${REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content">
      <div className="row mx-0">
       
        <div className="col-12 col-md-8 px-0 col-xl-9 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Cupones
          </h3>
        </div>

        <div className="row col-12 col-md-4 col-xl-3 px-0 m-0 mt-3 m-md-0">
          <Link to="/admin/coupons/new" className="w-100">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold"
            >
              <img
                src={addSVG}
                alt=""
                className="d-inline-block mr-2"
                style={{ height: '16px' }}
              />
              Nuevo cupón
            </button>
          </Link>
        </div>
      </div>

      <p className="text-dark-blue font-size-1x mt-3">
          ¡Importante! Ten en cuenta que todos los descuentos y cupones
          aplicados tendrán costos que serán asumidos por tu comercio. Por
          favor, utilízalos de manera consciente, considerando su impacto en tu
          negocio.
        </p>

      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <Card className="p-md-4">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
              onRowClick={(row) => {
                history.push(`/admin/coupons/${row[tableProps.rowId]}/edit`);
              }}
              remountCount={tableRemountCount}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default CouponsList;
