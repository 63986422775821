import Customers from 'views/Customers';
import Dashboard from 'views/Dashboard';
import DashboardPay from 'views/DashboardPayment';
import { ENVIROMENTS } from 'utils/misc';
import Inventory from 'views/Inventory';
import Logout from 'views/Logout';
import Orders from 'views/Orders';
import PaymentLinks from 'views/PaymentLink';
import Products from 'views/Products';
import Profile from 'views/Profile';
import Returns from 'views/Returns';
import Settings from 'views/Settings';
import Vouchers from 'views/Vouchers';
import RecurringPayments from 'views/RecurringPayments';
import OpenLinks from 'views/OpenLinks';
import PaySettings from 'views/PaySettings';
import CouponsList from 'views/coupons/list';
import PendingOrders from 'views/PendingOrders';
import SendNotification from 'views/SendNotification';

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Inicio',
    icon: 'space_dashboard',
    component: Dashboard,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin', 'store-employee'],
    enviroment: [ENVIROMENTS.MARKETPLACE],
  },
  {
    path: '/dashboard',
    name: 'Inicio',
    icon: 'space_dashboard',
    component: DashboardPay,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin', 'store-employee'],
    enviroment: [ENVIROMENTS.PAYMENT],
  },
  {
    path: '/customers',
    name: 'Clientes',
    icon: 'people',
    component: Customers,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin', 'store-employee'],
    enviroment: [ENVIROMENTS.MARKETPLACE],
  },
  {
    path: '/products',
    name: 'Productos',
    icon: 'outbox',
    component: Products,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin'],
    enviroment: [ENVIROMENTS.MARKETPLACE],
  },
  {
    path: '/pending-orders',
    name: 'Órdenes pendientes',
    icon: 'inventory',
    component: PendingOrders,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin', 'store-employee'],
    enviroment: [ENVIROMENTS.MARKETPLACE],
  },
  {
    path: '/orders',
    name: 'Ordenes',
    icon: 'content_paste_search',
    component: Orders,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin', 'store-employee'],
    enviroment: [ENVIROMENTS.MARKETPLACE],
  },
  // {
  //   path: '/send-notifications',
  //   name: 'Notificar usuarios',
  //   icon: 'notifications',
  //   component: SendNotification,
  //   layout: '/admin',
  //   flexGrow: '0',
  //   className: '',
  //   roles: ['admin'],
  //   enviroment: [ENVIROMENTS.MARKETPLACE],
  // },
  {
    path: '/inventory',
    name: 'Inventario',
    icon: 'inventory_2',
    component: Inventory,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin'],
    enviroment: [ENVIROMENTS.MARKETPLACE],
  },
  {
    path: '/returns',
    name: 'Devoluciones',
    icon: 'assignment_return',
    component: Returns,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin', 'store-employee'],
    enviroment: [ENVIROMENTS.MARKETPLACE],
  },
  {
    path: '/profile',
    name: 'Perfil',
    icon: 'person',
    component: Profile,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin'],
    enviroment: [ENVIROMENTS.MARKETPLACE],
  },
  {
    path: '/links',
    name: 'Links de pago',
    icon: 'add_link',
    component: PaymentLinks,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin', 'store-employee'],
    enviroment: [ENVIROMENTS.PAYMENT],
  },
  {
    path: '/open-links',
    name: 'Links Multipagos',
    icon: 'receipt_long',
    component: OpenLinks,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin', 'store-employee'],
    enviroment: [ENVIROMENTS.PAYMENT],
  },
  {
    path: '/recurring-payments',
    name: 'Pago Recurrente',
    icon: 'currency_exchange',
    component: RecurringPayments,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin', 'store-employee'],
    enviroment: [ENVIROMENTS.PAYMENT],
  },
  {
    path: '/vouchers',
    name: 'Historial',
    icon: 'manage_search',
    component: Vouchers,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin', 'store-employee'],
    enviroment: [ENVIROMENTS.PAYMENT],
  },
  {
    path: '/coupons',
    name: 'Cupones',
    icon: 'confirmation_number',
    component: CouponsList,
    layout: '/admin',
    flexGrow: '0',
    className: '',
    roles: ['admin'],
    enviroment: [ENVIROMENTS.MARKETPLACE],
  },
  {
    path: '/settings',
    name: 'Ajustes',
    icon: 'settings',
    component: Settings,
    layout: '/admin',
    flexGrow: '0',
    className: 'd-flex flex-column justify-content-end',
    roles: ['admin'],
    enviroment: [ENVIROMENTS.MARKETPLACE],
  },
  {
    path: '/pay-settings',
    name: 'Ajustes',
    icon: 'settings',
    component: PaySettings,
    layout: '/admin',
    flexGrow: '0',
    className: 'd-flex flex-column justify-content-end',
    roles: ['admin'],
    enviroment: [ENVIROMENTS.PAYMENT],
  },
  {
    path: '/logout',
    name: 'Cerrar sesión',
    icon: 'logout',
    component: Logout,
    layout: '/admin',
    flexGrow: '1',
    className: 'pb-3',
    roles: ['admin', 'store-employee'],
    enviroment: [ENVIROMENTS.MARKETPLACE, ENVIROMENTS.PAYMENT],
  },
];

export default dashboardRoutes;
