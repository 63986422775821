import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import BounceLoader from 'react-spinners/BounceLoader';
import { Link } from 'react-router-dom';

import Tabs from '../components/Tabs/Tabs';
import Stars from '../components/Stars/Stars';

import api from '../utils/api';
import { fileToBase64URL, uploadPictureToS3, deleteFromS3, formatTimestamp } from '../utils/misc';
import colors from '../assets/resources/colors';

import cameraSVG from '../assets/icons/camera.svg';
import altDeleteSVG from '../assets/icons/trash-alt-regular.svg';

const types = ['png','jpg','jpeg'];

function Profile() {
  const { REACT_APP_COOKIES_STORE_ID, REACT_APP_COOKIES_USER_ID, REACT_APP_TITLE } = process.env;

  useEffect(() => {
    document.title = `Perfil | ${REACT_APP_TITLE}`;
  }, []);

  const [cookies] = useCookies([REACT_APP_COOKIES_STORE_ID, REACT_APP_COOKIES_USER_ID]);

  const storeID = cookies[REACT_APP_COOKIES_STORE_ID];
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  const [reviews, setReviews] = useState([]);

  const [starsQty, setStarsQty] = useState([
    { stars: 1, qty: 0 },
    { stars: 2, qty: 0 },
    { stars: 3, qty: 0 },
    { stars: 4, qty: 0 },
    { stars: 5, qty: 0 },
  ]);
  const [starsAvg, setStarsAvg] = useState(0);

  const [editMode, setEditMode] = useState(false);
  const [profilePicture, setProfilePicture] = useState();
  const [mainBanner, setMainBanner] = useState();
  const [store, setStore] = useState();
  const [getStore, setGetStore] = useState(1);
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [isSavingPicture, setIsSavingPicture] = useState(false);
  const [isSavingMainBanner, setIsSavingMainBanner] = useState(false);
  const [activeTab, setActiveTab] = useState('banners');
  const [banners, setBanners] = useState([]);
  const [getBanners, setGetBanners] = useState(1);
  const [isSavingBanner, setIsSavingBanner] = useState(false);
  const [branchOffices, setBranchOffices] = useState();
  const [getBranchOffices, setGetBranchOffices] = useState(1);

  useEffect(() => {
    api.stores
      .getOne(storeID)
      .then(res => {
        setStore(res.data);
        setProfilePicture({ url: res.data.image });
        setMainBanner({ url: res.data.banner });
        setGetBanners(getBanners + 1);
        setGetBranchOffices(getBranchOffices + 1);

        return api.stores.rating.getByStore(storeID);
      })
      .then(res => {
        setReviews(res.data.sort((a, b) => (a.storesRatingsId < b.storesRatingsId ? 1 : a.storesRatingsId > b.storesRatingsId ? -1 : 0)));
      })
      .catch(err => {
        console.error(err);
        toast.warning(`[SERVER_ERROR] ${err}`);
      });
  }, [getStore]);

  useEffect(() => {
    store &&
      api.storeBanners
        .getByStore(storeID)
        .then(res => {
          setBanners(res.data);
        })
        .catch(err => {
          console.error(err);
          toast.warning('[SERVER_ERROR] No se pudo obtener listado de banners');
        });
  }, [getBanners]);

  useEffect(() => {
    const tmpStarsQty = [...starsQty];

    setStarsAvg(reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length);
    reviews.forEach(review => tmpStarsQty.find(starQty => starQty.stars === parseInt(review.rating)).qty++);
    setStarsQty([...tmpStarsQty]);
  }, [reviews]);

  useEffect(() => {
    store &&
      api.branchOffices
        .getByStore(storeID)
        .then(res => {
          setBranchOffices(res.data);
        })
        .catch(err => {
          console.error(err);
          toast.warning('[SERVER_ERROR] No se pudo obtener listado de sucursales');
        });
  }, [getBranchOffices]);

  const changePicture = async event => {
    const file = event.target.files[0];
    if (file.size / 1024 >= 2048) {
      toast.warning('Error: La imagen supera el peso maximo permitido');
      event.target.value = '';
    } else if(!types.includes(file.type.replace('image/',''))){
      toast.warning('Error: La imagen tiene un formato no soportado');
    } else {
      setIsSavingPicture(true);
      setProfilePicture({
        oldURL: profilePicture.url || profilePicture.oldURL || null,
        base64: await fileToBase64URL(file),
        type: file.type,
        name: file.name,
      });
      setIsSavingPicture(false);
    }
  };

  const changeMainBanner = async event => {
    const file = event.target.files[0];
    if (file.size / 1024 >= 2048) {
      toast.warning('Error: La imagen supera el peso maximo permitido');
      event.target.value = '';
    } else if(!types.includes(file.type.replace('image/',''))){
      toast.warning('Error: La imagen tiene un formato no soportado');
    } else { 
      setIsSavingMainBanner(true);
      setMainBanner({
        oldURL: mainBanner.url || mainBanner.oldURL || null,
        base64: await fileToBase64URL(file),
        type: file.type,
        name: file.name,
      });
      setIsSavingMainBanner(false);
    }
  };

  const addBanner = async event => {
    const file = event.target.files[0];
    if (file.size / 1024 >= 2048) {
      toast.warning('Error: La imagen supera el peso maximo permitido');
      event.target.value = '';
    } else if(!types.includes(file.type.replace('image/',''))){
      toast.warning('Error: La imagen tiene un formato no soportado');
    }else {
      setIsSavingBanner(true);

      const file = event.target.files[0];
      const { type, name } = file;
      api.storeBanners
        .create([
          {
            image: await uploadPictureToS3(await fileToBase64URL(file), type, name),
            active: 1,
            type: 'feature',
            fk_storesId: storeID,
            createdBy: userID,
          },
        ])
        .then(() => {
          setGetBanners(getBanners + 1);
        })
        .catch(err => {
          console.error(err);
          toast.warning('[SERVER_ERROR] No se pudo crear el banner');
        })
        .finally(() => {
          setIsSavingBanner(false);
        });
    }
  };

  const deleteBanner = async id => {
    const { storesBannersId, image } = banners.find(banner => banner.storesBannersId === id);

    api.storeBanners
      .deleteOne(storesBannersId)
      .then(async () => {
        await deleteFromS3(image.substring(image.lastIndexOf('/') + 1));
        setGetBanners(getBanners + 1);
      })
      .catch(err => {
        console.error(err);
        toast.warning('[SERVER_ERROR] No se pudo eliminar el banner');
      });
  };

  const saveProfile = async () => {
    setIsSavingProfile(true);

    profilePicture.oldURL && (await deleteFromS3(profilePicture.oldURL.substring(profilePicture.oldURL.lastIndexOf('/') + 1)));
    mainBanner.oldURL && (await deleteFromS3(mainBanner.oldURL.substring(mainBanner.oldURL.lastIndexOf('/') + 1)));

    api.stores
      .update(storeID, {
        name: store.name,
        description: store.description,
        image: profilePicture.url || (await uploadPictureToS3(profilePicture.base64, profilePicture.type, profilePicture.name)),
        updatedBy: userID,
        banner: mainBanner.url || (mainBanner.base64 ? await uploadPictureToS3(mainBanner.base64, mainBanner.type, mainBanner.name) : null),
      })
      .then(() => {
        toast.success('¡Perfil actualizado!');
        setEditMode(false);
        setGetStore(getStore + 1);
      })
      .catch(err => {
        console.error(err);
        toast.warning('[SERVER_ERROR] No se pudo actualizar la información de la tienda');
      })
      .finally(() => {
        setIsSavingProfile(false);
      });
  };

  return (
    <div className='content d-flex flex-column' style={{ flexGrow: '1' }}>
      <div className='row align-items-center'>
        <div className='col-12 col-md-8 col-lg-9 d-flex align-items-center'>
          <p className='text-dark-blue font-size-2x font-weight-bold w-100 text-center text-md-left'>Mi perfil</p>
        </div>
        <div className='col-12 col-md-4 col-lg-3 mt-2 mt-md-0'>
          <button
            disabled={isSavingProfile || isSavingPicture}
            onClick={() => (editMode ? saveProfile() : setEditMode(true))}
            type='button'
            className='tuyo-btn bg-purple text-white font-weight-bold rounded w-100 py-2'>
            {editMode ? (
              isSavingProfile ? (
                <div className='d-flex justify-content-around align-items-center'>
                  Guardando
                  <BounceLoader color='#fff' loading={true} size='18' />
                </div>
              ) : (
                'Guardar'
              )
            ) : (
              'Editar'
            )}
          </button>
        </div>
      </div>
      <div className='row mt-4' style={{ flexGrow: '1' }}>
        <div className='col-12'>
          <div className='p-4 justify-content-center'>
            {store ? (
              <>
                <div className='row justify-content-center'>
                  <div className='col-12 col-md-8 col-xl-6'>
                    <div
                      className='w-100 rounded'
                      style={{
                        minHeight: '200px',
                        backgroundColor: 'black',
                        backgroundImage: mainBanner?.base64 ? `url('${mainBanner.base64}')` : mainBanner?.url ? `url('${mainBanner.url}')` : '',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                      }}>
                      <button
                        className={'rounded-circle ' + (editMode ? '' : 'hidden')}
                        style={{
                          position: 'absolute',
                          width: '64px',
                          height: '64px',
                          backgroundColor: 'var(--green)',
                          top: '5%',
                          right: '5%',
                          border: '4px solid white',
                          transition: '220ms ease',
                        }}>
                        <label htmlFor='main-banner' className='w-100 h-100 d-flex align-items-center justify-content-center cursor-pointer'>
                          {isSavingMainBanner ? <BounceLoader color='#fff' loading={true} size='28' /> : <img src={cameraSVG} alt='' />}
                        </label>
                      </button>
                      <input accept='.jpg,.jpeg,.png' onChange={event => changeMainBanner(event)} type='file' id='main-banner' className='d-none' />
                    </div>
                  </div>
                </div>
                <div
                  className='row justify-content-center'
                  style={{
                    position: 'relative',
                    top: '-75px',
                    marginBottom: '-75px',
                  }}>
                  <div className='col-7 col-md-3 col-lg-3 col-xl-2' style={{ position: 'relative' }}>
                    <div
                      className='rounded-circle'
                      style={{
                        border: '3px solid white',
                        paddingTop: '100%',
                        backgroundColor: 'gray',
                        backgroundImage: profilePicture?.base64 ? `url('${profilePicture.base64}')` : profilePicture?.url ? `url('${profilePicture.url}')` : '',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                      }}
                    />
                    <button
                      className={'rounded-circle ' + (editMode ? '' : 'hidden')}
                      style={{
                        position: 'absolute',
                        width: '64px',
                        height: '64px',
                        backgroundColor: 'var(--green)',
                        bottom: '5%',
                        right: '5%',
                        border: '4px solid white',
                        transition: '220ms ease',
                      }}>
                      <label htmlFor='profile-picture' className='w-100 h-100 d-flex align-items-center justify-content-center cursor-pointer'>
                        {isSavingPicture ? <BounceLoader color='#fff' loading={true} size='28' /> : <img src={cameraSVG} alt='' />}
                      </label>
                    </button>
                    <input accept='.jpg,.jpeg,.png' onChange={event => changePicture(event)} type='file' id='profile-picture' className='d-none' />
                  </div>
                </div>
                <div className='row justify-content-center mt-4'>
                  <div className='col-12 col-md-8 col-xl-6 d-flex flex-column align-items-center'>
                    {editMode ? (
                      <input value={store.name} onInput={event => setStore({ ...store, name: event.target.value })} type='text' className='rounded p-2 w-100' />
                    ) : (
                      <p className='font-weight-bold font-size-125x text-center'>{store?.name}</p>
                    )}
                    {editMode ? (
                      <textarea
                        value={store.description}
                        onInput={ev => setStore({ ...store, description: ev.target.value })}
                        rows='5'
                        className='rounded w-100 p-2 mt-3'
                      />
                    ) : (
                      <p className='text-center mt-3'>{store.description}</p>
                    )}
                    {reviews && reviews.length > 0 && (
                      <div className='mt-3 d-flex align-items-center'>
                        <Stars fill={starsAvg} size='lg' color='yellow' />
                        <p className='ml-2 font-weight-bold font-size-125x' style={{ opacity: '0.7' }}>
                          {starsAvg.toFixed(2)}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <Tabs
                      activeTab={activeTab}
                      onChange={tab => setActiveTab(tab)}
                      tabs={[
                        { text: 'Banners', key: 'banners' },
                        { text: 'Lugar de recolección', key: 'branch-offices' },
                        { text: 'Reseñas', key: 'reviews', hidden: !reviews || reviews.length < 1 },
                      ]}
                    />
                    {activeTab === 'banners' ? (
                      <div className='row justify-content-center align-items-center mt-3'>
                        {banners.map(banner => (
                          <div key={banner.storesBannersId} className='col-12 col-md-6 col-xl-5 mb-3'>
                            <div
                              className='border'
                              style={{
                                paddingTop: '50%',
                                backgroundImage: `url('${banner.image}')`,
                                borderRadius: '0.5rem',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                              }}>
                              <button
                                className='delete w-100 h-100'
                                style={{ position: 'absolute', top: '0', left: '0' }}
                                onClick={() => deleteBanner(banner.storesBannersId)}>
                                <img src={altDeleteSVG} alt='' className='w-50 h-50' />
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className='col-6 col-md-3 col-lg-2 mb-3'>
                          <button
                            className={`w-100 add-picture-btn ${isSavingBanner ? 'uploading' : ''}`}
                            style={{
                              border: '2px solid rgba(0,0,0,0.16)',
                              paddingTop: '100%',
                              borderRadius: '1rem',
                              position: 'relative',
                            }}>
                            <div
                              className={`w-100 h-100 d-flex align-items-center justify-content-center ${!isSavingBanner ? 'hidden' : ''}`}
                              style={{ position: 'absolute', top: '0', left: '0', transition: '220ms' }}>
                              <BounceLoader color='#bbb' loading={true} size='72' />
                            </div>
                            <input accept='.jpg,.jpeg,.png' id='banner' type='file' className='d-none' onChange={event => addBanner(event)} />
                            <label htmlFor='banner' className='mb-0 cursor-pointer w-100 h-100' style={{ position: 'absolute', top: '0', left: '0' }} />
                          </button>
                        </div>
                      </div>
                    ) : activeTab === 'branch-offices' ? (
                      <div className='row mt-3 justify-content-center align-items-center'>
                        {branchOffices &&
                          branchOffices.map(branchOffice => (
                            <div key={branchOffice.storesBranchesId} className='col-12 col-md-6 col-lg-4 mb-3' style={{ position: 'relative' }}>
                              <Link className='branch-office-link' to={`/admin/branch-offices/${branchOffice.storesBranchesId}`}>
                                <button className='text-left rounded p-3 branch-office w-100'>
                                  <div className='row'>
                                    <div className='col-6'>
                                      <p className='font-weight-bold'>{branchOffice.name}</p>
                                    </div>
                                    <div className='col-6 d-flex justify-content-end' />
                                    <div className='col-12'>
                                      <p className='font-weight-bold font-size-08x mt-3'>Dirección</p>
                                      <p className='font-size-08x'>{branchOffice.address}</p>
                                      <p className='font-weight-bold font-size-08x mt-3'>Departamento</p>
                                      <p className='font-size-08x'>
                                        {branchOffice.city.name}, {branchOffice.state.name}
                                      </p>
                                    </div>
                                  </div>
                                </button>
                              </Link>
                            </div>
                          ))}
                        {branchOffices && branchOffices.length === 0 && (
                          <div className='col-6 col-md-3 col-lg-2 mb-3'>
                            <Link to='/admin/branch-offices/new'>
                              <button
                                className='w-100 add-picture-btn'
                                style={{
                                  border: '2px solid rgba(0,0,0,0.16)',
                                  paddingTop: '100%',
                                  borderRadius: '1rem',
                                  position: 'relative',
                                }}
                              />
                            </Link>
                          </div>
                        )}
                      </div>
                    ) : activeTab === 'reviews' ? (
                      <div className='row mt-4'>
                        <div className='col-12 col-xl-6 order-xl-2 d-flex align-items-center'>
                          <div className='row pl-xl-3'>
                            <div className='col-12 d-flex align-items-center'>
                              <p className='mr-3 text-dark-blue font-weight-bold font-size-2x'>{starsAvg.toFixed(2)}</p>
                              <Stars fill={parseInt(starsAvg)} size='lg' color='green' />
                            </div>
                            <div className='col-12 text-dark-blue font-weight-bold'>
                              {reviews.length} reseña{reviews.length > 1 && 's'}
                              <div className='my-3' style={{ borderBottom: '2px solid var(--light-gray)' }} />
                            </div>
                            {starsQty.reverse().map((stars, index) => (
                              <div key={index} className='col-12 mb-1 d-flex align-items-center'>
                                <Stars fill={stars.stars} />
                                <div className='progress ml-2' style={{ flexGrow: '1', height: '0.75rem' }}>
                                  <div
                                    className='progress-bar'
                                    role='progressbar'
                                    style={{ width: (stars.qty / reviews.length) * 100 + '%', backgroundColor: 'var(--green)' }}
                                    aria-valuenow={(stars.qty / reviews.length) * 100}
                                    aria-valuemin='0'
                                    aria-valuemax='100'
                                  />
                                </div>
                                <div style={{ flexBasis: '16%' }} className='ml-3'>
                                  {stars.qty}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className='col-12 col-xl-6 mt-4 mt-xl-0'>
                          <div className='row' style={{ height: '300px', overflow: 'auto' }}>
                            {reviews.map(review => (
                              <div key={review.storesRatingsId} className='col-12 mb-3 py-2 border rounded'>
                                <div className='d-flex align-items-center'>
                                  <div
                                    className='rounded'
                                    style={{
                                      height: '3rem',
                                      width: '3rem',
                                      backgroundImage: `url('${review.user.photo}')`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                    }}
                                  />
                                  <div className='ml-3'>
                                    <p className='font-weight-bold text-dark-blue font-size-125x'>
                                      {review.user.firstname} {review.user.lastname}
                                    </p>
                                    <p className='font-weight-bold text-gray' style={{ opacity: '0.7' }}>
                                      {formatTimestamp(review.createdAt)}
                                    </p>
                                    <div className='mt-1'>
                                      <Stars fill={review.rating} color='yellow' />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            ) : (
              <div className='p-5 m-5 d-flex justify-content-center align-items-center' style={{ flexGrow: '1' }}>
                <ClimbingBoxLoader color={colors.green} size='25' />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
