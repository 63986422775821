import React from "react";

function UserCard({ picture, name, store }) {
  return (
    <div className="user-card d-flex">
      <div className="desc p-2 text-right">
        <p className="text-dark-blue font-weight-bold">{name}</p>
        <p className="text-gray font-size-075x">{store}</p>
      </div>
      <div className="avatar p-2 d-flex align-items-center">
        <img src={picture} className="rounded" alt="" style={{ height: '40px' }} />
      </div>
    </div>
  );
}

export default UserCard;
