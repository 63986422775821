import React from 'react';

function Pagination(props) {
  const { page, pageSize, totalRows, totalPages, setPage } = props;

  return (
    <div
      className="d-flex align-items-center justify-content-between my-2"
      style={{ position: 'sticky', left: '0' }}
    >
      <p>
        Resultados {page * pageSize - (pageSize - 1)} -{' '}
        {Math.min(page * pageSize, totalRows)} de {totalRows}
      </p>
      <div className="d-flex items-center">
        <button
          className="mr-2"
          disabled={page === 1}
          onClick={() => setPage(1)}
        >
          <span className="material-icons-round align-middle">first_page</span>
        </button>
        <button
          className="mr-2"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        >
          <span className="material-icons-round align-middle">chevron_left</span>
        </button>
        <span className="mr-2">
          Pagina {page} de {totalPages}
        </span>
        <button
          className="mr-2"
          disabled={page === totalPages}
          onClick={() => setPage(page + 1)}
        >
          <span className="material-icons-round align-middle">chevron_right</span>
        </button>
        <button
          className="mr-2"
          disabled={page === totalPages}
          onClick={() => setPage(totalPages)}
        >
          <span className="material-icons-round align-middle">last_page</span>
        </button>
      </div>
    </div>
  );
}

export default Pagination;
