import React, { useEffect, useRef, useState } from "react";

function Choice({ text, onChange, hide }) {
  const [showSelector, setShowSelector] = useState(false);

  const choiceRef = useRef(null);

  useEffect(() => {
    setShowSelector(false);
  }, [hide]);

  const checkForFocus = () => {
    setTimeout(() => {
      choiceRef.current && setShowSelector(choiceRef.current.contains(document.activeElement));
    }, 0);
  };

  return (
    <div
      tabIndex="0"
      onBlur={checkForFocus}
      ref={choiceRef}
      className="tuyo-choice">
      <button
        className="w-100 px-4 py-1 rounded tuyo-btn bg-light-gray"
        onClick={(e) => {
          e.stopPropagation();
          setShowSelector(!showSelector);
        }}>
        {text}
      </button>
      <div className={'selector border ' + (showSelector ? '' : 'hidden')}>
        <div>
          <button
            className="bg-purple font-weight-bold rounded text-white py-1 tuyo-btn text-uppercase w-100 mb-2"
            onClick={(e) => {
              e.stopPropagation();
              onChange(true);
              setShowSelector(false);
            }}>Sí</button>
        </div>
        <div>
          <button
            className="bg-red font-weight-bold rounded text-white tuyo-btn text-uppercase py-1 w-100"
            onClick={(e) => {
              e.stopPropagation();
              onChange(false);
              setShowSelector(false);
            }}>No</button>
        </div>
      </div>
    </div>
  );
}

export default Choice;
