import React, { useEffect, useState } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import bgSVG from '../assets/img/login-bg.svg';
import logoSVG from '../assets/img/tuyo_icon.svg';

import Card from '../components/Card/Card';
import PasswordInput from '../components/PasswordInput/PasswordInput';

import api from '../utils/api';

const STEPS = {
  EMAIL: 'email',
  TOKEN: 'token',
  PASSWORD: 'password',
};

function ForgotPassword() {
  const { REACT_APP_COOKIES_JWT } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_JWT]);

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');

  const [isSending, setIsSending] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [step, setStep] = useState(STEPS.EMAIL);

  /* componentDidMount */
  useEffect(() => {
    if (cookies[REACT_APP_COOKIES_JWT]) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    isLoggedIn && history.push('/admin/dashboard');
  }, [isLoggedIn]);

  function sendEmail(event) {
    event.preventDefault();

    if (step === STEPS.EMAIL
      ? !email.trim()
      : step === STEPS.TOKEN
        ? !token.trim()
        : step === STEPS.PASSWORD
          ? !password.trim() || password.length < 10
          : true) {
      return;
    }

    setIsSending(true);

    const action = step === STEPS.EMAIL
      ? api.sendPasswordRecoveryEmail({ email })
      : step === STEPS.TOKEN
        ? api.verifyPasswordRecoveryToken({ token })
        : step === STEPS.PASSWORD
          ? api.changePassword({
            token,
            password,
          })
          : new Promise((resolve, reject) => reject(false));

    action
      .then(() => {
        if (step === STEPS.EMAIL) {
          toast.success('Correo enviado');
          setEmail('');
          setStep(STEPS.TOKEN);
        } else if (step === STEPS.TOKEN) {
          toast.success('Correo válido');
          setStep(STEPS.PASSWORD);
        } else if (step === STEPS.PASSWORD) {
          toast.success('Contraseña actualizada');
          setToken('');
          setPassword('');
          history.push('/login');
        }
      })
      .catch(err => {
        console.error(err);

        if (step === STEPS.EMAIL) {
          toast.error('El correo proporcionado no está vinculado a ninguna cuenta');
        } else if (step === STEPS.TOKEN) {
          toast.error('El código proporcionado no es válido. Revisa de nuevo el código ingresado o solicita uno nuevo');
        } else if (step === STEPS.PASSWORD) {
          toast.error('El código proporcionado en el paso anterior ya no es válido, solicita uno nuevo');
        }
      })
      .finally(() => setIsSending(false));
  };

  return isLoggedIn === false && <div
    className="tuyo-login d-flex align-items-center justify-content-center bg-beige"
    style={{
      backgroundImage: `url('${bgSVG}')`
    }}>
    <div className="row w-100 justify-content-center">
      <div className="col-11 col-md-8 col-lg-6 col-xl-4 ">
        <Card className="py-5 px-4 px-md-5">
          <form onSubmit={(event) => sendEmail(event)}>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <img src={logoSVG} alt="" style={{ width: '128px', height: '128px' }} />
              </div>
              <div className="col-12 mt-4">
                <p
                  style={{ lineHeight: '1.25' }}
                  className="text-center font-size-2x font-weight-bold text-dark-blue">
                  {
                    step === STEPS.EMAIL
                      ? 'Ingresa el correo vinculado a tu cuenta'
                      : step === STEPS.TOKEN
                        ? 'Ingresa el código enviado a tu correo en el paso anterior'
                        : step === STEPS.PASSWORD
                          ? 'Ingresa tu nueva contraseña'
                          : ''
                  }
                </p>
              </div>
              <div className="col-12 mt-4">
                <label className="d-block text-green font-weight-bold" htmlFor="username">
                  {
                    step === STEPS.EMAIL
                      ? 'Correo electrónico'
                      : step === STEPS.TOKEN
                        ? 'Código de verificación'
                        : step === STEPS.PASSWORD
                          ? 'Nueva contraseña'
                          : ''
                  }
                </label>
                {
                  [STEPS.EMAIL, STEPS.TOKEN].includes(step)
                    ? <input
                      value={step === STEPS.EMAIL
                        ? email
                        : step === STEPS.TOKEN
                          ? token
                          : null}
                      onChange={(event) => step === STEPS.EMAIL
                        ? setEmail(event.target.value)
                        : step === STEPS.TOKEN
                          ? setToken(event.target.value)
                          : null}
                      className="w-100 p-2 rounded"
                      type="text"
                      autoComplete="email"
                      autoFocus />
                    : step === STEPS.PASSWORD
                      ? <PasswordInput
                        id="password"
                        autoComplete="current-pasword"
                        onChange={value => setPassword(value)} />
                      : null
                }
                {
                  step === STEPS.PASSWORD && password.length < 10 &&
                  <p className="text-red font-size-075x pt-1 pl-1">
                    La contraseña debe contar con al menos 10 caracteres
                  </p>
                }
              </div>
              <div className="col-12 mt-4 mt-md-5">
                <button
                  disabled={(step === STEPS.EMAIL
                    ? !email.trim()
                    : step === STEPS.TOKEN
                      ? !token.trim()
                      : step === STEPS.PASSWORD
                        ? !password.trim()
                        : true) || isSending}
                  type="submit"
                  className="text-white font-weight-bold bg-green w-100 p-2 rounded tuyo-btn d-flex align-items-center justify-content-around">
                  {
                    step === STEPS.EMAIL
                      ? `${isSending ? 'Enviando' : 'Enviar'} correo`
                      : step === STEPS.TOKEN
                        ? `${isSending ? 'Verificando' : 'Verificar'} código`
                        : step === STEPS.PASSWORD
                          ? isSending
                            ? 'Guardando'
                            : 'Guardar'
                          : ''
                  }

                  <BounceLoader
                    color="#fff"
                    loading={isSending}
                    size="18" />
                </button>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  </div>;
}

export default ForgotPassword;
