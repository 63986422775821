import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import * as moment from 'moment';
import Datetime from 'react-datetime';

import api from '../utils/api';
import colors from '../assets/resources/colors';
import { STATUS } from '../assets/resources/status';
import { formatTimestamp, getOrderCode } from '../utils/misc';

import Table from '../components/Table/Table';
import OrderApproval from '../components/OrderApproval/OrderApproval';

const ordersTable = {
  options: {
    id: 'ordersId',
  },
  columns: [
    { text: 'Código', key: 'code', className: 'text-center' },
    { text: 'Turbo', key: 'turbo', className: 'text-center' },
    {
      text: 'Aprobar',
      isChoice: true,
      className: 'text-center',
      hidden: (status) => STATUS.ORDER_PENDING !== status,
    },
    { text: 'Comprador', key: 'buyer', className: 'text-center' },
    { text: 'Dirección de envío', key: 'address' },
    { text: 'Departamento', key: 'state', className: 'text-center' },
    { text: 'Fecha', key: 'createdAtDisplay', className: 'text-center' },
    { text: 'Estado', key: 'status', className: 'text-center', isStatus: true },
  ],
};

const today = new Date();

function Orders({ onlyPending, onTotalChange }) {
  const { REACT_APP_COOKIES_STORE_ID, REACT_APP_COOKIES_USER_ID } = process.env;
  const [cookies] = useCookies([
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_COOKIES_USER_ID,
  ]);
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  useEffect(() => {
    if (!onlyPending) {
      document.title = `Órdenes | ${process.env.REACT_APP_TITLE}`;
    }
  }, []);

  const [orders, setOrders] = useState();
  const [isSaving, setIsSaving] = useState();
  const [getOrders, setGetOrders] = useState(1);

  const [month, setMonth] = useState(today.getMonth());
  const [year, setYear] = useState(today.getFullYear());

  useEffect(() => {
    setOrders(null);

    const gt = new Date(year, month, 1);
    const lt = new Date(year, month, 1);
    lt.setMonth(month + 1);

    const getOrderStatus = (details) => {
      const {
        ORDER_PENDING,
        ORDER_ACCEPTED,
        PICKED_UP,
        FINISHED,
        NON_PICKED_UP,
        NON_FINISHED,
        ORDER_U_CANCELED,
        ORDER_S_CANCELED,
        RETURN_PENDING,
        RETURN_APPROVED,
        RETURN_DENIED,
        REFUND_PENDING,
        REFUND_APPROVED,
        REFUND_DENIED,
        READY,
        DELIVERED_IN_WAREHOUSE,
        ON_ROUTE,
        CANCELED_IN_DELIVERY_POINT,
        ON_THE_WAY_TO_STORE,
        WAITING_IN_STORE,
      } = STATUS;

      const statuses = [
        ORDER_PENDING,
        ORDER_ACCEPTED,
        PICKED_UP,
        FINISHED,
        NON_PICKED_UP,
        NON_FINISHED,
        ORDER_U_CANCELED,
        ORDER_S_CANCELED,
        RETURN_PENDING,
        RETURN_APPROVED,
        RETURN_DENIED,
        REFUND_PENDING,
        REFUND_APPROVED,
        REFUND_DENIED,
        READY,
        DELIVERED_IN_WAREHOUSE,
        ON_ROUTE,
        CANCELED_IN_DELIVERY_POINT,
        ON_THE_WAY_TO_STORE,
        WAITING_IN_STORE,
      ];

      let orderStatus;
      statuses.forEach((status) => {
        if (details.some((detail) => detail.fk_purchaseStatusesId === status)) {
          orderStatus = status;
        }
      });

      if (!orderStatus) {
        orderStatus = STATUS.ORDER_S_CANCELED;
      }

      return orderStatus;
    };

    api
      .findAll(
        '/orders?filter=' +
          JSON.stringify({
            include: [
              {
                association: 'user',
                paranoid: false,
                attributes: [
                  'firstname',
                  'lastname',
                  'email',
                  'phone',
                  'usersId',
                ],
              },
              {
                association: 'details',
                where: {
                  fk_storesId: cookies[REACT_APP_COOKIES_STORE_ID],
                  ...(onlyPending
                    ? null
                    : {
                        createdAt: {
                          $gt: gt,
                          $lt: lt,
                        },
                      }),
                },
                attributes: [
                  'ordersDetailsId',
                  'name',
                  'qty',
                  'image',
                  'price',
                  'discount',
                  'fk_purchaseStatusesId',
                ],
                include: [
                  {
                    association: 'variant',
                    attributes: [
                      'image',
                      'sku',
                      'values',
                      'price',
                      'discount',
                      'productsVariantsId',
                    ],
                    include: [
                      {
                        association: 'product',
                        attributes: [
                          'name',
                          'hasVariant',
                          'isFragile',
                          'freeShipping',
                        ],
                        include: [
                          {
                            association: 'images',
                            attributes: ['url'],
                          },
                          {
                            association: 'flavor',
                            attributes: ['productsTypesId', 'name'],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    association: 'store_branch',
                    attributes: ['name'],
                  },
                ],
              },
            ],
            order: [['ordersId', 'DESC']],
          })
      )
      .then((res) => {
        let orders = res.data
          .filter((order) => order.details.length > 0)
          .map((order) => ({
            ...order,
            code: getOrderCode(order.ordersId),
            address: [
              order.apartment,
              order.address,
              order.city,
              order.referencePoint,
            ]
              .filter((string) => string && string.trim())
              .join(' '),
            buyer: `${order.user.firstname} ${order.user.lastname}`,
            turbo: order?.shippingType === 'TURBO' ? 'SI 🚀' : 'NO',
            status: getOrderStatus(order.details),
            createdAtDisplay: formatTimestamp(order.createdAt),
            subtotal: order.details.reduce(
              (acc, detail) =>
                acc + detail.price * (1 - detail.discount / 100) * detail.qty,
              0
            ),
          }));

        /* Sort orders by status and then by order id */
        const customStatusOrder = [
          69, 1, 2, 20, 15, 19, 9, 10, 5, 3, 12, 14, 11, 13, 6, 17, 16, 4, 7, 8,
          18,
        ];
        orders = orders.sort(
          (a, b) =>
            customStatusOrder.indexOf(a.status) -
            customStatusOrder.indexOf(b.status)
        );

        if (onlyPending) {
          orders = orders.filter((order) =>
            [STATUS.ORDER_PENDING, STATUS.ORDER_ACCEPTED].includes(order.status)
          );
          orders = orders.sort((a, b) =>
            a.ordersId > b.ordersId ? 1 : a.ordersId < b.ordersId ? -1 : 0
          );
        }
        setOrders(orders);
        onTotalChange && onTotalChange(orders.length);
      })
      .catch((err) => {
        console.error(err);
        toast.warning('[SERVER_ERROR] No se pudo obtener listado de ordenes');
      });
  }, [getOrders, month]);

  /* Update orderOnView if getOrders is requested while an order is on view */
  useEffect(() => {
    if (orders && orders.length > 0 && orderOnView) {
      setOrderOnViewById(orderOnView.ordersId);
    }
  }, [orders]);

  /* Trigger getOrders whenever isSaving change to false */
  useEffect(() => {
    if (!isSaving && getOrders !== 1) {
      setGetOrders(getOrders + 1);
    }
  }, [isSaving]);

  const updateOrderStatus = (orderId, val) => {
    setIsSaving(true);

    const messages = {
      [STATUS.ORDER_ACCEPTED]: { text: 'Orden aprobada', type: 'success' },
      [STATUS.ORDER_S_CANCELED]: { text: 'Orden cancelada', type: 'warning' },
      [STATUS.READY]: { text: 'Orden marcada como lista', type: 'success' },
      [STATUS.ORDER_PENDING]: { text: 'Orden reiniciada', type: 'warning' },
    };

    const orderStatusText = {
      [STATUS.ORDER_ACCEPTED]: 'Orden aceptada',
      [STATUS.ORDER_S_CANCELED]: 'Orden cancelada',
      [STATUS.READY]: 'Orden lista',
      [STATUS.ORDER_PENDING]: 'Orden reiniciada',
    };

    const orderOnUpdate = orders.find((order) => order.ordersId === orderId);
    let detailsToUpdate = orderOnUpdate.details.filter(
      (detail) =>
        detail.fk_purchaseStatusesId ===
        (val === STATUS.ORDER_ACCEPTED || val === STATUS.ORDER_S_CANCELED
          ? STATUS.ORDER_PENDING
          : val === STATUS.READY
          ? STATUS.ORDER_ACCEPTED
          : detail.fk_purchaseStatusesId)
    );

    Promise.allSettled(
      detailsToUpdate.map((detail) =>
        updateOrderDetailStatus(detail.ordersDetailsId, val)
      )
    )
      .then((responses) => {
        responses.forEach((res) => {
          if (res.status === 'rejected') {
            toast.warning(`[SERVER_ERROR] ${res.reason}`);
          }
        });
      })
      .then(() =>
        api.orders.statusLog.create({
          status: orderStatusText[val],
          fk_ordersId: orderId,
          createdBy: userID,
        })
      )
      .then(() =>
        api.orders.update(orderId, {
          orderStatus: orderStatusText[val],
          updatedBy: userID,
        })
      )
      .then(() => toast[messages[val].type](`¡${messages[val].text}!`))
      .catch((err) => {
        console.error(err);
        toast.warning(`[SERVER_ERROR] ${err}`);
      })
      .finally(() => {
        onlyPending && setOrderOnView(null);
        setGetOrders(getOrders + 1);
        setIsSaving(false);
      });
  };

  const updateOrderDetailStatus = async (id, status) => {
    await api.ordersDetails.update(id, {
      fk_purchaseStatusesId: status,
      updatedBy: userID,
    });

    return api.ordersDetails.statusLog.create({
      fk_purchaseStatusesId: status,
      fk_ordersDetailsId: id,
      createdBy: userID,
    });
  };

  const updateProductStock = (productVariant, qty) => {
    productVariant.forEach((variant, index) => {
      api.variantsStocks
        .update(variant.stock[0].productsVariantsStocksId, {
          stock: variant.stock[0].stock + qty[index],
          updatedBy: userID,
        })
        .catch((err) => {
          console.error(err);
          toast.warning(`[SERVER_ERROR] ${err}`);
        });
    });
  };
  const [orderOnView, setOrderOnView] = useState();

  const setOrderOnViewById = (id) => {
    setOrderOnView({ ...orders.find((order) => order.ordersId === id) });
  };

  return (
    <div className="content d-flex flex-column" style={{ flexGrow: '1' }}>
      {!onlyPending && (
        <div className="row">
          <div className="col-12 col-md-8 col-xl-9 d-flex align-items-center">
            <h3 className="text-dark-blue font-size-2x font-weight-bold">
              Órdenes
            </h3>
          </div>
          <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-3 mt-md-0">
            <Datetime
              inputProps={{ style: { textAlign: 'center' } }}
              closeOnSelect={true}
              initialValue={today}
              isValidDate={(date) => date.isBefore(moment())}
              onChange={(date) => {
                setMonth(date.month());
                setYear(date.year());
              }}
              timeFormat={false}
              dateFormat="YYYY-MM"
            />
          </div>
        </div>
      )}
      <div
        className={`row ${onlyPending ? '' : 'mt-3 mt-md-4'}`}
        style={!orders ? { flexGrow: '1' } : null}
      >
        <div className="col-12">
          <div className="pt-2">
            {orders ? (
              orders.length > 0 ? (
                <Table
                  showDateFilter={!onlyPending}
                  columns={ordersTable.columns}
                  data={orders}
                  onChoiceChange={(orderId, newVal) =>
                    updateOrderStatus(
                      orderId,
                      newVal ? STATUS.ORDER_ACCEPTED : STATUS.ORDER_S_CANCELED
                    )
                  }
                  onRowClick={(orderId) => setOrderOnViewById(orderId)}
                  options={ordersTable.options}
                  updateOrderStatus={(orderId, val) =>
                    updateOrderStatus(orderId, val)
                  }
                  pageSize={10}
                  isSaving={isSaving}
                />
              ) : (
                <div className="d-flex flex-column align-items-center">
                  <p className="text-center">
                    No hay ninguna orden {onlyPending ? 'pendiente' : ''} para
                    mostrar
                  </p>
                  {onlyPending && (
                    <Link to="/admin/orders" className="mt-2">
                      <p className="tuyo-link text-green font-weight-bold font-size-125x px-3 py-1 rounded">
                        Ir a Órdenes
                      </p>
                    </Link>
                  )}
                </div>
              )
            ) : (
              <div
                className="p-5 m-5 d-flex justify-content-center align-items-center"
                style={{ flexGrow: '1' }}
              >
                <ClimbingBoxLoader color={colors.green} size="25" />
              </div>
            )}
          </div>
        </div>
      </div>

      {orderOnView && (
        <OrderApproval
          isSaving={isSaving}
          order={orderOnView}
          requestGetOrders={() => setGetOrders(getOrders + 1)}
          updateOrderStatus={(val) =>
            updateOrderStatus(orderOnView.ordersId, val)
          }
          updateProductStock={(val, qty) => updateProductStock(val, qty)}
          onCloseRequest={() => setOrderOnView(null)}
        />
      )}
    </div>
  );
}

export default Orders;
