import React from 'react';

import { status } from '../../assets/resources/status';
import ReactTooltip from 'react-tooltip';

function Status({ value, dataTip, className }) {
  const displayStatus = status.find((status) => value === status.id);

  return (
    <>
      <div
        data-tip={dataTip}
        className={`d-inline-block py-1 px-4 text-white rounded font-weight-bold text-center ${
          displayStatus.className
        } ${className || ''}`}
      >
        {displayStatus.text}
      </div>
      <ReactTooltip type="info" multiline place="bottom" effect="solid" />
    </>
  );
}

export default Status;
