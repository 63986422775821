import { ErrorMessage, Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { ClimbingBoxLoader } from 'react-spinners';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SweetAlert from 'react-bootstrap-sweetalert';
import Table from 'components/Table/Table';
import { VOUCHER_ICONS } from 'utils/misc';
import { VOUCHER_STATUS } from 'utils/misc';
import api from 'utils/api';
import colors from 'assets/resources/colors';
import { getStoreNameFromJWT } from 'utils/misc';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';

function PaymentLinks() {
  const {
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  } = process.env;
  const [cookies] = useCookies([
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  ]);
  const [modal, setModal] = useState({
    isOpen: false,
    data: null,
  });
  const [qrModal, setQrModal] = useState({
    isOpen: false,
    data: null,
  });
  const [lastVoucher, setLastVoucher] = useState([]);

  const tableColumns = {
    columns: [
      {
        text: 'Pagador',
        className: 'text-center',
        isCustom: true,
        render(payer) {
          return (
            <span>
              {payer?.firstname} {payer?.lastname}
            </span>
          );
        },
      },
      {
        text: 'Fecha',
        key: 'createdAt',
        className: 'text-center',
        isDate: true,
      },
      {
        text: 'Estado',
        key: 'status',
        className: 'text-center',
        isCustom: true,
        render(status) {
          return (
            <div
              style={{
                backgroundColor: VOUCHER_ICONS[status].color,
                fontWeight: 600,
                width: 150,
                margin: 'auto',
              }}
              className="rounded p-1 text-white text-center"
            >
              {VOUCHER_STATUS[status] || ''}
            </div>
          );
        },
      },
      {
        text: 'Concepto',
        key: 'concept',
        className: 'text-center',
      },
      {
        text: 'Monto',
        key: 'amount',
        className: 'text-center',
        isCurrency: true,
      },
    ],
    options: {
      id: 'paymentVouchersId',
    },
  };

  const getLastVouchers = (startDate = new Date(), endDate = new Date()) => {
    api.payment
      .getPaymentVouchersByDate({
        status: 'PENDING',
        limit: 5,
        paymentStoreId: cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID],
        attributes: [
          'paymentVouchersId',
          'amount',
          'ref',
          'createdAt',
          'status',
          'store',
          'firstname',
          'lastname',
          'concept',
          // 'fistname',
          // 'lastname',
          // 'email',
        ],
      })
      .then((res) => {
        const data = res.data ?? [];

        setLastVoucher(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function onSubmit(values,{
    setSubmitting,
  }) {
    const jwt = cookies[REACT_APP_COOKIES_JWT];
    const name = getStoreNameFromJWT(jwt);
    const userId = cookies[REACT_APP_COOKIES_USER_ID];
    const paymentStoreId = cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID];

    api.payment
      .createPaymentLink({
        amount: +values.amount,
        concept: values.concept,
        createdBy: userId,
        store: name,
        fk_paymentStoresId: paymentStoreId,
      })
      .then((res) => {
        toast.success('Se ha creado el link correctamente');
        setModal({
          isOpen: true,
          data: res.data,
        });
        getLastVouchers();
      })
      .catch((error) => {
        toast.error('No se pudo crear el link');
        console.error(error);
      }).finally(() => {
        setSubmitting(false);
      });
  }

  function getPaymentVoucher(id) {
    api.payment
      .getPaymentLink(id)
      .then((res) => {
        setModal({
          isOpen: true,
          data: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al generar el link de pago');
      });
  }

  const closeModal = () => setModal({ isOpen: false, data: null });

  const getStoreQR = () => {
    const paymentStoresId = cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID];

    api.payment
      .getPaymentStore({
        where: {
          paymentStoresId,
        },
        attributes: ['qr'],
      })
      .then((res) => {
        setQrModal({
          isOpen: true,
          data: res.data[0]?.qr || '',
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Set title
  useEffect(() => {
    document.title = `Links de pago | ${process.env.REACT_APP_TITLE}`;
  }, []);

  useEffect(() => {
    getLastVouchers();
    getStoreQR();
  }, []);

  return (
    <div className="row">
      {modal.isOpen && (
        <SweetAlert
          showConfirm={false}
          onConfirm={closeModal}
          onCancel={closeModal}
        >
          <div>
            <h2>Link de pago</h2>
            <div className="row">
              <div className="col-12 mb-4">
                <CopyToClipboard
                  text={`${modal.data?.link}`}
                  onCopy={() => toast.success('Link copiado')}
                >
                  <div
                    style={{
                      backgroundColor: '#26DB9480',
                      cursor: 'pointer',
                    }}
                    className="px-2 py-4 mx-2 mt-4 rounded-lg row flex justify-content-center align-items-center"
                  >
                    <p
                      style={{
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                      }}
                      className="col-10"
                    >
                      {`${modal.data?.link}`}
                    </p>
                    <div className=" col-2 ">
                      <i className="material-icons text-left">content_copy</i>
                    </div>
                  </div>
                </CopyToClipboard>
              </div>
              <img
                style={{
                  width: 300,
                  height: 300,
                  objectFit: 'contain',
                }}
                className="col-12"
                src={modal.data?.qr}
                alt="qr"
              />
            </div>
          </div>
        </SweetAlert>
      )}
      <div className="col-12 col-md-6">
        <div className="col-12 col-lg-8 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Crear link de pago
          </h3>
        </div>
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            amount: 0,
            concept: '',
          }}
          validate={(values) => {
            let errors = {};
            if (!values.amount) errors.amount = 'Este campo es obligatorio';
            if (isNaN(values.amount))
              errors.amount = 'Este campo debe ser un numero';
            if (values.amount < 0)
              errors.amount = 'Este campo debe ser un numero positivo';

            if (!values.concept) errors.concept = 'Este campo es obligatorio';

            return errors;
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <div className="p-4 row">
              <label htmlFor="amount" className="col-12 col-form-label">
                Cantidad
              </label>
              <Field className="col-12 mt-1 p-2" name="amount" />
              <ErrorMessage name="amount">
                {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
              </ErrorMessage>
              <label htmlFor="concept" className="col-12 col-form-label mt-4">
                Concepto
              </label>
              <Field className="col-12 mt-1 p-2" name="concept" />
              <ErrorMessage name="concept">
                {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
              </ErrorMessage>
              <button
                disabled={isSubmitting}
                className="col-12 tuyo-btn mt-2 bg-green font-weight-bold rounded"
                type="submit"
                onClick={submitForm}
              >
                <label
                  className="py-2 cursor-pointer text-white w-100 d-flex justify-content-around align-items-center"
                  htmlFor="createPaymentLink"
                >
                  {isSubmitting ? 'Creando link...' : 'Crear link'}
                </label>
              </button>
            </div>
          )}
        </Formik>
      </div>
      <div className="col-12 col-md-6">
        <p className="text-dark-blue font-weight-bold font-size-125x mb-2 mt-4">
          QR de tienda
        </p>
        {qrModal?.data ? (
          <div
            style={{
              objectFit: 'contain',
              width: '80%',
              margin: 'auto',
            }}
            className="d-flex justify-content-center"
          >
            <img className="col-12" src={qrModal?.data} alt="qr" style={{ maxWidth: '271px', maxHeight: '271px' }} />
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <ClimbingBoxLoader color={colors.green} size="25" />
          </div>
        )}
      </div>
      <div className="col-12">
        <p className="text-dark-blue font-weight-bold font-size-125x mb-2 mt-4">
          Ultimos 5 links de pago
        </p>
        <Table
          onRowClick={getPaymentVoucher}
          columns={tableColumns.columns}
          options={tableColumns.options}
          data={lastVoucher}
          pageSize={10}
        />
      </div>
    </div>
  );
}

export default PaymentLinks;
