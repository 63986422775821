import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import BounceLoader from 'react-spinners/BounceLoader';
import ReactTooltip from 'react-tooltip';
import swal from '@sweetalert/with-react';
import { toast } from 'react-toastify';

import api from '../utils/api';
import { validQty, updateSearchParam } from 'utils/misc';
import colors from 'assets/resources/colors';

import Table from 'components/Table/Table';
import Modal from 'components/Modal/Modal';

import addSVG from 'assets/icons/add-black.svg';
import deleteSVG from 'assets/icons/delete.svg';

function Inventory() {
  useEffect(() => {
    document.title = 'Inventario | Dashboard Tuyo';
  }, []);

  const {
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_SPACES_NAME,
    REACT_APP_SPACES_ENDPOINT,
    REACT_APP_S3_CSV_TEMPLATE_URI_EN,
    REACT_APP_S3_CSV_TEMPLATE_URI_ES,
  } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_STORE_ID]);

  const userID = cookies[REACT_APP_COOKIES_USER_ID];
  const storeID = cookies[REACT_APP_COOKIES_STORE_ID];

  const productsTable = {
    options: {
      id: 'productsId',
    },
    columns: [
      { text: '', key: 'image', isImage: true, className: 'text-center' },
      { text: 'Producto', key: 'name', className: 'text-center' },
      { text: 'SKU', key: 'sku', className: 'text-center' },
      { text: 'Categoría', key: 'category', className: 'text-center' },
      { text: 'Subcategoría', key: 'subcategory', className: 'text-center' },
      { text: 'Cantidad', key: 'stock', className: 'text-center', isQty: true },
      {
        text: 'Precio unitario',
        key: 'regularPrice',
        className: 'text-center',
        isCurrency: true,
      },
    ],
  };

  const [products, setProducts] = useState();
  const [productOnView, setProductOnView] = useState();
  const [features, setFeatures] = useState([]);
  const [branchOffices, setBranchOffices] = useState();
  const [variantsOnView, setVariantsOnView] = useState([]);
  const [total, setTotal] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [stocksToDelete, setStocksToDelete] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [getProducts, setGetProducts] = useState(1);
  const [productTypes, setProductTypes] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState(
    parseInt(new URLSearchParams(window.location.search).get('productType'))
  );

  // Get product types
  useEffect(() => {
    api
      .findAll(
        '/productstypes?filter=' +
          JSON.stringify({ attributes: ['productsTypesId', 'name'] })
      )
      .then((response) => {
        setProductTypes(response.data);
      })
      .catch((error) => {
        toast.warning('No se pudo obtener listado de tipos de producto');
      });
  }, []);

  // Set first product type as selectedProductType
  useEffect(() => {
    if (productTypes?.length > 0 && !selectedProductType) {
      setSelectedProductType(productTypes[1].productsTypesId);
    }
  }, [productTypes]);

  // Add productType URL's search parameter
  useEffect(() => {
    if (selectedProductType) {
      updateSearchParam('productType', selectedProductType);
    }
  }, [selectedProductType]);

  /* Get products from API when modal is hidden and on componentWillMount */
  useEffect(() => {
    if (selectedProductType) {
      setProducts(null);

      api
        .findAll(
          '/products?filter=' +
            JSON.stringify({
              where: {
                fk_storesId: storeID,
                fk_productsTypesId: selectedProductType,
              },
              attributes: ['productsId', 'name', 'sku', 'regularPrice'],
              include: [
                {
                  association: 'subcategory',
                  attributes: ['name'],
                },
                {
                  association: 'images',
                  attributes: ['url', 'order'],
                },
                {
                  association: 'category',
                  attributes: ['name'],
                },
                {
                  association: 'variants',
                  attributes: [
                    'productsVariantsId',
                    'attributes',
                    'values',
                    'image',
                  ],
                  include: [
                    {
                      association: 'stock',
                      attributes: [
                        'productsVariantsStocksId',
                        'stock',
                        'fk_storesBranchesId',
                        'fk_productsVariantsId',
                      ],
                    },
                  ],
                },
              ],
              order: [['createdAt', 'desc']],
            })
        )
        .then((res) => {
          setProducts(
            res.data.map((product) => ({
              ...product,
              image: product.images[0]?.url,
              category: product.category.name,
              subcategory: product.subcategory.name,
              stock: product.variants.reduce(
                (accV, variant) =>
                  accV +
                  variant.stock.reduce((accS, stock) => accS + stock.stock, 0),
                0
              ),
            }))
          );
        })
        .catch((err) => {
          console.error(err);
          toast.warning('No se pudo obtener listado de productos');
        });
    }
  }, [getProducts, selectedProductType]);

  /* Get branch offices on componentDidMount */
  useEffect(() => {
    api.branchOffices
      .getByStore(storeID)
      .then((res) => {
        setBranchOffices(res.data);
      })
      .catch((err) => {
        toast.warning(
          '[SERVER_ERROR] No se pudo obtener listado de sucursales'
        );
        console.error(err);
      });
  }, []);

  /* Create structure to display on click */
  useEffect(() => {
    if (
      products &&
      products.length > 0 &&
      branchOffices &&
      branchOffices.length > 0
    ) {
      products.forEach((product) => {
        product.variants.forEach((variant) => {
          const attributesArray = variant.attributes
            .split(',')
            .filter((attribute) => attribute.trim());
          const valuesArray = variant.values
            .split(',')
            .filter((val) => val.trim());

          variant.features = attributesArray.map((feature, index) => ({
            key: attributesArray[index],
            val: valuesArray[index],
          }));

          if (variant.stock.length < 1) {
            variant.stock = [
              {
                fk_storesBranchesId: branchOffices[0].storesBranchesId,
                stock: 0,
              },
            ];
          }
        });

        product.maxFeatures = product.variants.reduce(
          (max, variant) => variant.features.length,
          1
        );
      });
    }
  }, [products, branchOffices]);

  /* Calculate total on variantsOnView change */
  useEffect(() => {
    if (variantsOnView) {
      setTotal(
        variantsOnView.reduce(
          (acc, variant) =>
            acc +
            variant.stock.reduce(
              (acc2, branchOffice) => acc2 + parseInt(branchOffice.stock),
              0
            ),
          0
        )
      );
    }
  }, [variantsOnView]);

  useEffect(() => {
    if (productOnView) {
      const tmp = [];
      for (let i = 0; i < productOnView.maxFeatures; i++) {
        tmp.push(null);
      }

      setFeatures([...tmp]);
      setVariantsOnView([
        ...productOnView.variants.sort((a, b) =>
          a.productsVariantsId > b.productsVariantsId
            ? 1
            : a.productsVariantsId < b.productsVariantsId
            ? -1
            : 0
        ),
      ]);
    } else {
      setFeatures([null]);
      variantsOnView.length > 0 && setVariantsOnView([]);
      setIsSaving(false);
      setHasUnsavedChanges(false);
    }
  }, [productOnView]);

  const addBranchOffice = (variantId) => {
    const variantsTmp = [...variantsOnView];
    const variantToEdit = variantsTmp.find(
      (variant) => variant.productsVariantsId === variantId
    );
    const alreadySelectedBranchOffices = variantToEdit.stock.reduce(
      (acc, branchOffice) => [...acc, branchOffice.fk_storesBranchesId],
      []
    );
    variantToEdit.stock.push({
      fk_storesBranchesId: branchOffices.find(
        (branchOffice) =>
          alreadySelectedBranchOffices.indexOf(
            branchOffice.storesBranchesId
          ) === -1
      ).storesBranchesId,
      stock: 0,
    });
    setVariantsOnView([...variantsTmp]);
    setHasUnsavedChanges(true);
  };

  const updateStock = (variantId, branchOfficeId, newVal) => {
    const variantsTmp = [...variantsOnView];
    const variantOnEdit = variantsTmp.find(
      (variant) => variant.productsVariantsId === variantId
    );
    variantOnEdit.stock.find(
      (branchOffice) => branchOffice.fk_storesBranchesId === branchOfficeId
    ).stock = newVal;
    setVariantsOnView([...variantsTmp]);
    setHasUnsavedChanges(true);
  };

  const removeBranchOffice = (variantId, branchOfficeId) => {
    const variantsTmp = [...variantsOnView];
    const variantOnEdit = variantsTmp.find(
      (variant) => variant.productsVariantsId === variantId
    );
    const branchOfficeToDelete = variantOnEdit.stock.find(
      (branchOffice) => branchOffice.fk_storesBranchesId === branchOfficeId
    );

    if (branchOfficeToDelete.productsVariantsStocksId) {
      setStocksToDelete([
        ...stocksToDelete,
        branchOfficeToDelete.productsVariantsStocksId,
      ]);
    }

    variantOnEdit.stock = variantOnEdit.stock.filter(
      (branchOffice) => branchOffice.fk_storesBranchesId !== branchOfficeId
    );
    setVariantsOnView([...variantsTmp]);
    setHasUnsavedChanges(true);
  };

  const checkBeforeLeaving = () => {
    if (hasUnsavedChanges) {
      swal({
        buttons: ['Cancelar', 'Salir sin guardar'],
        dangerMode: true,
        content: (
          <div className="p-4">
            <p className="font-weight-bold text-dark-blue font-size-2x">
              Tienes modificaciones sin guardar
            </p>
          </div>
        ),
      })
        .then((res) => {
          if (res) {
            setProductOnView(null);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setProductOnView(null);
    }
  };

  const save = () => {
    setIsSaving(true);

    const stocksToCreate = [];
    const stocksToUpdate = [];

    variantsOnView.forEach((variant) => {
      variant.stock.forEach((branchOffice) => {
        if (!branchOffice.productsVariantsStocksId) {
          stocksToCreate.push({
            stock: branchOffice.stock,
            fk_productsVariantsId: variant.productsVariantsId,
            fk_storesBranchesId: branchOffice.fk_storesBranchesId,
            createdBy: userID,
          });
        } else {
          stocksToUpdate.push({ ...branchOffice });
        }
      });
    });

    const showUpdatedStockMessage = () => {
      toast.success('¡Inventario actualizado!');
      setProductOnView(null);
      setGetProducts(getProducts + 1);
    };

    const deleteStocks = () => {
      stocksToDelete.forEach((branchOffice, index) => {
        api.variantsStocks
          .delete(branchOffice)
          .then(() => {
            if (index === stocksToDelete.length - 1) {
              showUpdatedStockMessage();
            }
          })
          .catch((err) => {
            console.error(err);
            toast.warning('[SERVER_ERROR] No se pudo eliminar un stock');
            setIsSaving(false);
          });
      });
    };

    const updateStocks = () => {
      stocksToUpdate.forEach((branchOffice, index) => {
        api.variantsStocks
          .update(branchOffice.productsVariantsStocksId, {
            stock: branchOffice.stock,
            fk_storesBranchesId: branchOffice.fk_storesBranchesId,
            updatedBy: userID,
          })
          .then(() => {
            if (index === stocksToUpdate.length - 1) {
              if (stocksToDelete.length > 0) {
                deleteStocks();
              } else {
                showUpdatedStockMessage();
              }
            }
          })
          .catch((err) => {
            console.error(err);
            toast.warning('[SERVER_ERROR] No se pudo actualizar un stock');
            setIsSaving(false);
          });
      });
    };

    if (stocksToCreate.length > 0) {
      api.variantsStocks
        .create(stocksToCreate)
        .then(() => {
          if (stocksToUpdate.length > 0) {
            updateStocks();
          } else if (stocksToDelete.length > 0) {
            deleteStocks();
          } else {
            showUpdatedStockMessage();
          }
        })
        .catch((err) => {
          console.error(err);
          toast.warning('[SERVER_ERROR] No se pudo crear stocks nuevos');
          setIsSaving(false);
        });
    } else if (stocksToUpdate.length > 0) {
      updateStocks();
    } else if (stocksToDelete.length > 0) {
      deleteStocks();
    }
  };

  const uploadCSV = async (event) => {
    setIsUploading(true);

    const fileName = event.target.files[0].name;

    api.products
      .importFromCSV({
        file: event.target.files[0],
        userId: userID,
        storeId: storeID,
        storeBranchId: branchOffices[0].storesBranchesId,
        language: fileName.substring(
          fileName.lastIndexOf('-') + 1,
          fileName.lastIndexOf('.')
        ),
      })
      .then(() => {
        toast.success('¡Productos importados!');
        setGetProducts(getProducts + 1);
      })
      .catch((err) => {
        console.error(err);
        toast.warning('[SERVER_ERROR] No se pudo importar productos');
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <div className="content d-flex flex-column" style={{ flexGrow: '1' }}>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 col-lg-2">
          <p className="text-dark-blue font-size-2x font-weight-bold text-center text-md-left w-100">
            Inventario
          </p>
        </div>
        <div className="col-12 col-md-6 col-lg-4 d-flex align-items-center">
          <label htmlFor="product-type">Tipo de producto</label>
          <select
            value={selectedProductType}
            onChange={(event) => {
              setSelectedProductType(parseInt(event.target.value));
            }}
            name="product-type"
            id="product-type"
            className="h-100 flex-grow-1 ml-2"
          >
            {productTypes.map((type) => (
              <option key={type.productsTypesId} value={type.productsTypesId}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-6 col-lg-3 d-flex justify-content-center justify-content-md-end mt-2 mt-md-0">
          <small className="font-italic">
            <a
              className="d-block font-weight-bold"
              href={`https://${REACT_APP_SPACES_NAME}.${REACT_APP_SPACES_ENDPOINT}/${REACT_APP_S3_CSV_TEMPLATE_URI_ES}`}
              download
            >
              Descargar plantilla Excel español
            </a>
            <a
              className="d-block font-weight-bold"
              href={`https://${REACT_APP_SPACES_NAME}.${REACT_APP_SPACES_ENDPOINT}/${REACT_APP_S3_CSV_TEMPLATE_URI_EN}`}
              download
            >
              Descargar plantilla Excel inglés
            </a>
          </small>
        </div>
        <div className="col-12 col-md-6 col-lg-3 t-2 mt-md-0">
          <button
            disabled={isUploading}
            className="tuyo-btn w-100 bg-purple font-weight-bold rounded"
            type="button"
          >
            <label
              className="py-2 cursor-pointer text-white w-100 d-flex justify-content-around align-items-center"
              htmlFor="csv"
            >
              {isUploading ? 'Importando' : 'Importar'} productos
              <BounceLoader color="#fff" loading={isUploading} size="18" />
            </label>
            <input
              disabled={isUploading}
              onChange={(event) => {
                if (branchOffices && branchOffices.length > 0) {
                  uploadCSV(event);
                } else {
                  toast.error(
                    'No puedes importar un CSV sin antes haber configurado el lugar de recolección de tus productos'
                  );
                }
              }}
              className="d-none"
              type="file"
              id="csv"
              accept=".csv"
            />
          </button>
        </div>
      </div>
      <div
        className="row mt-3 mt-md-4"
        style={!products ? { flexGrow: '1' } : null}
      >
        <div className="col-12">
          <div className="pt-2">
            {products ? (
              <Table
                rowWarning={{ field: 'stock', value: 5 }}
                showFilters
                onRowClick={(productId) => {
                  if (branchOffices && branchOffices.length > 0) {
                    setProductOnView({
                      ...products.find(
                        (product) => product.productsId === productId
                      ),
                    });
                  } else {
                    toast.error(
                      'No puedes añadir stock sin antes haber configurado el lugar de recolección de tus productos'
                    );
                  }
                }}
                columns={productsTable.columns}
                data={products}
                options={productsTable.options}
                pageSize={10}
              />
            ) : (
              <div
                className="p-5 m-5 d-flex justify-content-center align-items-center"
                style={{ flexGrow: '1' }}
              >
                <ClimbingBoxLoader color={colors.green} size="25" />
              </div>
            )}
          </div>
        </div>
      </div>

      {productOnView && (
        <Modal onCloseRequest={checkBeforeLeaving}>
          <div className="row align-items-center">
            <div className="col-12 col-md-8 col-lg-9 col-xl-10 font-weight-bold font-size-175x">
              {productOnView.name}
            </div>
            <div className="col-12 col-md-4 col-lg-3 col-xl-2 mt-2 mt-md-0">
              <button
                onClick={save}
                type="button"
                disabled={isSaving}
                className="w-100 tuyo-btn bg-green text-white font-weight-bold py-2 rounded d-flex align-items-center justify-content-around"
              >
                {isSaving ? 'Guardando' : 'Guardar'}
                <BounceLoader color="#fff" loading={isSaving} size="18" />
              </button>
            </div>
            <div className="col-12 mt-3 mt-md-0">
              <p className="text-center text-md-left font-size-125x font-weight-bold text-purple">
                {total || 0} unidades
              </p>
            </div>
            <div className="col-12 mt-3" style={{ overflowX: 'auto' }}>
              <table className="tuyo-table">
                <thead>
                  <tr>
                    <th>Variante</th>
                    <th style={{ width: '8%' }} />
                    {branchOffices && branchOffices.length > 1 && (
                      <>
                        <th style={{ minWidth: '4%' }} />
                        <th style={{ minWidth: '4%' }} />
                      </>
                    )}
                    {productOnView.hasVariant &&
                      features.map((feature, index) => (
                        <React.Fragment key={index}>
                          <th>Atributo {index + 1}</th>
                          <th>Valor {index + 1}</th>
                        </React.Fragment>
                      ))}
                    <th>Cantidad</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {variantsOnView &&
                    variantsOnView.map((variant) => (
                      <React.Fragment key={variant.productsVariantsId}>
                        {variant.stock &&
                          variant.stock.length > 0 &&
                          variant.stock.map(
                            (branchOffice, branchOfficeIndex) => (
                              <tr
                                className={`text-center ${
                                  branchOffice.stock <= 5 ? 'low-stock' : ''
                                }`}
                                style={{
                                  backgroundColor: 'rgba(0,0,0,0.025)',
                                  border: 'none',
                                }}
                                key={branchOffice.fk_storesBranchesId}
                              >
                                <td>{variant.values.split(',').join(' ')}</td>
                                <td
                                  style={{
                                    width: '8%',
                                    backgroundImage: `url('${
                                      productOnView.hasVariant
                                        ? variant.image
                                        : productOnView.images.length > 0
                                        ? productOnView.images[0].url
                                        : ''
                                    }')`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center center',
                                    backgroundRepeat: 'no-repeat',
                                  }}
                                />
                                {branchOffices && branchOffices.length > 1 && (
                                  <>
                                    <td className="text-center py-2">
                                      {branchOfficeIndex === 0 &&
                                        variant.stock.length <
                                          branchOffices.length && (
                                          <>
                                            <button
                                              onClick={() =>
                                                addBranchOffice(
                                                  variant.productsVariantsId
                                                )
                                              }
                                              type="button"
                                              className="p-2 opt-btn"
                                              data-tip="Añadir sucursal"
                                            >
                                              <img
                                                src={addSVG}
                                                alt=""
                                                style={{ height: '16px' }}
                                              />
                                            </button>
                                            <ReactTooltip effect="solid" />
                                          </>
                                        )}
                                    </td>
                                    <td className="text-center py-2">
                                      {variant.stock.length > 1 && (
                                        <>
                                          <button
                                            onClick={() =>
                                              removeBranchOffice(
                                                variant.productsVariantsId,
                                                branchOffice.fk_storesBranchesId
                                              )
                                            }
                                            type="button"
                                            className="p-2 opt-btn"
                                            data-tip="Remover sucursal"
                                          >
                                            <img
                                              src={deleteSVG}
                                              alt=""
                                              style={{ height: '16px' }}
                                            />
                                          </button>
                                          <ReactTooltip effect="solid" />
                                        </>
                                      )}
                                    </td>
                                  </>
                                )}
                                {productOnView.hasVariant &&
                                  variant.features &&
                                  variant.features.map(
                                    (feature, featureIndex) => (
                                      <React.Fragment key={featureIndex}>
                                        <td>{feature.key}</td>
                                        <td>{feature.val}</td>
                                      </React.Fragment>
                                    )
                                  )}
                                {variant.features &&
                                  features.length > variant.features.length &&
                                  features
                                    .slice(
                                      variant.features.length - features.length
                                    )
                                    .map((feature, index) => (
                                      <React.Fragment key={index}>
                                        <td />
                                        <td />
                                      </React.Fragment>
                                    ))}
                                <td className="p-1" style={{ minWidth: '25%' }}>
                                  <input
                                    style={{ width: '90%' }}
                                    className="rounded p-1"
                                    type="text"
                                    onChange={(event) =>
                                      updateStock(
                                        variant.productsVariantsId,
                                        branchOffice.fk_storesBranchesId,
                                        validQty(event.target.value)
                                      )
                                    }
                                    value={branchOffice.stock}
                                  />
                                </td>
                                <td>
                                  {branchOffice.productsVariantsStocksId && (
                                    <button
                                      type="button"
                                      data-tip="Eliminar"
                                      className="p-2 mx-1 opt-btn"
                                      onClick={() => {
                                        api.variantsStocks
                                          .delete(
                                            branchOffice.productsVariantsStocksId
                                          )
                                          .then(() => {
                                            toast.success('Stock eliminado');
                                            setGetProducts(getProducts + 1);
                                          })
                                          .catch((err) => {
                                            console.error(err);
                                            toast.warning(
                                              `[SERVER_ERROR] ${err}`
                                            );
                                          });
                                      }}
                                    >
                                      <img
                                        src={deleteSVG}
                                        alt=""
                                        style={{ height: '18px' }}
                                      />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        <tr style={{ height: '1rem', border: 'none' }} />
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Inventory;
