import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import api from '../utils/api';
import Card from 'components/Card/Card';
import { useCookies } from 'react-cookie';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { BounceLoader } from 'react-spinners';

function SendNotification(props) {
  const {
    REACT_APP_TITLE,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_STORE_ID,
  } = process.env;

  const [cookies] = useCookies([
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_STORE_ID,
  ]);

  const storeId = cookies[REACT_APP_COOKIES_STORE_ID];
  const userId = cookies[REACT_APP_COOKIES_USER_ID];

  useEffect(() => {
    document.title = `Enviar notificacion | ${REACT_APP_TITLE}`;
  }, []);

  const initialValues = {
    title: '',
    content: '',
    startDate: '',
    endDate: '',
  };

  const onValidate = (values) => {
    const errors = {};

    if (!values.title) errors.title = 'Requerido';
    if (!values.content) errors.content = 'Requerido';
    if (!values.startDate) errors.startDate = 'Requerido';
    if (!values.endDate) errors.endDate = 'Requerido';

    const _startDate = new Date(values.startDate);
    const _endDate = new Date(values.endDate);
    const _30Days = 30 * 24 * 60 * 60 * 1000;

    if (_startDate > _endDate) {
      errors.startDate = 'La fecha de inicio debe ser menor a la fecha final';
    }

    if (_endDate - _startDate > _30Days) {
      errors.endDate = 'El rango de fechas no puede ser mayor a 30 dias';
    }

    return errors;
  };

  const onSubmit = (values, { setSubmitting }) => {
    api.oneSignal
      .notify({
        ...values,
        storesId: storeId,
        createdBy: userId,
      })
      .then(() => {
        toast.success('Notificacion enviada');
        props.onCloseRequest();
      })
      .catch((error) => {
        console.error(error);
        toast.warning('No se pudo enviar la notificacion');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="content d-flex flex-column" style={{ flexGrow: '1' }}>
      <h3 className="text-dark-blue font-size-2x font-weight-bold mb-4">
        Enviar notificacion
      </h3>
      <Card>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={onValidate}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              <div className="row">
                <div className="col-12 col-md-6 d-flex flex-column">
                  <label htmlFor="startDate">Inicio</label>
                  <Field className="rounded p-2" type="date" name="startDate" />
                  <ErrorMessage
                    name="startDate"
                    component="span"
                    className="text-danger"
                  />
                </div>
                <div className="col-12 col-md-6 d-flex flex-column">
                  <label htmlFor="endDate">Final</label>
                  <Field className="rounded p-2" type="date" name="endDate" />
                  <ErrorMessage
                    name="endDate"
                    component="span"
                    className="text-danger"
                  />
                </div>

                <div className="col-12 d-flex flex-column my-2">
                  <label htmlFor="title">Titulo</label>
                  <Field className="rounded p-2" name="title" />
                  <ErrorMessage
                    name="title"
                    component="span"
                    className="text-danger"
                  />
                </div>

                <div className="col-12 d-flex flex-column">
                  <label htmlFor="content">Contenido</label>
                  <Field className="rounded p-2" name="content" />
                  <ErrorMessage
                    name="content"
                    component="span"
                    className="text-danger"
                  />
                </div>

                <div className="col-12 d-flex flex-column mt-3">
                  <button
                    type="submit"
                    disabled={isSubmitting || !dirty || !isValid}
                    className="w-100 bg-purple tuyo-btn px-3 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                  >
                    {isSubmitting ? 'Enviando...' : 'Enviar notificacion'}
                    <BounceLoader
                      color="#fff"
                      loading={isSubmitting}
                      size="18"
                    />
                  </button>
                </div>

                <hr />
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
}

export default SendNotification;
