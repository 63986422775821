import React from 'react';

import starSVG from '../../assets/icons/star.svg';
import starGreenSVG from '../../assets/icons/star-green.svg';
import starYellowSVG from '../../assets/icons/star-yellow.svg';
import starOutlineSVG from '../../assets/icons/star-outline.svg';

function Stars({ fill, size, color }) {
  return (
    <div className="d-flex">
      {
        [1, 2, 3, 4, 5].map((star, index) => (
          <img
            key={index}
            style={{
              marginRight: '8px',
              height: size === 'lg' ? '24px' : '16px'
            }}
            src={fill >= star
              ? color === 'green'
                ? starGreenSVG
                : color === 'yellow'
                  ? starYellowSVG
                  : starSVG
              : starOutlineSVG} alt="" />
        ))
      }
    </div>
  );
}

export default Stars;
