import React from 'react';

import { getOrderCode } from 'utils/misc';

import logotype from 'assets/img/logotype-ticket.jpg';
import oneLink from 'assets/img/qr-code.png';

function Ticket({ order }) {
  return (
    <div
      style={{
        width: '102mm',
        height: '152mm',
        maxWidth: '102mm',
        maxHeight: '152mm',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <img src={logotype} alt="" style={{ width: '50%' }} className="mb-4" />
      <h2 style={{ fontWeight: 800, marginBottom: '1rem !important' }}>
        {`ORDEN TY-${getOrderCode(order?.ordersId)} (${order?.ordersId})`}
      </h2>
      <div>
        <h4 style={{ fontWeight: 600, margin: 0 }}>
          {order?.user?.firstname} {order?.user?.lastname}
        </h4>
        <h4
          style={{
            fontWeight: 600,
            marginBottom: '1rem !important',
            margin: 0,
          }}
        >
          +503 {order?.user?.phone}
        </h4>
        <h4 style={{ fontWeight: 600 }}>
          {[
            order?.referencePoint,
            order?.apartment,
            order?.address,
            order?.city,
            order?.state,
          ]
            .filter((value) => value ?? ''.length > 1)
            .join(', ')}
        </h4>
        {order?.note && (
          <h4 style={{ fontWeight: 600, marginBottom: '1rem' }}>
            Indicacion especial: {order?.note}
          </h4>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            fontWeight: 600,
            textAlign: 'right',
            paddingBottom: '0 !important',
            padding: '0.5rem',
            margin: 0,
          }}
        >
          Gracias por elegir Tuyo. Los productos son ahora Tuyos.
          ¡Esperamos que los disfrutes!
        </p>
        <img src={oneLink} alt="" style={{ width: '1.5in' }} />
      </div>
    </div>
  );
}

export default Ticket;
