import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Formik } from 'formik';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import PaginatedTable from 'components/TableWithPagination/Table';
import { DatePickerField } from 'components/DateTimeField';

import ReactTooltip from 'react-tooltip';
import SweetAlert from 'react-bootstrap-sweetalert';
import api from 'utils/api';
import { formatPrice } from 'utils/misc';
import { parseDate } from 'utils/misc';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import swal from '@sweetalert/with-react';

function OpenLinks() {
  const {
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  } = process.env;
  const [cookies] = useCookies([
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  ]);
  const [tableForceUpdate, setTableForceUpdate] = useState(0);
  const [modal, setModal] = useState({
    isOpen: false,
    data: null,
  });
  const [editModal, setEditModal] = useState({
    isOpen: false,
    data: null,
  });

  const tableProps = {
    rowId: 'voucherTemplatesId',
    baseURL: '/vouchertemplates',
    baseFilter: {
      where: {
        fk_paymentStoresId: cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID],
      },
      order: [['createdAt', 'desc']],
    },
    pageSize: 10,
    columns: [
      {
        title: 'ID',
        objectProperty: 'voucherTemplatesId',
        sortable: true,
      },
      {
        title: 'Concepto',
        objectProperty: 'concept',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Cantidad',
        objectProperty: 'amount',
        searchable: true,
        searchType: 'number',
        middleware: (value) => formatPrice(value),
      },
      {
        title: 'Usos Disponibles',
        objectProperty: 'remainUses',
        searchable: true,
        searchType: 'number',
      },
      {
        title: 'Usos Máximos',
        objectProperty: 'maxUses',
        searchable: true,
        searchType: 'number',
      },
      {
        title: 'Válido desde',
        objectProperty: 'startline',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (startline) => (startline ? parseDate(startline) : 'N/A'),
      },
      {
        title: 'Válido hasta',
        objectProperty: 'deadline',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (deadline) => (deadline ? parseDate(deadline) : 'N/A'),
      },
      {
        title: 'Acción',
        middleware: (data) => {
          return (
            <>
              <button
                type="button"
                data-tip={data?.active ? 'Desactivar' : 'Activar'}
                className="p-2 mx-1 opt-btn"
                onClick={(event) => {
                  event.stopPropagation();
                  onDisable(data);
                }}
              >
                <i
                  style={{
                    color: data?.active ? '#5bc989' : '#ff9cad',
                  }}
                  className="material-icons"
                >
                  {data?.active ? 'visibility' : 'visibility_off'}
                </i>
              </button>
              <button
                type="button"
                data-tip="Editar"
                className="p-2 mx-1 opt-btn"
                onClick={() =>
                  setEditModal({
                    isOpen: true,
                    data: data,
                  })
                }
              >
                <i className="text-center material-icons">edit</i>
              </button>
              <button
                type="button"
                data-tip="Ver link de pago"
                className="p-2 mx-1 opt-btn"
                onClick={() => getOpenLink(data.voucherTemplatesId)}
              >
                <i className="text-center material-icons">launch</i>
              </button>
              <ReactTooltip />
            </>
          );
        },
      },
    ],
  };

  function getOpenLink(id) {
    api.payment
      .getOpenLink(id)
      .then((res) => {
        setModal({
          isOpen: true,
          data: res.data,
        });
      })
      .catch((error) => {
        toast.error('Error al generar el link de pago');
      });
  }

  function onDisable(voucher) {
    const userId = cookies[REACT_APP_COOKIES_USER_ID];
    const isActive = voucher?.active;
    swal({
      buttons: ['Cancelar', isActive ? 'Desactivar' : 'Activar'],
      dangerMode: true,
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">{`¿Deseas ${
            isActive ? 'des' : ''
          }activar este link de pago?`}</p>
          <p className="mt-3">Esto podría tener efectos no esperados</p>
        </div>
      ),
    })
      .then((response) => {
        if (response) {
          api.payment
            .updateOpenLink(voucher?.voucherTemplatesId, {
              active: !isActive,
              updatedBy: userId,
            })
            .then(() => {
              toast.success(`Link de Pago ${isActive ? 'des' : ''}activado!`);
              setTableForceUpdate(tableForceUpdate + 1);
            })
            .catch((error) => {
              console.error(error);
              toast.warning(
                `[${error.toString()}] No se pudo ${
                  isActive ? 'des' : ''
                }activar el link de pago. Intente de nuevo más tarde`
              );
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const closeModal = () => setModal({ isOpen: false, data: null });
  const closeEditModal = () => setEditModal({ isOpen: false, data: null });

  function onSubmit(values, { setSubmitting }) {
    const userId = cookies[REACT_APP_COOKIES_USER_ID];
    const paymentStoreId = cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID];
    const body = {
      ...(values?.template
        ? { updatedBy: userId }
        : { amount: +values.amount, createdBy: userId }),
      ...(values?.maxUses !== 0 || values?.maxUses !== editModal?.data?.maxUses
        ? { maxUses: values?.maxUses, remainUses: values?.maxUses }
        : {}),
      concept: values?.template
        ? `${editModal?.data?.concept?.split(' - ')?.[0]} - ${values.concept}`
        : values.concept,
      fk_paymentStoresId: paymentStoreId,
      startline: values?.startline !== '' ? values?.startline : null,
      deadline: values?.deadline !== '' ? values?.deadline : null,
    };
    (values?.template
      ? api.payment.updateOpenLink(values?.template, body)
      : api.payment.createOpenLink(body)
    )
      .then((res) => {
        toast.success(
          `Se ha ${
            values?.template ? 'actualizado' : 'creado'
          } el link correctamente`
        );
        closeEditModal();
        setTableForceUpdate(tableForceUpdate + 1);
      })
      .catch((error) => {
        toast.error(
          `No se pudo ${values?.template ? 'actualizar' : 'crear'} el link`
        );
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  // Set title
  useEffect(() => {
    document.title = `Links Multipagos | ${process.env.REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content d-flex flex-column" style={{ flexGrow: 1 }}>
      {editModal.isOpen && (
        <SweetAlert
          showConfirm={false}
          onConfirm={closeEditModal}
          onCancel={closeEditModal}
        >
          <div>
            <h2>
              {!editModal.data?.voucherTemplatesId ? 'Crear' : 'Editar'} link multipago
            </h2>
            <div className="row">
              <div className="col-12 mb-4">
                <Formik
                  onSubmit={onSubmit}
                  enableReinitialize={true}
                  initialValues={{
                    ...(editModal.data?.voucherTemplatesId
                      ? { template: editModal.data?.voucherTemplatesId }
                      : { amount: 0 }),
                    maxUses: editModal?.data?.maxUses ?? 0,
                    concept: editModal?.data?.concept?.split(' - ')?.[1] ?? '',
                    startline: editModal?.data?.startline ?? '',
                    deadline: editModal?.data?.deadline ?? '',
                  }}
                  validate={(values) => {
                    let errors = {};
                    if (!values.amount && !editModal.data?.voucherTemplatesId)
                      errors.amount = 'Este campo es obligatorio';

                    if (
                      isNaN(values.amount) &&
                      !editModal.data?.voucherTemplatesId
                    )
                      errors.amount = 'Este campo debe ser un numero';

                    if (
                      values.amount < 0 &&
                      !editModal.data?.voucherTemplatesId
                    )
                      errors.amount = 'Este campo debe ser un numero positivo';

                    if (!values.concept)
                      errors.concept = 'Este campo es obligatorio';

                    if (values.startline || values.deadline) {
                      if (values.startline && values.deadline) {
                        if (values.startline > values.deadline) {
                          errors.deadline = 'La fecha de fin inválida';
                        }
                      }
                      if (values.startline && !values.deadline) {
                        errors.deadline = 'Debe agregar una fecha de fin';
                      }
                      if (!values.startline && values.deadline) {
                        errors.startline = 'Debe agregar una fecha de inicio';
                      }
                    }

                    if (values.maxUses && values.maxUses < 0)
                      errors.maxUses = 'Este campo debe ser un numero positivo';

                    return errors;
                  }}
                >
                  {({ submitForm, isSubmitting }) => (
                    <div className="p-4 row">
                      {!editModal.data?.voucherTemplatesId && (
                        <>
                          <label
                            htmlFor="amount"
                            className="col-12 col-form-label font-bold"
                          >
                            Monto a cobrar
                          </label>
                          <Field
                            className="col-12 mt-1 p-2"
                            type="number"
                            step="0.01"
                            name="amount"
                          />
                          <ErrorMessage name="amount">
                            {(msg) => (
                              <small style={{ color: 'red' }}>{msg}</small>
                            )}
                          </ErrorMessage>
                        </>
                      )}

                      <label
                        htmlFor="startline"
                        className="col-12 col-form-label mt-2 font-bold"
                      >
                        Válido desde
                      </label>
                      <DatePickerField name="startline" className="w-100" />
                      <ErrorMessage name="startline">
                        {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                      </ErrorMessage>

                      <label
                        htmlFor="deadline"
                        className="col-12 col-form-label mt-2 font-bold"
                      >
                        Válido hasta
                      </label>
                      <DatePickerField name="deadline" className="w-100" />
                      <ErrorMessage name="deadline">
                        {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                      </ErrorMessage>

                      <label
                        htmlFor="maxUses"
                        className="col-12 col-form-label mt-2 font-bold"
                      >
                        Usos máximos
                      </label>
                      <Field
                        className="col-12 mt-1 p-2"
                        type="number"
                        name="maxUses"
                      />
                      <ErrorMessage name="maxUses">
                        {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                      </ErrorMessage>

                      <label
                        htmlFor="concept"
                        className="col-12 col-form-label mt-2 font-bold"
                      >
                        Concepto del pago
                        <small style={{ color: 'red' }}>*</small>
                      </label>
                      <Field className="col-12 mt-1 p-2" name="concept" />
                      <ErrorMessage name="concept">
                        {(msg) => <small style={{ color: 'red' }}>{msg}</small>}
                      </ErrorMessage>
                      <button
                        disabled={isSubmitting}
                        className="col-12 tuyo-btn mt-4 bg-green font-weight-bold rounded"
                        type="submit"
                        onClick={submitForm}
                      >
                        <label
                          className="py-2 cursor-pointer text-white w-100 d-flex justify-content-around align-items-center"
                          htmlFor="createPaymentLink"
                        >
                          {isSubmitting
                            ? `${
                                editModal.data?.voucherTemplatesId
                                  ? 'Actualizando'
                                  : 'Creando'
                              } link...`
                            : `${
                                editModal.data?.voucherTemplatesId
                                  ? 'Editar'
                                  : 'Crear'
                              } link`}
                        </label>
                      </button>
                      <small className="font-italic mt-2">
                        * Campos requeridos
                      </small>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </SweetAlert>
      )}
      {modal.isOpen && (
        <SweetAlert
          showConfirm={false}
          onConfirm={closeModal}
          onCancel={closeModal}
        >
          <div>
            <h2>Link de pago</h2>
            <div className="row">
              <div className="col-12 mb-4">
                <CopyToClipboard
                  text={`${modal.data?.link}`}
                  onCopy={() => toast.success('Link copiado')}
                >
                  <div
                    style={{
                      backgroundColor: '#26DB9480',
                      cursor: 'pointer',
                    }}
                    className="px-2 py-4 mx-2 mt-4 rounded-lg row flex justify-content-center align-items-center"
                  >
                    <p
                      style={{
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                      }}
                      className="col-10"
                    >
                      {`${modal.data?.link}`}
                    </p>
                    <div className=" col-2 ">
                      <i className="material-icons text-left">content_copy</i>
                    </div>
                  </div>
                </CopyToClipboard>
              </div>
              <img
                style={{
                  width: 300,
                  height: 300,
                  objectFit: 'contain',
                }}
                className="col-12"
                src={modal.data?.qr}
                alt="qr"
              />
            </div>
          </div>
        </SweetAlert>
      )}
      <div className="row">
        <div className="col-8 col-lg-9 col-xl-10 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Links Multipagos
          </h3>
        </div>
        <div className="col-4 col-lg-3 col-xl-2 d-flex align-items-center justify-content-end">
          <button
            className="px-3 py-2 d-flex align-items-center font-weight-bold py-2 px-4 text-white rounded bg-green mr-2 float-right"
            type="button"
            onClick={() => setEditModal({ isOpen: true, data: null })}
          >
            <span className="font-size-075x">
              <i className="material-icons">add_task</i>
            </span>
            <span className="d-none ml-2 d-md-block">Crear link</span>
          </button>
        </div>
      </div>
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          <div className="pt-md-2">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
              remountCount={tableForceUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenLinks;
