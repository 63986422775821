import * as moment from 'moment';

import { Link, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import {
  formatAmount,
  formatPrice,
  formatTimestamp,
  genChartGradient,
  getMonthsDates,
  sortByDate,
} from 'utils/misc';

import Chart from 'chart.js';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import Datetime from 'react-datetime';
import ReactTagInput from '@pathofdev/react-tag-input';
import { STATUS } from '../assets/resources/status';
import Stars from 'components/Stars/Stars';
import Tabs from 'components/Tabs/Tabs';
import VariationsTable from 'components/VariationsTable/VariationsTable';
import api from '../utils/api';
import chevronLeftSVG from 'assets/icons/chevron-left.svg';
import circleCheckSVG from 'assets/icons/check-circle-solid-green.svg';
import colors from 'assets/resources/colors';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';

const lineChartOptions = {
  tooltips: {
    titleFontSize: 0,
    displayColors: false,
    callbacks: {
      label: (tooltipItem, data) => {
        return tooltipItem.yLabel;
      },
    },
  },
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          color: 'rgba(0,0,0,0)',
        },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          color: 'rgba(0,0,0,0)',
        },
      },
    ],
  },
};

const labels = [];
for (let i = 1; i <= 31; i++) {
  labels.push(i);
}

let viewsSalesChartCtx;
let viewsSalesChartJS;

const today = new Date();

function ViewProduct() {
  const history = useHistory();

  /* URL PARAMS */
  const { id } = useParams();

  /* REFS */
  const viewsSalesChartRef = useRef(null);
  const abandonmentChartRef = useRef(null);

  const { REACT_APP_COOKIES_STORE_ID, REACT_APP_TITLE } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_STORE_ID]);

  const [reviews, setReviews] = useState([]);
  const [product, setProduct] = useState();
  const [seriesVisibilityOnChart, setSeriesVisibilityOnChart] = useState({
    sales: false,
    views: false,
  });
  const [variants, setVariants] = useState([]);
  const [preparationTime, setPreparationTime] = useState();

  useEffect(() => {
    if (product) {
      document.title = `${product.name} | ${REACT_APP_TITLE}`;
    }
  }, [product]);

  /* Get product info. on component mount */
  useEffect(() => {
    api.products
      .getOne(id)
      .then((res) => {
        const product = res.data[0];

        if (cookies[REACT_APP_COOKIES_STORE_ID] != product.fk_storesId) {
          history.goBack();
        }

        product.variants = product.variants.map((variant) => ({
          ...variant,
          attributesArray: variant.attributes
            .split(',')
            .filter((attribute) => attribute.trim()),
          valuesArray: variant.values
            .split(',')
            .filter((value) => value.trim()),
        }));
        product.variants = sortByDate(
          product.variants.map((variant) => ({
            ...variant,
            features: variant.attributesArray.map((key, index) => ({
              key,
              value: variant.valuesArray[index],
            })),
          })),
          'createdAt',
          'asc'
        );

        setProduct(product);
        setVariants(product.variants);
        return api.products.rating.getByProduct(id);
      })
      .then((res) => {
        setReviews(
          res.data.sort((a, b) =>
            a.productsRatingsId < b.productsRatingsId
              ? 1
              : a.productsRatingsId > b.productsRatingsId
              ? -1
              : 0
          )
        );
      })
      .catch((err) => {
        console.error(err);
        toast.warning(`[SERVER_ERROR] ${err}`);
      });
  }, []);

  useEffect(() => {
    if (product) {
      setPreparationTime(
        parseInt(product.preparationTime || 0) / (24 * 60 * 60 * 1000)
      );
    }
  }, [product]);

  const [starsQty, setStarsQty] = useState([
    { stars: 1, qty: 0 },
    { stars: 2, qty: 0 },
    { stars: 3, qty: 0 },
    { stars: 4, qty: 0 },
    { stars: 5, qty: 0 },
  ]);
  const [starsAvg, setStarsAvg] = useState();

  /* CHARTS */
  const [views, setViews] = useState([]);
  const [sales, setSales] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [totalViews, setTotalViews] = useState(0);
  const [abandonment, setAbandonment] = useState([]);
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth());
  const [monthDates, setMonthDates] = useState();

  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    const tmpStarsQty = [...starsQty];

    setStarsAvg(
      reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length
    );
    reviews.forEach(
      (review) =>
        tmpStarsQty.find((starQty) => starQty.stars === parseInt(review.rating))
          .qty++
    );
    setStarsQty([...tmpStarsQty]);
  }, [reviews]);

  useEffect(
    () => setMonthDates(getMonthsDates(new Date(year, month, 1))),
    [month]
  );

  useEffect(() => {
    if (monthDates) {
      api.ordersDetails
        .getByStore(cookies[REACT_APP_COOKIES_STORE_ID], {
          dateRange: {
            gt: monthDates.currPeriod1stDay,
            lt: monthDates.currPeriodLastDay,
          },
          status: STATUS.FOR_INCOME_AND_SALES,
        })
        .then((res) => {
          const salesDataset = new Array(31).fill(0);
          let totalSales = 0;

          res.data
            .filter((detail) => detail?.variant?.product?.productsId == id)
            .forEach((detail) => {
              const date = new Date(detail.createdAt).getDate();
              salesDataset[date - 1] += detail.qty;
              totalSales += detail.qty;
            });
          setSales(salesDataset);
          setTotalSales(totalSales);
        })
        .catch((err) => {
          console.error(err);
          toast.warning(
            '[SERVER_ERROR] No se pudo obtener detalles de órdenes'
          );
        });

      setAbandonment(new Array(31).fill(0));
    }
  }, [monthDates]);

  /* Get views */
  useEffect(
    (_) => {
      monthDates &&
        api
          .findAll(
            '/productsstat?filter=' +
              JSON.stringify({
                where: {
                  fk_productsId: id,
                },
                attributes: ['createdAt'],
              })
          )
          .then((res) => {
            const viewsDataset = new Array(31).fill(0);
            let totalViews = 0;

            res.data.forEach((entry) => {
              const date = new Date(entry.createdAt).getDate();
              viewsDataset[date - 1]++;
              totalViews++;
            });

            setViews(viewsDataset);
            setTotalViews(totalViews);
          })
          .catch((err) => console.error(err));
    },
    [monthDates]
  );

  /* Update chart */
  useEffect(
    (_) => {
      if (views.length > 0 && viewsSalesChartJS) {
        viewsSalesChartJS.data.datasets[0].data = [...views];
        viewsSalesChartJS.update();
      }
    },
    [views]
  );

  useEffect(() => {
    if (product) {
      if (
        !product.variants ||
        product.variants.length < 1 ||
        !product.hasVariant
      ) {
        if (!reviews || reviews.length < 1) {
          setActiveTab('charts');
        } else {
          setActiveTab('reviews');
        }
      } else {
        setActiveTab('variants');
      }
    }
  }, [product, reviews]);

  useEffect(() => {
    if (viewsSalesChartRef.current) {
      viewsSalesChartCtx = viewsSalesChartRef.current.getContext('2d');

      viewsSalesChartJS = new Chart(viewsSalesChartCtx, {
        type: 'line',
        options: { ...lineChartOptions },
        data: {
          labels,
          datasets: [
            {
              lineTension: 0.1,
              data: views,
              borderColor: colors.green,
              fill: false,
              pointBackgroundColor: colors.green,
            },
            {
              lineTension: 0.1,
              data: sales,
              borderColor: colors.light_blue,
              backgroundColor: genChartGradient(
                viewsSalesChartCtx,
                0,
                0,
                0,
                400,
                '129, 149, 255'
              ),
              pointBackgroundColor: colors.light_blue,
            },
          ],
        },
      });
    }
  }, [sales, activeTab]);

  useEffect(() => {
    if (abandonmentChartRef.current) {
      const abandonmentCtx = abandonmentChartRef.current.getContext('2d');

      const abandonmentChart = new Chart(abandonmentCtx, {
        type: 'line',
        options: { ...lineChartOptions },
        data: {
          labels,
          datasets: [
            {
              lineTension: 0.1,
              label: month,
              data: abandonment,
              borderColor: colors.pink,
              backgroundColor: genChartGradient(
                abandonmentCtx,
                0,
                0,
                0,
                400,
                '253, 198, 255'
              ),
              pointBackgroundColor: colors.pink,
            },
          ],
        },
      });
    }
  }, [abandonment, activeTab]);

  useEffect(() => {
    if (viewsSalesChartJS) {
      viewsSalesChartJS.data = {
        ...viewsSalesChartJS.data,
        datasets: [
          {
            ...viewsSalesChartJS.data.datasets[0],
            hidden: seriesVisibilityOnChart.views,
          },
          {
            ...viewsSalesChartJS.data.datasets[1],
            hidden: seriesVisibilityOnChart.sales,
          },
        ],
      };
      viewsSalesChartJS.update();
    }
  }, [seriesVisibilityOnChart]);

  const toggleSeriesVisibilityOnChart = (series) => {
    setSeriesVisibilityOnChart({
      ...seriesVisibilityOnChart,
      [series]: !seriesVisibilityOnChart[series],
    });
  };

  return (
    <div className="content">
      <div className="row">
        <div className="col-6 col-md-3 col-xl-2">
          <Link to={'/admin/products' + window.location.search}>
            <button
              type="button"
              className="w-100 bg-beige tuyo-btn px-3 py-2 mr-2 rounded-pill border d-inline-flex align-items-center justify-content-center"
            >
              <img src={chevronLeftSVG} alt="" className="mr-2" />
              Regresar
            </button>
          </Link>
        </div>
        <div className="col-6 col-md-3 col-xl-2 order-md-3">
          <Link to={`/admin/products/${id}/edit` + window.location.search}>
            <button
              type="button"
              className="d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold"
            >
              Editar
            </button>
          </Link>
        </div>
        <div className="col-12 col-md-6 col-xl-8 mt-3 mt-md-0">
          <h3 className="text-dark-blue font-size-2x font-weight-bold text-center">
            {product?.name}
          </h3>
        </div>
      </div>
      <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
        <div className="col-12">
          <div className="p-4">
            {product ? (
              <>
                <div className="row justify-content-center">
                  {product &&
                    product.images &&
                    product.images
                      .map((img) => ({
                        ...img,
                        ...(!img.order ? { order: 5 } : {}),
                      }))
                      .sort((a, b) =>
                        a.order > b.order
                          ? 1
                          : a.order < b.order
                          ? -1
                          : a.productsImagesId > b.productsImagesId
                          ? 1
                          : a.productsImagesId < b.productsImagesId
                          ? -1
                          : 0
                      )
                      .map((img, index) => (
                        <div
                          className="col-6 col-md-3 mt-3 mt-md-0"
                          key={index}
                        >
                          <div
                            className="w-100 h-100 product-picture"
                            style={{
                              backgroundImage: "url('" + img.url + "')",
                            }}
                          />
                        </div>
                      ))}
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-md-3 order-md-2">
                    <div className="row">
                      <div className="col-6 col-md-12 mt-3">
                        <p className="text-green font-weight-bold">Categoría</p>
                        <p className="text-dark-blue">
                          {product?.category?.name}
                        </p>
                      </div>
                      <div className="col-6 col-md-12 mt-3">
                        <p className="text-green font-weight-bold">
                          Subcategoría
                        </p>
                        <p className="text-dark-blue">
                          {product?.subcategory?.name}
                        </p>
                      </div>
                      <div className="col-12 mt-3">
                        <p className="text-green font-weight-bold">
                          Transporte
                        </p>
                        <p className="text-dark-blue">{product?.cargoType}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 order-md-3">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <p className="text-green font-weight-bold">SKU</p>
                        <p className="text-dark-blue">{product?.sku}</p>
                      </div>
                      <div className="col-6 col-md-12 mt-3">
                        <p className="text-green font-weight-bold">Precio</p>
                        <p className="text-dark-blue">
                          {formatPrice(product?.regularPrice)}
                        </p>
                      </div>
                      {product.discount && product.discount != 0 ? (
                        <div className="col-6 col-md-12 mt-3">
                          <p className="text-green font-weight-bold">
                            Descuento
                          </p>
                          <p className="text-dark-blue">
                            {product?.discount} %
                          </p>
                        </div>
                      ) : null}
                      <div className="col-12 mt-3">
                        <p className="text-green font-weight-bold">Tags</p>
                        <ReactTagInput
                          readOnly
                          tags={
                            product?.tags
                              ?.split(',')
                              .filter((string) => string?.trim()) || []
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <div className="row">
                      <div className="col-12 mt-3">
                        <p className="text-green font-weight-bold">
                          Descripción
                        </p>
                        <p
                          className="text-dark-blue text-justify"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {product?.description}
                        </p>
                      </div>
                      <div className="col-12 mt-3">
                        <p className="text-green font-weight-bold">Enlace</p>
                        <a
                          href={`https://link.tuyoapp.com/product-${(
                            product?.productsId + 32
                          ).toString(16)}/${product?.name
                            .split(' ')
                            .join('-')}`}
                          className="text-dark-blue text-justify"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {`https://link.tuyoapp.com/product-${(
                            product?.productsId + 32
                          ).toString(16)}/${product?.name
                            .split(' ')
                            .join('-')}`}
                        </a>
                      </div>
                      {!product?.hasVariant && !product?.isVirtual && (
                        <div className="col-12 mt-3">
                          <p className="text-green font-weight-bold">
                            Existencias
                          </p>
                          <p className="text-dark-blue">
                            {product?.variants[0]?.stock[0]?.stock || 0}
                          </p>
                        </div>
                      )}
                      <div className="col-12 col-md-4 mt-3">
                        <p className="text-green font-weight-bold">
                          Tiempo de preparacion
                        </p>
                        <p className="text-dark-blue">{`${preparationTime} dia${
                          preparationTime !== 1 ? 's' : ''
                        }`}</p>
                      </div>
                      <div className="col-12 col-md-4 mt-3">
                        <p className="text-green font-weight-bold">
                          Acepta devoluciones
                        </p>
                        <p className="text-dark-blue">
                          {product?.canRefund ? 'Si' : 'No'}
                        </p>
                      </div>
                      <div className="col-12 col-md-4 mt-3">
                        <p className="text-green font-weight-bold">
                          Cobertura nacional
                        </p>
                        <p className="text-dark-blue">
                          {product?.nationwide ? 'Si' : 'No'}
                        </p>
                      </div>
                      <div className="col-12 col-md-4 mt-3">
                        <p className="text-green font-weight-bold">
                          Solo acepta efectivo
                        </p>
                        <p className="text-dark-blue">
                          {product?.onlyCash ? 'Si' : 'No'}
                        </p>
                      </div>
                      <div className="col-12 col-md-4 mt-3">
                        <p className="text-green font-weight-bold">
                          Solo acepta tarjeta
                        </p>
                        <p className="text-dark-blue">
                          {product?.onlyCard ? 'Si' : 'No'}
                        </p>
                      </div>

                      <div className="col-12 mt-2">
                        {product?.isFragile && (
                          <p className="text-dark-blue d-flex align-items-center mt-1">
                            <img
                              src={circleCheckSVG}
                              alt=""
                              style={{ height: '18px' }}
                              className="mr-2"
                            />
                            Producto frágil
                          </p>
                        )}
                        {product?.freeShipping && (
                          <p className="text-dark-blue d-flex align-items-center mt-1">
                            <img
                              src={circleCheckSVG}
                              alt=""
                              style={{ height: '18px' }}
                              className="mr-2"
                            />
                            Envío gratis
                          </p>
                        )}
                        {product?.isVirtual && (
                          <p className="text-dark-blue d-flex align-items-center mt-1">
                            <img
                              src={circleCheckSVG}
                              alt=""
                              style={{ height: '18px' }}
                              className="mr-2"
                            />
                            Virtual
                          </p>
                        )}
                        {product?.donation && (
                          <div className="border p-2 rounded mt-3">
                            <p className="text-dark-blue d-flex align-items-center">
                              <img
                                src={circleCheckSVG}
                                alt=""
                                style={{ height: '18px' }}
                                className="mr-2"
                              />
                              Donación
                            </p>
                            <p className="text-green font-weight-bold mt-3">
                              Nombre del receptor
                            </p>
                            <p
                              className="text-dark-blue text-justify"
                              style={{ whiteSpace: 'pre-line' }}
                            >
                              {product?.donation?.name}
                            </p>
                            <p className="text-green font-weight-bold mt-3">
                              Correo electrónico del receptor
                            </p>
                            <p
                              className="text-dark-blue text-justify"
                              style={{ whiteSpace: 'pre-line' }}
                            >
                              {product?.donation?.email}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Tabs
                  onChange={(tab) => setActiveTab(tab)}
                  tabs={[
                    {
                      text: 'Variaciones',
                      key: 'variants',
                      hidden:
                        !product?.variants ||
                        product?.variants.length < 1 ||
                        !product.hasVariant,
                    },
                    {
                      text: 'Reseñas',
                      key: 'reviews',
                      hidden: !reviews || reviews.length < 1,
                    },
                    { text: 'Gráficos', key: 'charts' },
                  ]}
                  activeTab={activeTab}
                />
                {activeTab === 'charts' ? (
                  <>
                    <div className="row d-flex align-items-center justify-content-center mt-4">
                      <div className="col-12 col-md-8 col-xl-4">
                        <p className="font-size-125x font-weight-bold text-dark-blue">
                          Visitas / compras
                        </p>
                      </div>
                      <div className="col-12 col-md-4 col-xl-2 order-xl-4 mt-2 mt-md-0">
                        <Datetime
                          inputProps={{ style: { textAlign: 'center' } }}
                          value={moment(`${year}-${month + 1}-1`)}
                          closeOnSelect={true}
                          isValidDate={(date) => date.isBefore(moment())}
                          onChange={(date) => {
                            setMonth(date.month());
                            setYear(date.year());
                          }}
                          timeFormat={false}
                          dateFormat="YYYY-MM"
                        />
                      </div>
                      <div className="col-12 col-md-4 col-lg-3 d-flex justify-content-md-center mt-3 mt-xl-0">
                        <button
                          className="d-flex align-items-center"
                          onClick={() => toggleSeriesVisibilityOnChart('views')}
                        >
                          <div
                            className="rounded"
                            style={{
                              width: '2rem',
                              height: '1.5rem',
                              backgroundColor: colors.green,
                              display: 'inline-block',
                              marginRight: '0.5rem',
                            }}
                          />
                          Visitas
                          <span
                            className="font-weight-bold"
                            style={{ marginLeft: '0.5rem' }}
                          >
                            {formatAmount(totalViews)}
                          </span>
                        </button>
                      </div>
                      <div className="col-12 col-md-4 col-lg-3 d-flex justify-content-md-center mt-2 mt-md-3 mt-xl-0">
                        <button
                          className="d-flex align-items-center"
                          onClick={() => toggleSeriesVisibilityOnChart('sales')}
                        >
                          <div
                            className="rounded"
                            style={{
                              width: '2rem',
                              height: '1.5rem',
                              backgroundColor: colors.light_blue,
                              display: 'inline-block',
                              marginRight: '0.5rem',
                            }}
                          />
                          Compras
                          <span
                            className="font-weight-bold"
                            style={{ marginLeft: '0.5rem' }}
                          >
                            {formatAmount(totalSales)}
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div style={{ overflowX: 'auto' }} className="col-12">
                        <div
                          style={{ minWidth: '800px' }}
                          className="d-flex align-items-center"
                        >
                          <div
                            className="text-light-gray"
                            style={{
                              display: 'inline-block',
                              transform: 'rotate(-90deg)',
                              letterSpacing: '0.75rem',
                              width: '2%',
                            }}
                          >
                            CANTIDAD
                          </div>
                          <div
                            style={{ display: 'inline-block', width: '98%' }}
                          >
                            <canvas
                              className="mt-2"
                              ref={viewsSalesChartRef}
                              style={{
                                width: '100%',
                                height: '400px',
                                display: 'inline-block',
                              }}
                            />
                          </div>
                        </div>
                        <p
                          className="text-light-gray text-center"
                          style={{ letterSpacing: '0.75rem' }}
                        >
                          TIEMPO
                        </p>
                      </div>
                    </div>

                    <div className="row d-flex align-items-center mt-4">
                      <div className="col-12 col-md-5 col-lg-6">
                        <p className="font-size-125x font-weight-bold text-dark-blue">
                          Abandono en carrito
                        </p>
                      </div>
                      <div className="col-12 col-md-3 order-md-3 mt-2 mt-md-0">
                        <Datetime
                          inputProps={{ style: { textAlign: 'center' } }}
                          value={moment(`${year}-${month + 1}-1`)}
                          closeOnSelect={true}
                          initialValue={today}
                          isValidDate={(date) => date.isBefore(moment())}
                          onChange={(date) => {
                            setMonth(date.month());
                            setYear(date.year());
                          }}
                          timeFormat={false}
                          dateFormat="YYYY-MM"
                        />
                      </div>
                      <div className="col-12 col-md-4 col-lg-3 d-flex justify-content-center mt-3 mt-md-0">
                        <button className="d-flex align-items-center">
                          <div
                            className="rounded"
                            style={{
                              width: '2rem',
                              height: '1.5rem',
                              backgroundColor: colors.pink,
                              display: 'inline-block',
                              marginRight: '0.5rem',
                            }}
                          />
                          Total
                          <span
                            className="font-weight-bold"
                            style={{ marginLeft: '0.5rem' }}
                          >
                            {formatAmount(0)}
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div style={{ overflowX: 'auto' }} className="col-12">
                        <div
                          style={{ minWidth: '800px' }}
                          className="d-flex align-items-center"
                        >
                          <div
                            className="text-light-gray"
                            style={{
                              display: 'inline-block',
                              transform: 'rotate(-90deg)',
                              letterSpacing: '0.75rem',
                              width: '2%',
                            }}
                          >
                            CANTIDAD
                          </div>
                          <div
                            style={{ display: 'inline-block', width: '98%' }}
                          >
                            <canvas
                              className="mt-2"
                              ref={abandonmentChartRef}
                              style={{
                                width: '100%',
                                height: '400px',
                                display: 'inline-block',
                              }}
                            />
                          </div>
                        </div>
                        <p
                          className="text-light-gray text-center"
                          style={{ letterSpacing: '0.75rem' }}
                        >
                          TIEMPO
                        </p>
                      </div>
                    </div>
                  </>
                ) : activeTab === 'variants' ? (
                  <div className="row mt-3">
                    <div className="col-12">
                      <VariationsTable
                        editable={false}
                        rows={product.variants}
                        onVariationsChange={(variants) =>
                          setVariants(sortByDate(variants, 'createdAt', 'asc'))
                        }
                      />
                    </div>
                  </div>
                ) : activeTab === 'reviews' ? (
                  <div className="row mt-4">
                    <div className="col-12 col-xl-6 order-xl-2 d-flex align-items-center">
                      <div className="row pl-xl-3">
                        <div className="col-12 d-flex align-items-center">
                          <p className="mr-3 text-dark-blue font-weight-bold font-size-2x">
                            {starsAvg.toFixed(2)}
                          </p>
                          <Stars
                            fill={parseInt(starsAvg)}
                            size="lg"
                            color="green"
                          />
                        </div>
                        <div className="col-12 text-dark-blue font-weight-bold">
                          {reviews.length} reseña{reviews.length > 1 ? 's' : ''}
                          <div
                            className="my-3"
                            style={{
                              borderBottom: '2px solid var(--light-gray)',
                            }}
                          />
                        </div>
                        {starsQty.reverse().map((stars, index) => (
                          <div
                            key={index}
                            className="col-12 mb-1 d-flex align-items-center"
                          >
                            <Stars fill={stars.stars} />
                            <div
                              className="progress ml-2"
                              style={{ flexGrow: '1', height: '0.75rem' }}
                            >
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width:
                                    (stars.qty / reviews.length) * 100 + '%',
                                  backgroundColor: 'var(--green)',
                                }}
                                aria-valuenow={
                                  (stars.qty / reviews.length) * 100
                                }
                                aria-valuemin="0"
                                aria-valuemax="100"
                              />
                            </div>
                            <div style={{ flexBasis: '16%' }} className="ml-3">
                              {stars.qty}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                      <div
                        className="row"
                        style={{ height: '300px', overflow: 'auto' }}
                      >
                        {reviews.map((review) => (
                          <div
                            key={review.productsRatingsId}
                            className="col-12 mb-3 py-2 border rounded"
                          >
                            <div className="d-flex align-items-center">
                              <div
                                className="rounded"
                                style={{
                                  height: '3rem',
                                  width: '3rem',
                                  backgroundImage: `url('${review.user.photo}')`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                }}
                              />
                              <div className="ml-3">
                                <p className="font-weight-bold text-dark-blue font-size-125x">
                                  {review.user.firstname} {review.user.lastname}
                                </p>
                                <p
                                  className="font-weight-bold text-gray"
                                  style={{ opacity: '0.7' }}
                                >
                                  {formatTimestamp(review.createdAt)}
                                </p>
                                <div className="mt-1">
                                  <Stars fill={review.rating} color="yellow" />
                                </div>
                              </div>
                            </div>
                            {review.reviewTitle && (
                              <p className="mt-3 font-weight-bold text-gray">
                                {review.reviewTitle}
                              </p>
                            )}
                            {review.comment && (
                              <p className="text-justify text-gray">
                                {review.comment}
                              </p>
                            )}

                            <div
                              style={{
                                display: 'flex',
                                marginTop: '0.5rem',
                                marginBottom: '0.5rem',
                              }}
                            >
                              {review.ratings_images.map((image) => (
                                <div
                                  style={{
                                    backgroundImage: `url('${image.url}')`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    height: '3rem',
                                    width: '3rem',
                                    marginRight: '0.5rem',
                                    borderRadius: '0.5rem',
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <div
                className="p-5 m-5 d-flex justify-content-center align-items-center"
                style={{ flexGrow: '1' }}
              >
                <ClimbingBoxLoader color={colors.green} size="25" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewProduct;
