import React, { useEffect, useState } from 'react';

function ToggleButtons({ buttons, className, onChange, activeButton }) {
  const [selectedButton, setSelectedButton] = useState(activeButton);

  useEffect(() => {
    selectedButton && onChange(selectedButton);
  }, [selectedButton]);

  return (
    <div
      className={"tuyo-toggle-buttons " + className}>
      {
        buttons.map(button => (
          <button
            key={button.key}
            onClick={() => setSelectedButton(button.key)}
            className={"py-1 " + (button.key == selectedButton ? 'selected' : '')}
            type="button">
            {button.text}
          </button>
        ))
      }
    </div>
  );
};

export default ToggleButtons;
