import React, { useEffect, useState } from 'react';

import api from 'utils/api';
import { toast } from 'react-toastify';
import { formatPrice } from 'utils/misc';
import ReactTooltip from 'react-tooltip';
import { useCookies } from 'react-cookie';
import swal from '@sweetalert/with-react';
import ReactModal from 'components/Modal';
import { useHistory } from 'react-router-dom';
import { updateSearchParam } from 'utils/misc';
import Image from 'components/TableWithPagination/Image';
import PaginatedTable from 'components/TableWithPagination/Table';
import NewProduct from './NewProduct';

function Products() {
  const history = useHistory();

  const { REACT_APP_COOKIES_STORE_ID, REACT_APP_COOKIES_USER_ID } = process.env;
  const [cookies] = useCookies([
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_COOKIES_USER_ID,
  ]);
  const userId = cookies[REACT_APP_COOKIES_USER_ID];

  const [tableForceUpdate, setTableForceUpdate] = useState(0);
  const [keepPageOnUpdate, setKeepPageOnUpdate] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [updateProductId, setUpdateProductId] = useState(null);
  const [selectedProductType, setSelectedProductType] = useState(
    parseInt(new URLSearchParams(window.location.search).get('productType'))
  );

  // Set title
  useEffect(() => {
    document.title = `Productos | ${process.env.REACT_APP_TITLE}`;
  }, []);

  // Get product types
  useEffect(() => {
    api
      .findAll(
        '/productstypes?filter=' +
          JSON.stringify({ attributes: ['productsTypesId', 'name'] })
      )
      .then((response) => {
        setProductTypes(response.data);
      })
      .catch((error) => {
        toast.warning('No se pudo obtener listado de tipos de producto');
      });
  }, []);

  // Set first product type as selectedProductType
  useEffect(() => {
    if (productTypes?.length > 0 && !selectedProductType) {
      setSelectedProductType(productTypes[1].productsTypesId);
    }
  }, [productTypes]);

  // Add productType URL's search parameter
  useEffect(() => {
    if (selectedProductType) {
      updateSearchParam('productType', selectedProductType);
      setTableForceUpdate(tableForceUpdate + 1);
    }
  }, [selectedProductType]);

  function onDelete(id) {
    swal({
      buttons: ['Cancelar', 'Eliminar'],
      dangerMode: true,
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">
            ¿Deseas eliminar este producto?
          </p>
          <p className="mt-3">Esto podría tener efectos no esperados</p>
        </div>
      ),
    })
      .then((response) => {
        if (response) {
          api.products
            .deleteOne(id)
            .then(() => {
              toast.success('¡Producto eliminado!');
              setTableForceUpdate(tableForceUpdate + 1);
            })
            .catch((err) => {
              console.error(err);
              toast.warning(
                '[SERVER_ERROR] No se pudo eliminar el producto. Intente de nuevo más tarde'
              );
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function onDisable(product) {
    const isActive = product?.active === '1';
    swal({
      buttons: ['Cancelar', isActive ? 'Desactivar' : 'Activar'],
      dangerMode: true,
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">{`¿Deseas ${
            isActive ? 'des' : ''
          }activar este producto?`}</p>
          <p className="mt-3">Esto podría tener efectos no esperados</p>
        </div>
      ),
    })
      .then((response) => {
        if (response) {
          api.products
            .update(product?.productsId, {
              active: isActive ? '0' : '1',
              updatedBy: userId,
            })
            .then(() => {
              toast.success(`¡Producto ${isActive ? 'des' : ''}activado!`);
              setTableForceUpdate(tableForceUpdate + 1);
            })
            .catch((error) => {
              console.error(error);
              toast.warning(
                `[SERVER_ERROR] No se pudo ${
                  isActive ? 'des' : ''
                }activar el producto. Intente de nuevo más tarde`
              );
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function closeRequest() {
    setUpdateProductId(null);
  }

  function saveRequest() {
    setUpdateProductId(null);
    setKeepPageOnUpdate((prev) => !prev);
  }

  const tableProps = {
    rowId: 'productsId',
    baseURL: '/products',
    baseFilter: {
      where: {
        fk_storesId: cookies[REACT_APP_COOKIES_STORE_ID],
        fk_productsTypesId: selectedProductType,
      },
      attributes: [
        'productsId',
        'name',
        'sku',
        'regularPrice',
        'active',
        'discount',
      ],
      include: [
        { association: 'category', attributes: ['name'] },
        { association: 'subcategory', attributes: ['name'] },
        { association: 'images', attributes: ['url'], limit: 1 },
        {
          association: 'variants',
          attributes: ['available', 'productsVariantsId'],
          include: [{ association: 'stock', attributes: ['stock'] }],
        },
      ],
      order: [['name'], ['productsId', 'desc']],
    },
    pageSize: 10,
    columns: [
      {
        name: 'image',
        objectProperty: 'images',
        middleware: (value) => (
          <Image
            src={value[0]?.url}
            alt="Imagen del producto"
            className="product-thumbnail"
          />
        ),
      },
      {
        title: 'Nombre',
        objectProperty: 'name',
        searchable: true,

        searchType: 'text',
      },
      {
        title: 'SKU',
        objectProperty: 'sku',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Precio',
        objectProperty: 'regularPrice',
        searchable: true,
        searchType: 'number',
        className: 'text-center',
        middleware: (value) => formatPrice(value),
      },
      {
        title: 'Stock',
        objectProperty: 'variants',
        searchable: false,
        className: 'text-center',
        middleware: (value) => {
          const available = value.filter(
            (variant) => variant.available === true
          );
          const stock = available.reduce((acc, variant) => {
            return acc + variant.stock?.[0]?.stock || 0;
          }, 0);
          return stock;
        },
      },
      {
        title: 'Descuento',
        objectProperty: 'discount',
        searchable: true,
        searchType: 'number-equal',
        className: 'text-center',
        middleware: (value) => `${value}%`,
      },
      {
        title: 'Acciones',
        middleware: (value, _) => (
          <div>
            <a
              href={`/admin/products/${value.productsId}/view`}
              target="_blank"
              data-tip="Ver producto"
              className="text-dark-blue"
            >
              <span className="material-icons">preview</span>
            </a>
            <button
              data-tip="Editar producto"
              onClick={() => setUpdateProductId(value.productsId)}
              className="text-dark-blue"
            >
              <span className="material-icons">edit</span>
            </button>
            <button
              data-tip={
                value?.active === '1' ? 'Desactivar' : 'Activar' + ' producto'
              }
              className="text-dark-blue"
              onClick={() => onDisable(value)}
            >
              <span className="material-icons">
                {value?.active === '1' ? 'visibility_off' : 'visibility'}
              </span>
            </button>
            <button
              data-tip="Eliminar producto"
              className="text-dark-blue"
              onClick={() => onDelete(value.productsId)}
            >
              <span className="material-icons">delete</span>
            </button>
            <ReactTooltip effect="solid" />
          </div>
        ),
      },
    ],
  };

  return (
    <div className="content d-flex flex-column" style={{ flexGrow: 1 }}>
      <ReactModal
        isOpen={updateProductId != null}
        onCloseRequest={closeRequest}
        style={{
          content: {
            borderRadius: '5px',
            width: '95%',
            height: '98%',
            margin: 'auto',
            maxHeight: '98%',
            maxWidth: '98%',
            bottom: '2rem',
          },
        }}
      >
        <NewProduct
          productId={updateProductId}
          isModal={true}
          modalCloseRequest={saveRequest}
        />
      </ReactModal>
      <div className="row">
        <div className="col-12 col-md-3 d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold">
            Productos
          </h3>
        </div>
        <div className="col-12 col-md-5 d-flex align-items-center">
          <label htmlFor="product-type">Tipo de producto</label>
          <select
            value={selectedProductType}
            onChange={(event) => {
              const type = event.target.value;
              setSelectedProductType(null);
              setTimeout(() => setSelectedProductType(type), 50);
            }}
            name="product-type"
            id="product-type"
            className="h-100 flex-grow-1 ml-2"
          >
            {productTypes.map((type) => (
              <option key={type.productsTypesId} value={type.productsTypesId}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-4 d-flex justify-content-end mt-3 mt-md-0">
          <button
            onClick={() => {
              history.push('/admin/products/new' + window.location.search);
            }}
            type="button"
            className="w-100 d-flex align-items-center justify-content-center bg-purple tuyo-btn w-100 h-100 px-4 py-2 rounded text-light font-weight-bold"
          >
            <i className="material-icons-round align-middle">add</i>
            Agregar producto
          </button>
        </div>
      </div>
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          <div className="pt-2">
            {selectedProductType ? (
              <PaginatedTable
                columns={tableProps.columns}
                pageSize={tableProps.pageSize}
                baseURL={tableProps.baseURL}
                baseFilter={tableProps.baseFilter}
                rowId={tableProps.rowId}
                remountCount={tableForceUpdate}
                keepPageOnUpdate={keepPageOnUpdate}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
