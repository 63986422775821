import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import swal from '@sweetalert/with-react';
import { useCookies } from 'react-cookie';

import Modal from 'components/Modal/Modal';

import deleteSVG from 'assets/icons/delete.svg';
import deleteBlackSVG from 'assets/icons/delete-black.svg';
import addSVG from 'assets/icons/add-black.svg';
import undoSVG from 'assets/icons/undo-alt-solid.svg';
import pictureSVG from 'assets/icons/image-regular.svg';
import altAddSVG from 'assets/icons/add-gray.svg';
import eyeSVG from 'assets/icons/eye-regular.svg';
import editSVG from 'assets/icons/edit-regular.svg';
import toggleOnSVG from 'assets/icons/toggle-on-solid.svg';
import toggleOffSVG from 'assets/icons/toggle-off-solid.svg';

import {
  validSKU,
  validPrice,
  validPctg,
  fileToBase64URL,
  formatPrice,
} from 'utils/misc';
import api from 'utils/api';
import { validQty } from 'utils/misc';

const featureTemplate = {
  key: '',
  value: '',
};

const types = ['png', 'jpg', 'jpeg'];

function VariationsTable({
  rows,
  editable,
  onVariationsChange,
  onVariantDelete,
  permutations,
  defaultStock,
  baseSKU,
  pageSize = 10,
}) {
  const featuresRef = useRef(null);
  const pageRef = useRef(null);

  const [features, setFeatures] = useState([]);
  const [permutationsQty, setPermutationsQty] = useState();
  const [featuresTemplate, setFeaturesTemplate] = useState([]);
  const [inPreviewPicture, setInPreviewPicture] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [variations, setVariations] = useState([]);
  const [variationTemplate, setVariationTemplate] = useState();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  /* pagination state */
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [rowsOnView, setRowsOnView] = useState([]);
  const [showing, setShowing] = useState({ lower: 0, higher: 0 });
  const [itemsQty, setItemsQty] = useState(0);

  const { REACT_APP_COOKIES_USER_ID } = process.env;

  /* Cookies */
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.value = currentPage;
    }
  }, [currentPage]);

  useEffect(() => {
    if (variations?.length > 0) {
      setRowsOnView(
        variations.slice(
          (currentPage - 1) * pageSize,
          Math.min(currentPage * pageSize, variations?.length)
        )
      );

      setShowing({
        lower: (currentPage - 1) * pageSize + 1,
        higher: Math.min(currentPage * pageSize, variations?.length),
      });
    }
  }, [variations, currentPage]);

  useEffect(() => {
    if (variations.length > 0) {
      setItemsQty(variations?.length);
      setMaxPage(Math.ceil(variations?.length / pageSize));
    }
  }, [variations]);

  useEffect(() => {
    api.defaultValues
      .getAll()
      .then((res) => {
        setFeatures([
          ...res.data.map((feature) => ({
            key: feature.option,
            values: feature.value.split(',').filter((val) => val.trim()),
          })),
          {
            key: 'Personalizado',
            custom: true,
          },
        ]);
      })
      .catch((err) => {
        console.error(err);
        toast.warning(
          '[SERVER_ERROR] No se pudo obtener los valores por defecto'
        );
      });
  }, []);

  useEffect(() => {
    if (permutations) {
      if (permutations.length > 1) {
        setPermutationsQty(permutations.length);
      }
    }
  }, [permutations]);

  useEffect(() => {
    const tmpFeaturesTemplate = [];

    /* Get max number of features present on a variation and set that many on the variations template to display table headers */
    let featuresQty = permutationsQty ? permutationsQty : 1;

    if (rows && rows.length > 0) {
      featuresQty = Math.max(...rows.map((row) => row?.features.length));
    }

    for (let i = 0; i < featuresQty; i++) {
      tmpFeaturesTemplate.push({ ...featureTemplate });
    }

    setFeaturesTemplate([...tmpFeaturesTemplate]);
  }, []);

  useEffect(() => {
    if (featuresTemplate?.length > 0) {
      setVariationTemplate({
        price: '',
        sku: '',
        discount: '',
        available: true,
        stock: 0,
        features: [...featuresTemplate],
      });
    }
  }, [featuresTemplate]);

  useEffect(() => {
    if (featuresTemplate?.length > 0) {
      setVariationTemplate({ ...variationTemplate, stockValue: defaultStock });
    }
  }, [defaultStock, featureTemplate]);

  useEffect(() => {
    if (featuresTemplate?.length > 0) {
      setVariationTemplate({ ...variationTemplate, sku: baseSKU });
    }
  }, [baseSKU, featureTemplate]);

  useEffect(() => {
    if (variationTemplate && isFirstLoad) {
      /* Set variations array with rows prop */
      let tmpVariations = [...variations];

      if (rows?.length > 0) {
        tmpVariations = [...rows];
        setFeaturesTemplate([...rows[0]?.features]);
      } else if (tmpVariations?.length < 1) {
        tmpVariations.push({ ...variationTemplate });
      }

      setVariations([...tmpVariations]);
      setIsFirstLoad(false);
    }
  }, [variationTemplate]);

  useEffect(() => {
    let tmpPermutations = [];
    if (permutations) {
      if (permutations.length > 1) {
        setVariations([]);
        for (let i = 0; i < permutations.length; i++) {
          const tmp = Object.values(permutations[i]);
          tmpPermutations.push({
            ...variationTemplate,
            features: tmp,
            sku: `${baseSKU}${i + 1}`,
            stockValue: defaultStock,
          });
        }
        setVariations(tmpPermutations);
      }
    }
  }, [permutations]);

  useEffect(() => {
    if (onVariationsChange) {
      onVariationsChange(variations);
    }
  }, [variations]);

  function deleteFeature(index) {
    setVariations(
      variations.map((variation) => ({
        ...variation,
        features: [
          ...variation.features.slice(0, index),
          ...variation.features.slice(index + 1),
        ],
      }))
    );

    setVariationTemplate({
      ...variationTemplate,
      features: variationTemplate.features.slice(1),
    });
  }

  function addFeature() {
    setVariations(
      variations.map((variation) => ({
        ...variation,
        features: [...variation.features, { ...featureTemplate }],
      }))
    );

    setVariationTemplate({
      ...variationTemplate,
      features: [...variationTemplate.features, { ...featureTemplate }],
    });

    if (featuresRef.current) {
      setTimeout(() => {
        featuresRef.current.scroll({
          left: featuresRef.current.scrollWidth,
          top: 0,
          behavior: 'smooth',
        });
      }, 0);
    }
  }

  function setFeatureKey(value, featureIndex) {
    const isCustom = value === 'Personalizado';

    setVariations(
      variations.map((variant) => ({
        ...variant,
        features: [
          ...variant.features.slice(0, featureIndex),
          {
            key: isCustom ? '' : value,
            custom: isCustom,
            value:
              !isCustom &&
              value.trim() &&
              features.find((feature) => feature.key === value).values.length >
                0
                ? features.find((feature) => feature.key === value).values[0]
                : '',
          },
          ...variant.features.slice(featureIndex + 1),
        ],
      }))
    );

    setFeaturesTemplate([
      ...variations[0]?.features?.slice(0, featureIndex),
      {
        key: isCustom ? '' : value,
        custom: isCustom,
        value:
          !isCustom &&
          value.trim() &&
          features.find((feature) => feature.key === value).values.length > 0
            ? features.find((feature) => feature.key === value).values[0]
            : '',
      },
      ...variations[0]?.features?.slice(featureIndex + 1),
    ]);
  }

  function setCustomFeatureKey(value, featureIndex) {
    setVariations(
      variations?.map((variant) => ({
        ...variant,
        features: [
          ...variant?.features?.slice(0, featureIndex),
          {
            ...variant?.features[featureIndex],
            key: value,
          },
          ...variant?.features?.slice(featureIndex + 1),
        ],
      }))
    );

    setFeaturesTemplate([
      ...variations[0]?.features?.slice(0, featureIndex),
      {
        ...variations[0]?.features[featureIndex],
        key: value,
      },
      ...variations[0]?.features?.slice(featureIndex + 1),
    ]);
  }

  function setFeatureValue(event, variationIndex, featureIndex) {
    setVariations([
      ...variations.slice(0, variationIndex),
      {
        ...variations[variationIndex],
        features: [
          ...variations[variationIndex].features.slice(0, featureIndex),
          {
            ...variations[variationIndex].features[featureIndex],
            value: event.target.value,
          },
          ...variations[variationIndex].features.slice(featureIndex + 1),
        ],
      },
      ...variations.slice(variationIndex + 1),
    ]);
  }

  function addVariation() {
    setVariations([...variations, { ...variationTemplate }]);
  }

  function deleteVariation(index) {
    if (variations.length === 1) {
      toast.warning('El producto debe tener al menos una variante');
    } else {
      setVariations([
        ...variations.slice(0, index),
        ...variations.slice(index + 1),
      ]);
      const IDVariantToDelete = variations[index].productsVariantsId;
      IDVariantToDelete && onVariantDelete(IDVariantToDelete);
    }
  }

  function setVariationValue(value, variationIndex, key) {
    setVariations([
      ...variations.slice(0, variationIndex),
      {
        ...variations[variationIndex],
        [key]: value,
      },
      ...variations.slice(variationIndex + 1),
    ]);
  }

  async function addVariationPicture(event, index) {
    const file = event.target.files[0];
    if (!types.includes(file.type.replace('image/', ''))) {
      toast.warning('Error: La imagen tiene un formato no soportado');
    } else {
      let _base64;
      try {
        _base64 = await fileToBase64URL(file);
      } catch (error) {
        console.log(error);
        return;
      }

      const onEditVariant = { ...variations[index] };
      setVariations([
        ...variations.slice(0, index),
        {
          ...onEditVariant,
          picture: {
            oldURL: onEditVariant
              ? onEditVariant.image || onEditVariant.oldURL
              : null,
            base64: _base64,
            type: file.type,
            name: file.name,
          },
        },
        ...variations.slice(index + 1),
      ]);
    }
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function previewVariationPicture(base64) {
    setInPreviewPicture(base64);
    setIsModalOpen(true);
  }

  const disableVariant = (index) => {
    const productVariant = variations[index];
    const isAvailable = productVariant.available;
    swal({
      buttons: ['Cancelar', isAvailable ? 'Deshabilitar' : 'Habilitar'],
      dangerMode: true,
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">
            {isAvailable
              ? '¿Desea deshabilitar la variante?'
              : '¿Desea habilitar la variante?'}
          </p>
        </div>
      ),
    })
      .then((res) => {
        if (res) {
          api.productVariants
            .update(productVariant.productsVariantsId, {
              available: !isAvailable,
              updatedBy: userID,
            })
            .then((res) => {
              if (res) {
                productVariant.available = !isAvailable;
                setVariations(
                  variations.map((variation) => ({
                    ...variation,
                  }))
                );
                toast.success(
                  `Se ${isAvailable ? 'deshabilitó' : 'habilitó'} la variante`
                );
              }
            })
            .then(() => {
              const lastAvailableVariant = variations.filter(
                (variation) => variation.available == true
              );
              //si es igual a 0 entonces solo hay una variante disponible y se va desactivar el producto
              if (lastAvailableVariant.length === 0) {
                api.products
                  .update(productVariant.fk_productsId, {
                    active: 0,
                    updatedBy: userID,
                  })
                  .then((res) => {
                    if (res) {
                      toast.success(`Se deshabilitó el producto`);
                    }
                  })
                  .catch((err) => {
                    toast.warning(
                      '[Error]: No se pudo deshabilitar el producto'
                    );
                    console.error(err);
                  });
              }
            })
            .catch((err) => {
              console.error(err);
              toast.warning(
                '[Error]: No se pudo habilitar/deshabilitar la variante'
              );
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {editable ? (
        <div style={{ overflowX: 'auto' }}>
          <div className="d-flex variations-table">
            <div
              ref={featuresRef}
              className="d-inline-block mt-3"
              style={{
                overflow: 'auto',
                width: '36%',
                scrollBehavior: 'smooth',
                paddingBottom: '0.5rem',
              }}
            >
              <div
                className="d-flex bg-beige py-2"
                style={{
                  borderBottomLeftRadius: '0.5rem',
                  borderTopLeftRadius: '0.5rem',
                  overflow: 'show',
                }}
              >
                {variationTemplate?.features?.map((feature, index) => (
                  <React.Fragment key={index}>
                    <div
                      style={{ width: '58%', flexShrink: '0' }}
                      className="font-weight-bold text-black d-flex justify-content-center align-items-center"
                    >
                      Atributo {index + 1}
                    </div>
                    <div
                      style={{ width: '42%', flexShrink: '0' }}
                      className="font-weight-bold text-black d-flex justify-content-around align-items-center"
                    >
                      Valor {index + 1}
                      {index > 0 && (
                        <button
                          type="button"
                          className="px-1 ml-2 opt-btn"
                          data-tip="Eliminar atributo"
                          onClick={(_) => deleteFeature(index)}
                        >
                          <img
                            src={deleteBlackSVG}
                            alt=""
                            style={{ height: '16px' }}
                          />
                        </button>
                      )}
                      {index === variationTemplate?.features.length - 1 &&
                        index < 2 && (
                          <>
                            <button
                              type="button"
                              className="d-flex align-items-center p-1 opt-btn d-inline-block ml-1"
                              onClick={addFeature}
                              data-tip="Añadir atributo"
                            >
                              <img
                                src={addSVG}
                                alt=""
                                style={{ height: '16px' }}
                              />
                            </button>
                            <ReactTooltip effect="solid" />
                          </>
                        )}
                    </div>
                  </React.Fragment>
                ))}
              </div>
              <div>
                {rowsOnView.map((variation, rowIndex) => (
                  <div
                    key={'variation-' + rowIndex}
                    className="py-2 d-flex no-wrap"
                  >
                    {variation?.features?.map((feature, featureIndex) => (
                      <React.Fragment key={'feature-' + featureIndex}>
                        <div
                          style={{ width: '58%', flexShrink: '0' }}
                          className="px-2 d-flex"
                        >
                          {feature.custom ||
                          (feature.key.trim() &&
                            !features.find(
                              (featureOpt) => featureOpt.key === feature.key
                            )) ? (
                            <>
                              <input
                                style={{ minWidth: '10px' }}
                                type="text"
                                className="w-100 rounded p-1"
                                value={feature.key}
                                onChange={(event) => {
                                  setCustomFeatureKey(
                                    event.target.value,
                                    featureIndex
                                  );
                                }}
                              />
                              <button
                                type="button"
                                className="opt-btn ml-1 px-1"
                                onClick={() => {
                                  setFeatureKey(features[0].key, featureIndex);
                                }}
                              >
                                <img
                                  src={undoSVG}
                                  alt=""
                                  style={{ height: '16px' }}
                                />
                              </button>
                            </>
                          ) : (
                            <select
                              className="w-100"
                              value={feature.key}
                              onChange={(event) => {
                                setFeatureKey(event.target.value, featureIndex);
                              }}
                            >
                              <option value="" />
                              {features
                                .filter((featureOpt) => {
                                  return (
                                    variation.features
                                      .map((iFeature) => iFeature.key)
                                      .indexOf(featureOpt.key) === -1 ||
                                    featureOpt.key === feature.key
                                  );
                                })
                                .map((featureOpt) => (
                                  <option
                                    key={featureOpt.key}
                                    value={featureOpt.key}
                                  >
                                    {featureOpt.key}
                                  </option>
                                ))}
                            </select>
                          )}
                        </div>
                        <div
                          style={{ width: '42%', flexShrink: '0' }}
                          className="px-2"
                        >
                          {feature.custom ||
                          (feature.key &&
                            features.find(
                              (featureOpt) => feature.key === featureOpt.key
                            ) &&
                            !features.find(
                              (featureOpt) => feature.key === featureOpt.key
                            ).values) ||
                          (features.find(
                            (featureOpt) => feature.key === featureOpt.key
                          ) &&
                            features
                              .find(
                                (featureOpt) => feature.key === featureOpt.key
                              )
                              .values.indexOf(feature.value) === -1) ||
                          !features.find(
                            (featureOpt) => featureOpt.key === feature.key
                          ) ? (
                            <input
                              type="text"
                              className="w-100 rounded p-1"
                              value={feature.value}
                              onChange={(event) => {
                                setFeatureValue(
                                  event,
                                  rowIndex + (currentPage - 1) * pageSize,
                                  featureIndex
                                );
                              }}
                            />
                          ) : (
                            <select
                              className="w-100"
                              value={feature.value}
                              onChange={(event) => {
                                setFeatureValue(
                                  event,
                                  rowIndex + (currentPage - 1) * pageSize,
                                  featureIndex
                                );
                              }}
                            >
                              {features.find(
                                (featureOpt) => feature.key === featureOpt.key
                              ) &&
                                features
                                  .find(
                                    (featureOpt) =>
                                      feature.key === featureOpt.key
                                  )
                                  .values.map((value) => (
                                    <option key={value} value={value}>
                                      {value}
                                    </option>
                                  ))}
                            </select>
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            <div
              className="d-inline-block mt-3 variations-table"
              style={{ overflow: 'auto', width: '64%' }}
            >
              <div
                className="d-flex bg-beige py-2 font-weight-bold text-black"
                style={{
                  borderBottomRightRadius: '0.5rem',
                  borderTopRightRadius: '0.5rem',
                }}
              >
                <div
                  style={{ flexBasis: '25%', flexGrow: '1' }}
                  className="d-flex justify-content-center align-items-center"
                >
                  SKU
                </div>
                <div
                  style={{ flexBasis: '20%', flexGrow: '1' }}
                  className="d-flex justify-content-center align-items-center"
                >
                  Precio ($)
                </div>
                <div
                  style={{ flexBasis: '20%', flexGrow: '1' }}
                  className="d-flex justify-content-center align-items-center"
                >
                  Descuento (%)
                </div>
                <div
                  style={{ flexBasis: '20%', flexGrow: '1' }}
                  className="d-flex justify-content-center align-items-center"
                >
                  Inventario
                </div>
                <div
                  style={{ flexBasis: '15%', flexGrow: '1' }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <img src={pictureSVG} alt="" style={{ height: '16px' }} />
                </div>
                <div
                  style={{ flexBasis: '10%' }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <button
                    type="button"
                    className="p-1 opt-btn"
                    data-tip="Añadir variación"
                    onClick={addVariation}
                  >
                    <img src={addSVG} alt="" style={{ height: '16px' }} />
                  </button>
                </div>
                <div
                  style={{ flexBasis: '10%', flexGrow: '1' }}
                  className="d-flex align-items-center justify-content-center"
                />
              </div>
              <div>
                {rowsOnView.map((variation, rowIndex) => (
                  <div
                    key={'variation-' + rowIndex}
                    className="py-2 d-flex w-100"
                  >
                    <div
                      style={{ flexBasis: '25%', flexGrow: '1' }}
                      className="px d-flex align-items-center-2"
                    >
                      <input
                        type="text"
                        className="rounded p-1 w-100"
                        value={variation.sku}
                        onChange={(event) => {
                          setVariationValue(
                            validSKU(event.target.value),
                            rowIndex + (currentPage - 1) * pageSize,
                            'sku'
                          );
                        }}
                      />
                    </div>
                    <div
                      style={{ flexBasis: '20%', flexGrow: '1' }}
                      className="px-2 d-flex align-items-center"
                    >
                      <input
                        type="text"
                        className="rounded p-1 w-100"
                        value={variation.price}
                        onChange={(event) => {
                          setVariationValue(
                            validPrice(event.target.value),
                            rowIndex + (currentPage - 1) * pageSize,
                            'price'
                          );
                        }}
                      />
                    </div>
                    <div
                      style={{ flexBasis: '20%', flexGrow: '1' }}
                      className="px-2 d-flex align-items-center"
                    >
                      <input
                        type="text"
                        className="rounded p-1 w-100"
                        value={variation.discount}
                        onChange={(event) => {
                          setVariationValue(
                            validPctg(event.target.value),
                            rowIndex + (currentPage - 1) * pageSize,
                            'discount'
                          );
                        }}
                      />
                    </div>
                    <div
                      style={{ flexBasis: '20%', flexGrow: '1' }}
                      className="px-2 d-flex align-items-center"
                    >
                      <input
                        type="text"
                        className="rounded p-1 w-100"
                        value={variation.stockValue}
                        onChange={(event) => {
                          setVariationValue(
                            validQty(event.target.value),
                            rowIndex + (currentPage - 1) * pageSize,
                            'stockValue'
                          );
                        }}
                      />
                    </div>
                    <div
                      style={{ flexBasis: '20%', flexGrow: '1' }}
                      className="justify-content-center d-flex justify-content-center align-items-center"
                    >
                      {variation.picture && (
                        <>
                          <button
                            type="button"
                            className="opt-btn"
                            data-tip="Click para ver imagen en tamaño completo"
                            onClick={() =>
                              previewVariationPicture(variation.picture.base64)
                            }
                          >
                            <img
                              src={
                                variation?.picture?.base64 || variation?.image
                              }
                              alt=""
                              className="img-fluid rounded flex-shrink-0"
                              style={{ height: '32px', width: '32px' }}
                            />
                          </button>
                          <ReactTooltip effect="solid" />
                        </>
                      )}
                      <button
                        type="button"
                        className="p-2 opt-btn ml-1"
                        data-tip={`${
                          variation.picture ? 'Cambiar' : 'Subir'
                        } imagen`}
                        style={{ position: 'relative' }}
                      >
                        <img
                          src={variation.picture ? editSVG : altAddSVG}
                          alt=""
                          style={{ height: '16px' }}
                        />
                        <input
                          accept=".jpg,.jpeg,.png"
                          id={'new-pic' + rowIndex}
                          type="file"
                          style={{ display: 'none' }}
                          onChange={(event) =>
                            addVariationPicture(
                              event,
                              rowIndex + (currentPage - 1) * pageSize
                            )
                          }
                        />
                        <label
                          htmlFor={'new-pic' + rowIndex}
                          className="mb-0 d-flex align-items-center"
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            right: '0',
                            bottom: '0',
                          }}
                        />
                      </button>
                    </div>
                    <div
                      style={{ flexBasis: '10%', flexShrink: '1' }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <button
                        type="button"
                        className="p-2 opt-btn"
                        data-tip="Eliminar variación"
                        onClick={() =>
                          deleteVariation(
                            rowIndex + (currentPage - 1) * pageSize
                          )
                        }
                      >
                        <img
                          src={deleteSVG}
                          alt=""
                          style={{ height: '16px' }}
                        />
                      </button>
                    </div>
                    <div
                      style={{ flexBasis: '10%' }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <button
                        type="button"
                        className="p-1 opt-btn"
                        data-tip={
                          variation.available ? 'Deshabilitar' : 'Habilitar'
                        }
                        onClick={() =>
                          disableVariant(
                            rowIndex + (currentPage - 1) * pageSize
                          )
                        }
                      >
                        <img
                          src={variation.available ? toggleOnSVG : toggleOffSVG}
                          alt=""
                          style={{ height: '16px' }}
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ overflowX: 'auto' }}>
          <table className="variations-table">
            <thead>
              <tr className="text-center bg-beige">
                {variationTemplate?.features.map((feature, index) => (
                  <React.Fragment key={index}>
                    <th>Atributo {index + 1}</th>
                    <th>Valor {index + 1}</th>
                  </React.Fragment>
                ))}
                <th className="py-3">SKU</th>
                <th>Precio</th>
                <th>Descuento</th>
                <th>Inventario</th>
                <th>
                  <img src={pictureSVG} alt="" style={{ height: '16px' }} />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {rowsOnView.map((variation, index) => (
                <tr
                  key={index}
                  className="text-center"
                  style={{ borderBottom: '1px solid var(--pink)' }}
                >
                  {variationTemplate?.features.map((feature, index) => (
                    <React.Fragment key={index}>
                      <td>
                        {variation.features[index]
                          ? variation.features[index].key
                          : ''}
                      </td>
                      <td>
                        {variation.features[index]
                          ? variation.features[index].value
                          : ''}
                      </td>
                    </React.Fragment>
                  ))}
                  <td className="py-3">{variation.sku}</td>
                  <td>{formatPrice(variation.price)}</td>
                  <td>{variation.discount || 0} %</td>
                  <td>{variation.stock[0]?.stock || 0}</td>
                  <td>
                    <div className="h-100 d-flex justify-content-center">
                      <button
                        data-tip="Ver imagen"
                        type="button"
                        className="p-2 opt-btn"
                        onClick={() => previewVariationPicture(variation.image)}
                      >
                        <img src={eyeSVG} alt="" style={{ height: '16px' }} />
                      </button>
                      <ReactTooltip effect="solid" />
                    </div>
                  </td>
                  <td>
                    <div
                      style={{ flexBasis: '10%' }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <button
                        type="button"
                        className="p-1 opt-btn"
                        data-tip={
                          variation.available ? 'Deshabilitar' : 'Habilitar'
                        }
                        onClick={() => disableVariant(index)}
                      >
                        <img
                          src={variation.available ? toggleOnSVG : toggleOffSVG}
                          alt=""
                          style={{ height: '16px' }}
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div
        className="row pager mt-4 font-size-075x text-gray"
        style={{ opacity: '0.7' }}
      >
        <div className="col-12 col-md-4 text-center text-md-left">
          Resultados {showing.lower} - {showing.higher} de {itemsQty}
        </div>
        <div className="col-12 col-md-8 d-flex justify-content-end mt-3 mt-md-0">
          <button
            disabled={currentPage === 1}
            className="px-2"
            onClick={() => {
              setCurrentPage(1);
            }}
          >
            Primero
          </button>
          <button
            disabled={currentPage === 1}
            className="px-2"
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
          >
            Anterior
          </button>
          <span className="px-2 text-green">
            Página
            <input
              className="current-page rounded"
              ref={pageRef}
              type="text"
              onChange={(event) => {
                const newPage = event.target.value.trim();

                if (
                  newPage &&
                  parseInt(newPage) > 0 &&
                  parseInt(newPage) <= maxPage
                ) {
                  setCurrentPage(newPage);
                }
              }}
            />
            de {maxPage}
          </span>
          <button
            disabled={currentPage === maxPage}
            className="px-2"
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
          >
            Siguiente
          </button>
          <button
            disabled={currentPage === maxPage}
            className="px-2"
            onClick={() => {
              setCurrentPage(maxPage);
            }}
          >
            Último
          </button>
        </div>
      </div>
      {isModalOpen && (
        <Modal onCloseRequest={closeModal}>
          <div
            className="w-100 d-flex justify-content-center"
            style={{
              backgroundImage: "url('" + inPreviewPicture + "')",
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              height: '72vh',
            }}
          />
        </Modal>
      )}
    </>
  );
}

export default VariationsTable;
