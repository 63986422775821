import moment from 'moment-timezone';

export const parseDateOrTime = (date, type = 'date') => {
  const svdate = moment(date.toISOString()).tz('America/El_Salvador');
  if (type === 'date') {
    return svdate.format('DD-MM-YYYY');
  }
  return svdate.format('HH:mm:ss');
};

export const convertDate = (date = new Date()) => {
  const svdate = moment(date.toISOString()).tz('America/El_Salvador');
  return svdate;
};

export const addOrSubMonths = (date, months = 1) => {
  const svdate = moment(date.toISOString()).tz('America/El_Salvador');
  const end = svdate.endOf('day');
  let last;
  if (months === 1 || months === 0) { 
    last = end;
  } else if (months > 1) {
    last = end.add(months, 'months');
  } else {
    last = end.subtract(months, 'months');
  }
  return last;
};