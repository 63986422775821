import React, { useContext, useState } from 'react';
import jwt_decode from 'jwt-decode';
import UserCard from 'components/UserCard/UserCard.jsx';

import hamburgerMenuSVG from '../../assets/icons/hamburger-menu.svg';
import { useCookies } from 'react-cookie';
import EnvironmentContext from 'context/environment';
import { ENVIROMENTS } from 'utils/misc';
import { useHistory } from 'react-router';

function Header() {
  const [sidebarExists, setSidebarExists] = useState(false);
  const history = useHistory();
  const [cookies] = useCookies([
    process.env.REACT_APP_COOKIES_JWT,
    process.env.REACT_APP_VALID_ENVIROMENTS,
  ]);
  const { environment, updateEnvironment } = useContext(EnvironmentContext);
  const validEnv = cookies[process.env.REACT_APP_VALID_ENVIROMENTS];

  const JWT = cookies[process.env.REACT_APP_COOKIES_JWT];

  let decodedJWT;
  let store;
  let paymentStore;
  let firstname;
  let lastname;
  let photo;

  if (JWT) {
    decodedJWT = jwt_decode(JWT);
    const user = decodedJWT.user;
    store = user?.store || user?.store_employee?.store;
    paymentStore = user?.payment_stores?.[0];
    firstname = user.firstname;
    lastname = user.lastname;
    photo = user.photo;
  }

  const mobileSidebarToggle = (e) => {
    if (sidebarExists === false) setSidebarExists(true);

    e.preventDefault();
    document.documentElement.classList.toggle('nav-open');
    var node = document.createElement('div');
    node.id = 'bodyClick';
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle('nav-open');
    };
    document.body.appendChild(node);
  };

  return JWT ? (
    <div className="tuyo-navbar d-flex align-items-center justify-content-end p-2">
      {validEnv === ENVIROMENTS.BOTH && (
        <button
          className=" px-3 py-2 text-green font-weight-bold rounded mr-2"
          type="button"
          onClick={() => {
            updateEnvironment(
              environment === ENVIROMENTS.PAYMENT
                ? ENVIROMENTS.MARKETPLACE
                : ENVIROMENTS.PAYMENT
            );
            history.push('/');
          }}
        >
          {environment === ENVIROMENTS.PAYMENT ? (
            <span className="d-flex align-items-center font-size-075x py-2 px-4 text-white rounded bg-green ">
              <i className="material-icons">shopping_bag</i>
              <span className="d-none ml-2 d-md-block">Marketplace</span>
            </span>
          ) : environment === ENVIROMENTS.MARKETPLACE ? (
            <span className="d-flex align-items-center font-size-075x py-2 px-4 text-white rounded bg-green ">
              <i className="material-icons">payments</i>
              <span className="d-none ml-2 d-md-block">Pay</span>
            </span>
          ) : (
            ''
          )}
        </button>
      )}

      <UserCard
        store={environment === ENVIROMENTS.PAYMENT ? paymentStore?.name : store?.name}
        name={`${firstname} ${lastname}`}
        picture={photo}
      />
      <div>
        {
          <button
            className="d-xl-none p-2"
            onClick={(event) => mobileSidebarToggle(event)}
          >
            <img src={hamburgerMenuSVG} alt="" style={{ height: '24px' }} />
          </button>
        }
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Header;
