import React from 'react';

import { formatTimestamp, getOrderCode } from 'utils/misc';
import { STATUS } from '../../assets/resources/status';

import Modal from '../Modal/Modal';
import Status from '../Status/Status';

function ReturnApproval({ returnInView, onCloseRequest, updateReturnStatus, type }) {
  return (
    <Modal onCloseRequest={onCloseRequest}>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <Status dataTip={``} value={returnInView.fk_purchaseStatusesId} />

          <p className='font-weight-bold text-dark-blue font-size-125x mt-3'>
            ID ORDEN <span className='text-purple'>TY-{getOrderCode(returnInView.order.ordersId)}</span>
          </p>
          <p className='font-weight-bold text-green font-size-125x mt-3'>Motivo de devolución</p>
          <p className='font-size-125x font-weight-bold'>
            {returnInView.order.orders_histories.find(entry => ['peticion de cambio', 'peticion de reembolso'].includes(entry.status.toLowerCase())).reason}
          </p>

          <p className='font-weight-bold text-green mt-3'>Nombre del producto</p>
          <p>{returnInView.name}</p>

          <p className='font-weight-bold text-green mt-3'>Dirección de envío</p>
          <p>{returnInView.deliveryAddress}</p>

          <p className='font-weight-bold text-green mt-3'>Departamento</p>
          <p>{returnInView.state}</p>

          <p className='font-weight-bold text-green mt-3'>Comprador</p>
          <p>{returnInView.buyer}</p>

          <p className='font-weight-bold text-green mt-3'>Fecha</p>
          <p>{formatTimestamp(returnInView.updatedAt)}</p>
        </div>
        <div className='col-12 col-lg-6 mt-3 mt-lg-0 d-flex flex-column justify-content-center'>
          <div
            style={{
              backgroundImage: `url('${returnInView.image}')`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              minHeight: '200px',
              maxHeight: '360px',
            }}
            className='h-100 rounded'
          />
          {[STATUS.REFUND_PENDING, STATUS.RETURN_PENDING].includes(returnInView.fk_purchaseStatusesId) && (
            <div className='row justify-content-center mt-4'>
              <div className='col-12 col-md-6 col-xl-4'>
                <button
                  onClick={() => updateReturnStatus(type === 'return' ? STATUS.RETURN_APPROVED : STATUS.REFUND_APPROVED)}
                  type='button'
                  className='w-100 font-weight-bold text-white mr-md-3 bg-purple tuyo-btn py-2 rounded'>
                  Aprobar
                </button>
              </div>
              <div className='col-12 col-md-6 col-xl-4 mt-3 mt-md-0'>
                <button
                  onClick={() => updateReturnStatus(type === 'return' ? STATUS.RETURN_DENIED : STATUS.REFUND_DENIED)}
                  type='button'
                  className='w-100 font-weight-bold bg-red text-white tuyo-btn py-2 rounded'>
                  Denegar
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ReturnApproval;
