import 'react-phone-number-input-plus/style.css';

import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input-plus';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { ErrorMessage, Field, Formik } from 'formik';

import PaginatedTable from 'components/TableWithPagination/Table';
import api from 'utils/api';
import { formatPrice, getOrderCode, parseDate, isEmail } from 'utils/misc';
import { addOrSubMonths, convertDate } from 'utils/date';
import Modal from 'components/Modal/Modal';

const recurrence = Array.from({ length: 36 }, (_, index) => index + 1);
const now = convertDate().valueOf();

function RecurringPayments() {
  const {
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  } = process.env;
  const [cookies] = useCookies([
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_JWT,
  ]);

  const [recomuntCount, setRecomuntCount] = useState(0);
  const [recurringPaymentModal, setRecurringPaymentModal] = useState({
    isOpen: false,
    data: {},
  });

  const isCompleted = (data) => {
    const lastDate = convertDate(new Date(data.lastDate)).valueOf();
    return now > lastDate;
  };

  const isValidToResend = (data) => {
    const lastEmail = convertDate(new Date(data.lastEmail)).valueOf();
    if (data.active & !['COMPLETADO', 'ACTIVO'].includes(data.status?.text)) {
      if (data.failed && now > lastEmail) {
        return true;
      }
      if (now > lastEmail) {
        return true;
      }
    }
    return false;
  };

  const handleStatus = (data) => {
    if (data.active) {
      if (data.failed) {
        return {
          color: '#ff9cad',
          text: 'ERROR',
        };
      }
      if (isCompleted(data)) {
        if (
          !data?.vouchers?.length > 0 ||
          data?.vouchers?.length !== data?.recurrence
        ) {
          return {
            color: '#fe6e8a',
            text: 'INCOMPLETO',
          };
        }
        return {
          color: '#5bc989',
          text: 'COMPLETADO',
        };
      }
      if (!data.cardToken) {
        return {
          color: '#f7c574',
          text: 'PENDIENTE',
        };
      }
      return {
        color: '#63d8f1',
        text: 'ACTIVO',
      };
    }
    if (data.cardToken && isCompleted(data)) {
      if (
        !data?.vouchers?.length > 0 ||
        data?.vouchers?.length !== data?.recurrence
      ) {
        return {
          color: '#fe6e8a',
          text: 'INCOMPLETO',
        };
      }
      return {
        color: '#5bc989',
        text: 'COMPLETADO',
      };
    }
    return {
      color: '#9baab9',
      text: 'DESACTIVADO',
    };
  };

  const customSort = (data) => {
    const customStatusOrder = [
      'ERROR',
      'INCOMPLETO',
      'PENDIENTE',
      'ACTIVO',
      'COMPLETADO',
      'DESACTIVADO',
    ];

    data = data.map((item) => {
      return {
        ...item,
        status: handleStatus(item),
      };
    });

    return data.sort(
      (a, b) =>
        customStatusOrder.indexOf(a.status.text) -
        customStatusOrder.indexOf(b.status.text)
    );
  };

  const tableProps = {
    rowId: 'id',
    baseURL: '/invoicing',
    baseFilter: {
      where: {
        storeId: cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID],
      },
      order: [['createdAt', 'desc']],
    },
    pageSize: 25,
    columns: [
      {
        title: 'Estado',
        objectProperty: 'status',
        middleware: (status) => {
          return (
            <div
              style={{
                backgroundColor: status.color,
                fontWeight: 600,
                width: 150,
                margin: 'auto',
              }}
              className="rounded p-1 text-white text-center"
            >
              {status.text}
            </div>
          );
        },
      },
      {
        title: 'Código',
        objectProperty: 'serial',
        searchable: true,
        searchType: 'number-equal',
        sortable: true,
        middleware: (value) => `RTY-${getOrderCode(value)}`,
        searchMiddleware: (searchTerm) =>
          parseInt(searchTerm.replace(/RTY-/, ''), 16) / 64,
      },
      {
        title: 'Día de cobro',
        objectProperty: 'paymentDay',
      },
      {
        title: 'Pagos Cobrados',
        middleware: (data) =>
          `${data?.vouchers?.length || 0} de ${data?.recurrence}`,
      },
      {
        title: 'Monto',
        objectProperty: 'amount',
        searchable: true,
        searchType: 'number',
        middleware: (value) => formatPrice(value),
      },
      {
        title: 'Título',
        objectProperty: 'title',
        searchable: true,
        searchType: 'text',
        sortable: true,
      },
      /* {
        title: 'Descripción',
        objectProperty: 'description',
        searchable: true,
        searchType: 'text',
        sortable: true,
      }, */
      {
        title: 'Nombre',
        objectProperty: 'firstname',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Apellido',
        objectProperty: 'lastname',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Teléfono',
        objectProperty: 'phone',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Email',
        objectProperty: 'email',
        searchable: true,
        searchType: 'text',
      },
      {
        title: 'Fecha Creación',
        objectProperty: 'createdAt',
        searchable: true,
        searchType: 'date',
        sortable: true,
        middleware: (createdAt) => parseDate(createdAt),
      },
      {
        title: 'Acción',
        middleware: (data) => {
          return (
            <div className="d-flex align-items-center justify-content-between">
              {isValidToResend(data) ? (
                <button
                  type="button"
                  className="p-2 mx-1 opt-btn"
                  data-tip="Reenviar email"
                  onClick={(event) => {
                    event.stopPropagation();
                    resendInvoice(data.id);
                  }}
                >
                  <i className="text-center material-icons">forward_to_inbox</i>
                  <ReactTooltip />
                </button>
              ) : (
                <div className="p-2 mx-1">
                  <div style={{ width: 24, height: 24 }}></div>
                </div>
              )}
              <button
                type="button"
                className="p-2 mx-1 opt-btn"
                data-tip="Editar pago recurrente"
                onClick={(event) => {
                  event.stopPropagation();
                  gerRecurringPayment(data.id);
                }}
              >
                <i className="text-center material-icons">edit</i>
                <ReactTooltip />
              </button>
              <button
                type="button"
                data-tip={data?.active ? 'Desactivar' : 'Activar'}
                className="p-2 mx-1 opt-btn"
                onClick={(event) => {
                  event.stopPropagation();
                  handleRecurringPayment(data);
                }}
              >
                <i
                  style={{
                    color: data?.active ? '#5bc989' : '#ff9cad',
                  }}
                  className="material-icons"
                >
                  {data?.active ? 'visibility' : 'visibility_off'}
                </i>
                <ReactTooltip />
              </button>
            </div>
          );
        },
      },
    ],
  };

  function gerRecurringPayment(id) {
    api.recurringPayments
      .getRecurringPayment(id)
      .then((res) => {
        setRecurringPaymentModal({
          isOpen: true,
          data: res.data,
        });
      })
      .catch(() => {
        toast.error('Error al generar el link de pago');
      });
  }

  const resendInvoice = (id) => {
    api.recurringPayments
      .resendInvoice(id)
      .then((res) => {
        setRecomuntCount((prev) => prev + 1);
        toast.success('Email enviado al cliente');
      })
      .catch(() => {
        toast.success('Error al enviar el email');
      });
  };

  const closeRecurringModal = () =>
    setRecurringPaymentModal({ isOpen: false, data: null });

  const validateRecurring = (values) => {
    let errors = {};
    if (!values.amount) errors.amount = 'Este campo es obligatorio';
    if (isNaN(values.amount)) errors.amount = 'Este campo debe ser un número';
    if (values.amount < 0)
      errors.amount = 'Este campo debe ser un número positivo';
    if (!values.description) errors.description = 'Este campo es obligatorio';
    if (!values.title) errors.title = 'Este campo es obligatorio';
    if (!values.recurrence) errors.recurrence = 'Este campo es obligatorio';
    if (isNaN(values.recurrence))
      errors.recurrence = 'Este campo debe ser un número';
    if (values.recurrence < 0)
      errors.recurrence = 'Este campo debe ser un número positivo';
    if (!values.firstname) errors.firstname = 'Este campo es obligatorio';
    if (!values.lastname) errors.lastname = 'Este campo es obligatorio';
    if (!values.email) errors.email = 'Este campo es obligatorio';
    if (values.email && !isEmail(values.email))
      errors.email = 'Correo inválido';
    if (!values.phone) {
      errors.phone = 'Este campo es obligatorio';
    } else {
      if (!isValidPhoneNumber(values.phone)) {
        errors.phone = 'Número de teléfono inválido';
      }
    }

    return errors;
  };

  const onSubmitRecurring = (values, { setSubmitting }) => {
    setSubmitting(true);
    let action;

    if (recurringPaymentModal.data.id) {
      action = api.recurringPayments.updateRecurringPayment(
        recurringPaymentModal.data.id,
        {
          ...(values.firstname !== recurringPaymentModal.data.firstname
            ? { firstname: values.firstname }
            : {}),
          ...(values.lastname !== recurringPaymentModal.data.lastname
            ? { lastname: values.lastname }
            : {}),
          ...(values.email !== recurringPaymentModal.data.email
            ? { email: values.email }
            : {}),
          ...(values.phone !== recurringPaymentModal.data.phone
            ? { phone: values.phone }
            : {}),
          ...(values.title !== recurringPaymentModal.data.title
            ? { title: values.title }
            : {}),
          ...(values.description !== recurringPaymentModal.data.description
            ? { description: values.description }
            : {}),
          ...(values.amount !== recurringPaymentModal.data.amount
            ? { amount: values.amount }
            : {}),
          ...(parseInt(values.recurrence) !==
          recurringPaymentModal.data.recurrence
            ? { recurrence: parseInt(values.recurrence) }
            : {}),
          ...(values.active !== recurringPaymentModal.data.active
            ? { active: values.active }
            : {}),
          updatedBy: cookies[REACT_APP_COOKIES_USER_ID],
        }
      );
    } else {
      action = api.recurringPayments.createRecurringPayment({
        ...values,
        recurrence: parseInt(values.recurrence),
        storeId: cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID],
        createdBy: cookies[REACT_APP_COOKIES_USER_ID],
      });
    }

    action
      .then((res) => {
        setSubmitting(false);
        setRecomuntCount((prev) => prev + 1);
        toast.success(
          recurringPaymentModal.data.id
            ? 'Pago recurrente actualizado'
            : 'Pago recurrente creado'
        );
        closeRecurringModal();
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(
          recurringPaymentModal.data.id
            ? 'Error al actualizar el pago recurrente'
            : 'Error al crear el pago recurrente'
        );
      });
  };

  const handleRecurringPayment = (data) => {
    api.recurringPayments
      .updateRecurringPayment(data.id, {
        active: !data.active,
        updatedBy: cookies[REACT_APP_COOKIES_USER_ID],
      })
      .then((res) => {
        setRecomuntCount((prev) => prev + 1);
        toast.success(
          data.active
            ? 'Pago recurrente desactivado'
            : 'Pago recurrente activado'
        );
      })
      .catch((error) => {
        toast.error(
          data.active
            ? 'Error al desactivar el pago recurrente'
            : 'Error al activar el pago recurrente'
        );
      });
  };

  // Set title
  useEffect(() => {
    document.title = `Pagos Recurrentes | ${process.env.REACT_APP_TITLE}`;
  }, []);

  return (
    <div className="content d-flex flex-column" style={{ flexGrow: 1 }}>
      {recurringPaymentModal.isOpen && (
        <Modal onCloseRequest={closeRecurringModal}>
          <div className="container">
            <h3 className="text-center">Pago recurrente</h3>
            <div className="row">
              <div className="col-12 mb-4">
                <Formik
                  onSubmit={onSubmitRecurring}
                  enableReinitialize={true}
                  initialValues={{
                    firstname: recurringPaymentModal.data.firstname,
                    lastname: recurringPaymentModal.data.lastname,
                    email: recurringPaymentModal.data.email,
                    phone: recurringPaymentModal.data.phone ?? '',
                    title: recurringPaymentModal.data.title,
                    description: recurringPaymentModal.data.description,
                    amount: recurringPaymentModal.data.amount,
                    recurrence: recurringPaymentModal.data.recurrence ?? 1,
                    active: recurringPaymentModal.data.active,
                  }}
                  validate={validateRecurring}
                >
                  {({ submitForm, isSubmitting }) => (
                    <div className="p-4 row">
                      <div className="col-12 col-md-6">
                        <label
                          htmlFor="title"
                          className="col-form-label font-bold d-block w-100"
                        >
                          Título<small className="text-danger">*</small>
                        </label>
                        <Field
                          className="mt-1 p-2 d-block w-100"
                          type="text"
                          name="title"
                        />
                        <ErrorMessage
                          name="title"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-6">
                        <label
                          htmlFor="amount"
                          className="d-block w-100 col-form-label font-bold"
                        >
                          Monto a cobrar<small className="text-danger">*</small>
                        </label>
                        <Field
                          className="d-block w-100 mt-1 p-2"
                          type="number"
                          step="0.01"
                          name="amount"
                        />
                        <ErrorMessage
                          name="amount"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-6">
                        <label
                          htmlFor="firstname"
                          className="d-block w-100 col-form-label font-bold"
                        >
                          Nombre<small className="text-danger">*</small>
                        </label>
                        <Field
                          className="d-block w-100 mt-1 p-2"
                          type="text"
                          name="firstname"
                        />
                        <ErrorMessage
                          name="firstname"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-6">
                        <label
                          htmlFor="lastname"
                          className="d-block w-100 col-form-label font-bold"
                        >
                          Apellido<small className="text-danger">*</small>
                        </label>
                        <Field
                          className="d-block w-100 mt-1 p-2"
                          type="text"
                          name="lastname"
                        />
                        <ErrorMessage
                          name="lastname"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-6 col-lg-4">
                        <label
                          htmlFor="email"
                          className="d-block w-100 col-form-label font-bold"
                        >
                          Correo<small className="text-danger">*</small>
                        </label>
                        <Field
                          className="d-block w-100 mt-1 p-2"
                          type="email"
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-6 col-lg-4">
                        <label
                          htmlFor="phone"
                          className="d-block w-100 col-form-label font-bold"
                        >
                          Teléfono<small className="text-danger">*</small>
                        </label>
                        <Field name="phone">
                          {({ field, form }) => (
                            <PhoneInput
                              international
                              defaultCountry="SV"
                              placeholder="12345678"
                              limitMaxLength
                              className="w-100 mt-1 font-bold"
                              value={field.value}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value ?? '');
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="phone"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12 col-md-6 col-lg-4">
                        <label
                          htmlFor="recurrence"
                          className="d-block w-100 col-form-label font-bold"
                        >
                          Recurrencia (meses)
                          <small className="text-danger">*</small>
                        </label>
                        <Field
                          className="d-block w-100 mt-1 p-2"
                          as="select"
                          name="recurrence"
                          default="1"
                        >
                          {recurrence.map((x) => (
                            <option key={`${x}`} value={x}>
                              {x}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="recurrence"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-12">
                        <label
                          htmlFor="description"
                          className="d-block w-100 col-form-label font-bold"
                        >
                          Descripción<small className="text-danger">*</small>
                        </label>
                        <Field
                          className="d-block w-100 mt-1 p-2"
                          as="textarea"
                          name="description"
                        />
                        <ErrorMessage
                          name="description"
                          component="small"
                          className="text-danger"
                        />
                      </div>

                      <button
                        disabled={isSubmitting}
                        className="col-12 tuyo-btn mt-4 bg-green font-weight-bold rounded-0"
                        type="submit"
                        onClick={submitForm}
                      >
                        <label
                          className="py-2 cursor-pointer text-white w-100 d-flex justify-content-around align-items-center"
                          htmlFor="createPaymentLink"
                        >
                          {recurringPaymentModal.data.id
                            ? 'Actualizar pago recurrente'
                            : 'Crear pago recurrente'}
                        </label>
                      </button>
                      <small className="font-italic mt-2">
                        <span className="text-danger">*</span> Campos requeridos
                      </small>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div className="row">
        <div className="col-12 row d-flex align-items-center">
          <h3 className="text-dark-blue font-size-2x font-weight-bold col-12 col-md-7 col-lg-8">
            Pagos Recurrentes
          </h3>
          <div className="col-12 col-md-5 col-lg-4">
            <button
              onClick={() =>
                setRecurringPaymentModal({ isOpen: true, data: {} })
              }
              type="button"
              className="w-100 font-weight-bold text-white mr-md-3 bg-purple tuyo-btn py-2 px-5 rounded"
            >
              Crear nuevo pago
            </button>
          </div>
        </div>
      </div>
      <div className="row mt-3 mt-md-4">
        <div className="col-12">
          <div className="pt-md-2">
            <PaginatedTable
              columns={tableProps.columns}
              pageSize={tableProps.pageSize}
              baseURL={tableProps.baseURL}
              baseFilter={tableProps.baseFilter}
              rowId={tableProps.rowId}
              remountCount={recomuntCount}
              customSort={customSort}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecurringPayments;
