import React from 'react';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { ENVIROMENTS } from 'utils/misc';
import EnvironmentContext from '.';

export default function EnvironmentProvider({ children }) {
  const {
    REACT_APP_LAST_ENVIROMENT,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_VALID_ENVIROMENTS,
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
  } = process.env;
  const [environment, setEnvironment] = useState();
  const [cookies, setCookie] = useCookies([
    REACT_APP_LAST_ENVIROMENT,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_VALID_ENVIROMENTS,
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
  ]);

  const updateEnvironment = (newEnvironment) => {
    setCookie(REACT_APP_LAST_ENVIROMENT, newEnvironment, {
      path: '/',
    });
    setEnvironment(newEnvironment);
  };

  const getDefaultEnvironment = () => {
    const storeId = cookies[REACT_APP_COOKIES_STORE_ID];
    const paymentStoreId = cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID];

    const validEnv =
      storeId && paymentStoreId
        ? ENVIROMENTS.BOTH
        : storeId
        ? ENVIROMENTS.MARKETPLACE
        : paymentStoreId
        ? ENVIROMENTS.PAYMENT
        : '';

    if (validEnv) init('', validEnv);
  };

  const init = (last = '', valid = '') => {
    const lastEnvironment = last || cookies[REACT_APP_LAST_ENVIROMENT];
    const validEnviroments = valid || cookies[REACT_APP_VALID_ENVIROMENTS];

    if (!validEnviroments) {
      getDefaultEnvironment();
      return;
    }

    setEnvironment(lastEnvironment);

    if (validEnviroments == ENVIROMENTS.BOTH) {
      updateEnvironment(lastEnvironment || ENVIROMENTS.MARKETPLACE);
      return;
    }

    if (validEnviroments == ENVIROMENTS.MARKETPLACE) {
      updateEnvironment(ENVIROMENTS.MARKETPLACE);

      return;
    }

    if (validEnviroments == ENVIROMENTS.PAYMENT) {
      updateEnvironment(ENVIROMENTS.PAYMENT);
      return;
    }
  };

  return (
    <EnvironmentContext.Provider
      value={{ init, environment, updateEnvironment }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
}
