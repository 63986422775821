import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js';
import mapboxgl from 'mapbox-gl';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import Datetime from 'react-datetime';
import * as moment from 'moment';

import Card from 'components/Card/Card';
import Tabs from 'components/Tabs/Tabs';
import PieChartCard from 'components/PieChartCard/PieChartCard';
import ToggleButtons from 'components/ToggleButtons/ToggleButtons';

import api from '../utils/api';
import { formatAmount, genChartGradient, getMonthsDates, getWeeksDates, getDaysDates } from 'utils/misc';

import colors from 'assets/resources/colors';
import { STATUS } from 'assets/resources/status';

const today = new Date();

let chartCtx;
let chartJS;

let map;

function Customers() {
  const { REACT_APP_TITLE, REACT_APP_MAPBOX_ACCESS_TOKEN, REACT_APP_COOKIES_STORE_ID } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_STORE_ID]);
  const storeId = cookies[REACT_APP_COOKIES_STORE_ID];

  mapboxgl.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN;

  const [pieChartCardsPeriod, setPieChartCardsPeriod] = useState('month');
  const [pieChartCardsDates, setPieChartCardsDates] = useState();
  const [currPeriodOrdersDetails, setCurrPeriodOrdersDetails] = useState();
  const [beforePeriodOrdersDetails, setBeforePeriodOrdersDetails] = useState([]);
  const [newUsersCount, setNewUsersCount] = useState(0);
  const [prevUsersCount, setPrevUsersCount] = useState(0);
  const [paymentMethodsCount, setPaymentMethodsCount] = useState();
  const [salesCount, setSalesCount] = useState(0);

  const [month, setMonth] = useState(today.getMonth());
  const [year, setYear] = useState(today.getFullYear());
  const [monthDates, setMonthDates] = useState();
  const [seriesVisibilityOnChart, setSeriesVisibilityOnChart] = useState({
    sales: false,
    views: false,
  });

  useEffect(() => {
    document.title = `Clientes | ${REACT_APP_TITLE}`;
  }, []);

  const customers = {
    labels: ['Nuevos', 'Recurrentes'],
    colors: [colors.light_blue, colors.green],
  };

  const paymentMethods = {
    labels: ['Tarjeta', 'Efectivo'],
    colors: [colors.pink, colors.green],
  };

  const productAbandonment = {
    labels: ['Compra realizada', 'Abandono en carrito'],
    colors: [colors.light_blue, colors.pink],
  };

  const labels = [];
  for (let i = 1; i <= 31; i++) {
    labels.push(i);
  }

  /* Refs for charts */
  const chart = useRef(null);

  const mapContainer = useRef(null);

  const lineChartOptions = {
    tooltips: {
      titleFontSize: 0,
      displayColors: false,
      callbacks: {
        label: (tooltipItem, data) => {
          return tooltipItem.yLabel;
        },
      },
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: 'rgba(0,0,0,0)',
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: 'rgba(0,0,0,0)',
          },
        },
      ],
    },
  };

  const [activeTab, setActiveTab] = useState('views-sales');
  const [views] = useState(new Array(31).fill(0));
  const [totalViews, setTotalViews] = useState(0);
  const [sales] = useState([]);
  const [totalSales, setTotalSales] = useState(0);

  useEffect(() => {
    setMonthDates(getMonthsDates(new Date(year, month, 1)));
  }, [month]);

  useEffect(() => {
    let salesRes;

    monthDates &&
      api.ordersDetails
        .getByStore(storeId, {
          dateRange: {
            gt: monthDates.currPeriod1stDay,
            lt: monthDates.currPeriodLastDay,
          },
          status: STATUS.FOR_INCOME_AND_SALES,
        })
        .then(res => {
          salesRes = res;

          return api.findAll(
            '/products?filter=' +
              JSON.stringify({
                attributes: ['productsId'],
                where: {
                  fk_storesId: storeId,
                },
                include: [
                  {
                    required: true,
                    where: {
                      createdAt: {
                        $gt: monthDates.currPeriod1stDay,
                        $lt: monthDates.currPeriodLastDay,
                      },
                    },
                    association: 'statistics',
                    attributes: ['createdAt'],
                  },
                ],
              })
          );
        })
        .then(res => {
          const viewsRes = res;

          const salesDataset = new Array(31).fill(0);
          const viewsDataset = new Array(31).fill(0);

          salesRes.data.forEach(detail => {
            const date = new Date(detail.createdAt).getDate();
            salesDataset[date - 1] += detail.qty;
          });

          viewsRes.data.forEach(entry => {
            entry.statistics.forEach(click => {
              const date = new Date(click.createdAt).getDate();
              viewsDataset[date - 1]++;
            });
          });

          setTotalViews(viewsDataset.reduce((acc, day) => acc + day, 0));
          setTotalSales(salesDataset.reduce((acc, day) => acc + day, 0));

          chartJS.data.datasets[0].data = [...viewsDataset];
          chartJS.data.datasets[1].data = [...salesDataset];
          chartJS.update();
        })
        .catch(err => {
          console.error(err);
          toast.warning('[SERVER_ERROR] No se pudo obtener los detalles de las órdenes');
        });
  }, [monthDates]);

  /* Update period dates */
  useEffect(() => {
    setPieChartCardsDates(
      pieChartCardsPeriod === 'month' ? getMonthsDates(today) : pieChartCardsPeriod === 'week' ? getWeeksDates(today) : getDaysDates(today)
    );
  }, [pieChartCardsPeriod]);

  const getOrdersDetails = period => {
    api.ordersDetails
      .getByStore(storeId, {
        dateRange: {
          [period === 'current' ? 'gt' : 'lt']: pieChartCardsDates.currPeriod1stDay,
        },
        status: STATUS.FOR_INCOME_AND_SALES,
      })
      .then(res => {
        const action = period === 'current' ? setCurrPeriodOrdersDetails : setBeforePeriodOrdersDetails;
        action(res.data);
      })
      .catch(err => {
        console.error(err);
        toast.warning('[SERVER_ERROR] No se pudo obtener detalles de órdenes');
      });
  };

  /* Get ordersDetails for current period */
  useEffect(() => {
    pieChartCardsDates && getOrdersDetails('current');
    pieChartCardsDates && getOrdersDetails('before');
  }, [pieChartCardsDates]);

  /* Get counts for pieChartCards */
  useEffect(() => {
    if (currPeriodOrdersDetails) {
      setSalesCount(currPeriodOrdersDetails.reduce((acc, detail) => acc + detail.qty, 0));
      setPaymentMethodsCount(
        currPeriodOrdersDetails
          .map(detail => ({ ...detail, paymentType: detail.order.paymentType.split('|')[0] }))
          .reduce((acc, detail) => ({ ...acc, [detail.paymentType]: (acc[detail.paymentType] || 0) + 1 }), {})
      );

      const prevUsers = Array.from(new Set(beforePeriodOrdersDetails.reduce((acc, detail) => [...acc, detail.createdBy], [])));
      setPrevUsersCount(currPeriodOrdersDetails.reduce((acc, detail) => acc + (prevUsers.indexOf(detail.createdBy) !== -1 ? 1 : 0), 0));
      setNewUsersCount(currPeriodOrdersDetails.reduce((acc, detail) => acc + (prevUsers.indexOf(detail.createdBy) !== -1 ? 0 : 1), 0));
    }
  }, [currPeriodOrdersDetails, beforePeriodOrdersDetails]);

  useEffect(() => {
    if (currPeriodOrdersDetails) {
      map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-89.2365921, 13.7034519],
        zoom: 12,
      });

      map.on('load', () => {
        map.addSource('sales', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: currPeriodOrdersDetails.map(detail => ({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: detail.order.location.coordinates,
              },
            })),
          },
        });

        map.addLayer(
          {
            id: 'sales-heat',
            type: 'heatmap',
            source: 'sales',
          },
          'waterway-label'
        );

        map.addLayer(
          {
            id: 'sales-point',
            type: 'circle',
            source: 'sales',
            minzoom: 13,
            paint: {
              'circle-radius': 5,
              'circle-color': 'red',
              'circle-stroke-color': 'white',
              'circle-stroke-width': 1,
              'circle-opacity': 1,
            },
          },
          'waterway-label'
        );
      });
    }
  }, [currPeriodOrdersDetails]);

  /* Render in-view tabs chart when views or activeTab change */
  useEffect(
    _ => {
      if (chart.current) {
        chartCtx = chart.current.getContext('2d');

        chartJS = new Chart(chartCtx, {
          type: 'line',
          options: { ...lineChartOptions },
          data: {
            labels,
            datasets: [
              {
                lineTension: 0.1,
                data: views,
                borderColor: colors.green,
                fill: false,
                pointBackgroundColor: colors.green,
              },
              {
                lineTension: 0.1,
                data: sales,
                borderColor: colors.light_blue,
                backgroundColor: genChartGradient(chartCtx, 0, 0, 0, 400, '129, 149, 255'),
                pointBackgroundColor: colors.light_blue,
              },
            ],
          },
        });
      }
    },
    [views, activeTab]
  );

  useEffect(() => {
    if (chartJS) {
      chartJS.data = {
        ...chartJS.data,
        datasets: [
          {
            ...chartJS.data.datasets[0],
            hidden: seriesVisibilityOnChart.views,
          },
          {
            ...chartJS.data.datasets[1],
            hidden: seriesVisibilityOnChart.sales,
          },
        ],
      };
      chartJS.update();
    }
  }, [seriesVisibilityOnChart]);

  const toggleSeriesVisibilityOnChart = series => {
    setSeriesVisibilityOnChart({
      ...seriesVisibilityOnChart,
      [series]: !seriesVisibilityOnChart[series],
    });
  };

  return (
    <div className='content'>
      <div className='row align-items-center'>
        <div className='col-12 col-md-6 col-lg-8 col-xl-9'>
          <h3 className='text-dark-blue font-size-2x font-weight-bold'>Clientes</h3>
        </div>
        <div className='col-12 col-md-6 col-lg-4 col-xl-3 mt-2 mt-md-0'>
          <ToggleButtons
            activeButton={pieChartCardsPeriod}
            onChange={value => setPieChartCardsPeriod(value)}
            buttons={[
              { key: 'day', text: 'Día' },
              { key: 'week', text: 'Semana' },
              { key: 'month', text: 'Mes' },
            ]}
          />
        </div>
      </div>
      <div className='row mt-3 mt-md-4 justify-content-center'>
        <div className='col-12 col-md-6 mb-3 col-lg-4'>
          <PieChartCard title='Clientes' labels={customers.labels} data={[newUsersCount, prevUsersCount]} colors={customers.colors} />
        </div>
        <div className='col-12 col-md-6 mb-3 col-lg-4'>
          <PieChartCard
            title='Método de pago'
            labels={paymentMethods.labels}
            data={[paymentMethodsCount?.CARD || 0, paymentMethodsCount?.CASH || 0]}
            colors={paymentMethods.colors}
          />
        </div>
        <div className='col-12 col-md-6 mb-3 col-lg-4'>
          <PieChartCard title='Abandono' labels={productAbandonment.labels} data={[salesCount, 0]} colors={productAbandonment.colors} />
        </div>
      </div>
      <div className='row mt-1 mt-md-2'>
        <div className='col-12'>
          <p className='text-dark-blue font-weight-bold font-size-125x'>Ubicación de compra</p>
        </div>
        <div className='col-12 mt-2 mt-md-3'>
          <Card className='p-0'>
            <div id='map' ref={mapContainer} style={{ width: '100%', height: '400px', borderRadius: '1rem' }} />
          </Card>
        </div>
      </div>
      <div className='row mt-3 mt-md-4'>
        <div className='col-12'>
          <Tabs onChange={tab => setActiveTab(tab)} tabs={[{ text: 'Visitas / compras', key: 'views-sales' }]} activeTab={activeTab} />
        </div>
      </div>
      <div className='row mt-3 mt-md-4'>
        <div className='col-12'>
          {activeTab === 'views-sales' && (
            <div className='pt-2'>
              <div className='row d-flex align-items-center justify-content-center'>
                <div className='col-12 col-md-8 col-xl-4'>
                  <p className='font-size-125x font-weight-bold text-dark-blue'>Visitas / compras por mes</p>
                </div>
                <div className='col-12 col-md-4 col-xl-2 order-xl-4 mt-2 mt-md-0'>
                  <Datetime
                    inputProps={{ style: { textAlign: 'center' } }}
                    closeOnSelect={true}
                    initialValue={today}
                    isValidDate={date => date.isBefore(moment())}
                    onChange={date => {
                      setMonth(date.month());
                      setYear(date.year());
                    }}
                    timeFormat={false}
                    dateFormat='YYYY-MM'
                  />
                </div>
                <div className='col-12 col-md-4 col-lg-3 d-flex justify-content-md-center mt-3 mt-xl-0'>
                  <button className='d-flex align-items-center' onClick={() => toggleSeriesVisibilityOnChart('views')}>
                    <div
                      className='rounded'
                      style={{ width: '2rem', height: '1.5rem', backgroundColor: colors.green, display: 'inline-block', marginRight: '0.5rem' }}
                    />
                    Visitas
                    <span className='font-weight-bold' style={{ marginLeft: '0.5rem' }}>
                      {formatAmount(totalViews)}
                    </span>
                  </button>
                </div>
                <div className='col-12 col-md-4 col-lg-3 d-flex justify-content-md-center mt-2 mt-md-3 mt-xl-0'>
                  <button className='d-flex align-items-center' onClick={() => toggleSeriesVisibilityOnChart('sales')}>
                    <div
                      className='rounded'
                      style={{ width: '2rem', height: '1.5rem', backgroundColor: colors.light_blue, display: 'inline-block', marginRight: '0.5rem' }}
                    />
                    Compras
                    <span className='font-weight-bold' style={{ marginLeft: '0.5rem' }}>
                      {formatAmount(totalSales)}
                    </span>
                  </button>
                </div>
              </div>
              <div className='row mt-3'>
                <div style={{ overflowX: 'auto' }} className='col-12'>
                  <div style={{ minWidth: '800px' }} className='d-flex align-items-center'>
                    <div className='text-light-gray' style={{ display: 'inline-block', transform: 'rotate(-90deg)', letterSpacing: '0.75rem', width: '2%' }}>
                      CANTIDAD
                    </div>
                    <div style={{ display: 'inline-block', width: '98%' }}>
                      <canvas className='mt-2' ref={chart} style={{ width: '100%', height: '400px', display: 'inline-block' }} />
                    </div>
                  </div>
                  <p className='text-light-gray text-center' style={{ letterSpacing: '0.75rem' }}>
                    TIEMPO
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Customers;
