import React, { useEffect, useRef, useState } from 'react';

import Card from 'components/Card/Card';

import { validQty, validPrice } from 'utils/misc';

import filtersSVG from 'assets/icons/filters.svg';

function Filters({
  columns,
  onChange
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [filters, setFilters] = useState([]);
  const [resetFilters, setResetFilters] = useState(1);

  const filtersRef = useRef(null);

  /* Set initial structura for filters */
  useEffect(() => {
    if (columns && columns.length > 0) {
      const tmpFilters = [];

      columns.forEach(column => {
        if (column.isQty || column.isCurrency) {
          tmpFilters.push({
            key: column.key,
            text: column.text,
            min: '',
            max: '',
            isQty: column.isQty,
            isCurrency: column.isCurrency
          });
        }
      });

      setFilters([...tmpFilters]);
    }
  }, [columns, resetFilters]);

  useEffect(() => {
    if (filters.length > 0) {
      onChange(filters);
    }
  }, [filters]);

  const updateFilterLimit = (index, limit, val) => {
    const filterToUpdate = filters[index];
    filterToUpdate[limit] = val;

    setFilters([
      ...filters.slice(0, index),
      { ...filterToUpdate },
      ...filters.slice(index + 1)
    ]);
  };

  const checkForFocus = () => {
    setTimeout(() => {
      filtersRef.current && setShowDialog(filtersRef.current.contains(document.activeElement));
    }, 0);
  };

  return (
    <div
      ref={filtersRef}
      tabIndex="0"
      onBlur={checkForFocus}
      className="tuyo-filters h-100">
      <button
        onClick={() => setShowDialog(!showDialog)}
        className="w-100 h-100 py-1 border rounded d-flex align-items-center justify-content-center"
        type="button">
        <img
          className="mr-3"
          style={{ height: '18px' }}
          src={filtersSVG}
          alt="" />
        Filtros
      </button>
      <div className={`dialog ${showDialog ? '' : 'hidden-vertical'}`}>
        <Card className="border w-100">
          {
            filters.map((filter, index) => (
              <div
                className="mb-3"
                key={filter.key}>
                <p>{filter.text}</p>
                <div className="row no-gutters mt-1">
                  <div className="col-5">
                    <input
                      onChange={event => updateFilterLimit(index, 'min', filter.isQty
                        ? validQty(event.target.value)
                        : filter.isCurrency
                          ? validPrice(event.target.value)
                          : event.target.value)}
                      value={filter.min}
                      type="text"
                      className="w-100 rounded p-1" />
                  </div>
                  <div className="col-2 d-flex align-items-center justify-content-center">
                    -
                    </div>
                  <div className="col-5">
                    <input
                      onChange={event => updateFilterLimit(index, 'max', filter.isQty
                        ? validQty(event.target.value)
                        : filter.isCurrency
                          ? validPrice(event.target.value)
                          : event.target.value)}
                      value={filter.max}
                      type="text"
                      className="w-100 rounded p-1" />
                  </div>
                </div>
              </div>
            ))
          }
          <button
            onClick={() => setResetFilters(resetFilters + 1)}
            className="tuyo-btn bg-purple rounded font-weight-bold text-white py-1 w-100"
            type="button">
            Limpiar
            </button>
        </Card>
      </div>
    </div>
  );
};

export default Filters;
