import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';

import api from 'utils/api';

function Logout() {
  const {
    REACT_APP_ENV,
    REACT_APP_COOKIES_JWT,
    REACT_APP_LAST_ENVIROMENT,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_VALID_ENVIROMENTS,
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
  } = process.env;

  const history = useHistory();
  const [cookies, , removeCookie] = useCookies([
    REACT_APP_COOKIES_JWT,
    REACT_APP_LAST_ENVIROMENT,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_VALID_ENVIROMENTS,
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
  ]);

  const wrapper = async () => {
    if (REACT_APP_ENV === 'development') {
      clear();
      return;
    }

    try {
      const userId = await window?.OneSignal?.getUserId();

      if (userId) {
        let devices = (
          await api.users.devices.getAll(cookies[REACT_APP_COOKIES_USER_ID])
        ).data[0].devices;

        const deviceToDelete = devices.find((device) => device.uuid === userId);

        if (deviceToDelete) {
          await api.users.devices.delete(deviceToDelete.usersDevicesId);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      clear();
    }
  };

  const clear = () => {
    removeCookie(REACT_APP_COOKIES_JWT, { path: '/' });
    removeCookie(REACT_APP_COOKIES_USER_ID, { path: '/' });
    removeCookie(REACT_APP_COOKIES_STORE_ID, { path: '/' });
    removeCookie(REACT_APP_COOKIES_PAYMENT_STORE_ID, { path: '/' });
    removeCookie(REACT_APP_LAST_ENVIROMENT, { path: '/' });
    removeCookie(REACT_APP_VALID_ENVIROMENTS, { path: '/' });

    history.push('/login');
  };

  useEffect(() => {
    wrapper();
  }, []);

  return <></>;
}

export default Logout;
