import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import {
  deleteFromS3,
  fileToBase64URL,
  isEmptyObject,
  sortByDate,
  uploadPictureToS3,
  validPctg,
  validPrice,
  validQty,
  validSKU,
} from '../utils/misc';

import BounceLoader from 'react-spinners/BounceLoader';
import Card from '../components/Card/Card';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import ProductAttributes from 'components/ProductAttributes/ProductAttributes';
import { Prompt } from 'react-router';
import ReactTagInput from '@pathofdev/react-tag-input';
import Switch from 'react-switch';
import VariationsTable from 'components/VariationsTable/VariationsTable';
import altDeleteSVG from '../assets/icons/trash-alt-regular.svg';
import api from '../utils/api';
import chevronLeftSVG from '../assets/icons/chevron-left.svg';
import colors from '../assets/resources/colors';
import swal from '@sweetalert/with-react';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';

const transportationMethods = ['Motocicleta', 'Liviano', 'Pesado'];
const types = ['png', 'jpg', 'jpeg'];

function NewProduct({ productId, isModal, modalCloseRequest }) {
  /* URL params */
  const { id: paramId } = useParams();
  const id = paramId || productId;

  useEffect(() => {
    document.title = `${id ? 'Editar' : 'Nuevo'} producto | ${
      process.env.REACT_APP_TITLE
    }`;
  }, []);

  const history = useHistory();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_COOKIES_STORE_ID } = process.env;

  /* Cookies */
  const [cookies] = useCookies([
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_STORE_ID,
  ]);
  const userID = cookies[REACT_APP_COOKIES_USER_ID];

  const [images, setImages] = useState(new Array(4).fill({}));
  const [hasVariants, setHasVariants] = useState(true);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [shouldSetSubcategory, setShouldSetSubcategory] = useState(true);
  const [selectedSubcategory, setSelectedSubcategory] = useState();
  const [variants, setVariants] = useState([]);
  const [product, setProduct] = useState();
  const [variantsToDelete, setVariantsToDelete] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [variantImages, setVariantImages] = useState([]);
  const [tags, setTags] = useState([]);
  const [hasAttributes, setHasAttributes] = useState(false);
  const [productAttributes, setProductAttributes] = useState([]);
  const [permutations, setPermutations] = useState();
  const [firstBranchId, setFirstBranchId] = useState();
  const [isVirtual, setIsVirtual] = useState(false);
  const [isDonation, setIsDonation] = useState(false);
  const [defaultStock, setDefaultStock] = useState(0);
  const [baseSKU, setBaseSKU] = useState('');

  useEffect(() => {
    if (!isVirtual) {
      setIsDonation(false);
    }
  }, [isVirtual]);

  /* Get store branches */
  useEffect(() => {
    api
      .findAll(
        '/storesbranches?filter=' +
          JSON.stringify({
            where: { fk_storesId: cookies[REACT_APP_COOKIES_STORE_ID] },
          })
      )
      .then((response) => {
        setFirstBranchId(response?.data[0]?.storesBranchesId ?? null);
      })
      .catch((error) => {
        console.error(error);
        history.goBack();
      });
  }, []);

  /* Get categories on component did mount */
  useEffect(() => {
    api.categories
      .getAll()
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.error(err);
        toast.warning(
          '[SERVER_ERROR] No se pudo obtener la lista de categorias'
        );
      });
  }, []);

  useEffect(() => {
    if (!id) {
      if (images && images.length > 0) {
        setHasUnsavedChanges(true);
      }
    }
  }, [images]);

  useEffect(() => {
    if (!id && variants && variants.length > 1) {
      setHasUnsavedChanges(true);
    }
  }, [variants]);

  useEffect(() => {
    if (id && variantsToDelete && variantsToDelete.length > 0) {
      setHasUnsavedChanges(true);
    }
  }, [variantsToDelete]);

  useEffect(() => {
    if (
      id &&
      variants &&
      variants.some((variant) => !variant.productsVariantsId)
    ) {
      setHasUnsavedChanges(true);
    }
  }, [variants]);

  /* Get product info. on component did mount */
  useEffect(() => {
    if (id) {
      api.products
        .getOne(id)
        .then((res) => {
          const product = res.data[0];
          if (cookies[REACT_APP_COOKIES_STORE_ID] != product.fk_storesId) {
            history.push('/admin/products' + window.location.search);
          }

          product.variants = product.variants.map((variant) => ({
            ...variant,
            stockValue: variant?.stock[0]?.stock || 0,
            attributesArray: variant.attributes
              .split(',')
              .filter((attribute) => attribute.trim()),
            valuesArray: variant.values
              .split(',')
              .filter((value) => value.trim()),
          }));

          product.variants = product.variants.map((variant) => ({
            ...variant,
            features: variant.attributesArray.map((key, index) => ({
              key,
              value: variant.valuesArray[index],
            })),
            picture: {
              url: variant.image,
              base64: variant.image,
            },
          }));
          setProduct(product);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  /* Set images on product update */
  useEffect(() => {
    if (product) {
      const tmpImages = new Array(4).fill({});

      const orderedImages = [];
      const unorderedImages = [];

      product.images.forEach((img) => {
        if (img.order) {
          orderedImages.push(img);
        } else {
          unorderedImages.push(img);
        }
      });

      orderedImages.forEach((img) => {
        tmpImages[img.order - 1] = img;
      });

      unorderedImages.forEach((img) => {
        tmpImages[tmpImages.findIndex((img) => isEmptyObject(img))] = img;
      });

      setImages([...tmpImages]);
      setHasVariants(product.hasVariant);
      setVariants(sortByDate(product.variants, 'createdAt', 'desc'));
      setVariantImages(
        product.variants.map((variant) => ({
          url: variant.image,
          productsVariantsId: variant.productsVariantsId,
        }))
      );
      setIsVirtual(product?.isVirtual);
      setIsDonation(Boolean(product?.donation));
    }
  }, [product]);

  /* Get subcategories on change on category selected */
  useEffect(() => {
    if (selectedCategory) {
      getSubcategories(selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (subcategories && subcategories.length > 0) {
      if (product) {
        if (shouldSetSubcategory) {
          setSelectedSubcategory(product.fk_subcategoriesId);
          setShouldSetSubcategory(false);
        } else {
          setSelectedSubcategory(subcategories[0].productsSubcategoriesId);
        }
      } else {
        setSelectedSubcategory(subcategories[0].productsSubcategoriesId);
      }
    }
  }, [subcategories, product]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      if (product) {
        setSelectedCategory(product.fk_categoriesId);
      } else if (!id) {
        setSelectedCategory(categories[0].productsCategoriesId);
      }
    }
  }, [categories, product]);

  const getSubcategories = () => {
    api.subcategories
      .getByCategory(selectedCategory)
      .then((res) => {
        setSubcategories(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /* Set products tags */
  useEffect(() => {
    if (product) {
      if (product.tags) {
        setTags(product.tags.split(','));
      }
    }
  }, [product]);

  async function save(formValues) {
    if (!images || images.length < 1) {
      toast.error('El producto debe tener al menos una imagen');
      return;
    }

    let variantsToSend = [];

    const discount = +(formValues.discount ? formValues.discount : 0);

    if (hasVariants) {
      if (
        !variants.every(
          (variation) => variation.sku.trim() && variation.sku.length >= 5
        )
      ) {
        toast.error(
          'Todas las variaciones deben contar con SKU, los cuales deben tener al menos 5 caracteres de longitud'
        );
        return;
      }

      if (
        !variants.every((variation) => variation.price && variation.price > 0)
      ) {
        toast.warning(
          'Las variaciones sin precio serán guardadas con el mismo precio del producto principal'
        );
      }

      if (
        !variants.every((variation) =>
          variation.features.some(
            (feature) => feature.key.trim() && feature.value.trim()
          )
        )
      ) {
        toast.error(
          'Todas las variaciones deben tener al menos 1 atributo y su valor'
        );
        return;
      }

      /* Check for missing pictures on variants with "style" or "color" attribute */
      let missingVariantPicture = false;

      variants.forEach((variant) => {
        const { features, picture } = variant;
        const lowerCasedAttributesArray = features.map((feature) =>
          feature.key.toLowerCase()
        );

        if (
          (lowerCasedAttributesArray.includes('estilo') ||
            lowerCasedAttributesArray.includes('color')) &&
          !picture
        ) {
          missingVariantPicture = true;
        }
      });

      if (missingVariantPicture) {
        toast.error(
          'Las variaciones de estilo o color deben contar con imagen'
        );
        return;
      }

      if (!id) {
        variantsToSend = await generateVariantsStructure(
          variants,
          parseFloat(formValues.price),
          parseFloat(discount)
        );
      }
    }

    if (!firstBranchId) {
      toast.error('La tienda no dispone de punto de recolección.');
      return;
    }

    setIsSaving(true);
    let body = {
      name: formValues.name,
      hasVariant: !formValues.hasNotVariants,
      fk_categoriesId: selectedCategory,
      fk_subcategoriesId: selectedSubcategory,
      sku: formValues.sku,
      regularPrice: parseFloat(formValues.price),
      discount: +formValues.discount
        ? parseFloat(formValues.discount)
        : parseFloat(0),
      cargoType: formValues.transportation,
      freeShipping: formValues.freeShipping,
      isFragile: formValues.fragile,
      description: formValues.description,
      tags: tags.toString(),
      active: formValues.active ? '0' : '1',
      nationwide: formValues.nationwide,
      onlyCash: formValues.onlyCash,
      onlyCard: formValues.onlyCard,
      canRefund: formValues.canRefund,
      preparationTime: formValues.preparationTime
        ? parseInt(formValues.preparationTime) * 24 * 60 * 60 * 1000
        : null,
      isVirtual,
      ...(isDonation
        ? {
            donation: {
              name: formValues.receiverName,
              email: formValues.receiverEmail,
              createdBy: userID,
            },
          }
        : {}),
      fk_productsTypesId: id
        ? product?.fk_productsTypesId
        : parseInt(
            new URLSearchParams(window.location.search).get('productType')
          ),
    };

    if (!id) {
      if (!formValues.hasNotVariants) {
        body.variants = variantsToSend;
      } else {
        const img = images.find((img) => !isEmptyObject(img));

        body.variants = [
          {
            attributes: 'Tipo',
            values: 'Unico',
            sku: formValues.sku,
            price: parseFloat(formValues.price),
            discount: parseFloat(discount),
            createdBy: userID,
            image: await uploadPictureToS3(img.base64, img.type, img.name),
            stock: [
              {
                stock: parseInt(formValues.stock),
                fk_storesBranchesId: firstBranchId,
                createdBy: userID,
              },
            ],
          },
        ];
      }

      body = {
        ...body,
        fk_usersId: userID,
        createdBy: userID,
        fk_storesId: cookies[REACT_APP_COOKIES_STORE_ID],
        images: (
          await Promise.all(
            images.map(async (img, index) =>
              isEmptyObject(img)
                ? null
                : {
                    order: index + 1,
                    url: await uploadPictureToS3(
                      img.base64,
                      img.type,
                      img.name
                    ),
                    createdBy: userID,
                  }
            )
          )
        ).filter((img) => img),
      };
    } else {
      body = {
        ...body,
        updatedBy: +userID,
      };
    }

    const action = id
      ? api.products.update(id, body)
      : api.products.create(body);

    /* Create images when editing a product */
    if (id) {
      images.forEach(async (img, index) => {
        !isEmptyObject(img) &&
          !img.productsImagesId &&
          api.productImages
            .createOne([
              {
                order: index + 1,
                url: await uploadPictureToS3(img.base64, img.type, img.name),
                fk_productsId: id,
                createdBy: userID,
              },
            ])
            .then(() => {})
            .catch((err) => {
              console.error(err);
              toast.warning('[SERVER_ERROR] No se pudo crear una imagen');
            });
      });
    }

    /* Delete images when editing a product */
    for (const image of imagesToDelete) {
      await api.productImages
        .deleteOne(image.productsImagesId)
        .then(async () => {
          await deleteFromS3(image.url);
        })
        .catch((err) => {
          console.error(err);
          toast.warning('[SERVER_ERROR] No se pudo eliminar una imagen');
        });
    }

    let localVariantsToDelete = [...variantsToDelete];

    /* Update variants when editing a product */
    if (id && hasVariants) {
      const variantsToCreate = [];

      for (const variant of variants) {
        if (variant.productsVariantsId) {
          api.productVariants
            .update(
              variant.productsVariantsId,
              (
                await generateVariantsStructure(
                  [variant],
                  formValues.price,
                  discount
                )
              )[0]
            )
            .then(() => {
              return variant?.stock[0]
                ? api.update(
                    '/productsvariantsstocks',
                    variant?.stock[0].productsVariantsStocksId,
                    {
                      stock: variant?.stockValue,
                      updatedBy: +userID,
                    }
                  )
                : api.create('/productsvariantsstocks', [
                    {
                      stock: parseInt(variant.stockValue),
                      fk_productsVariantsId: variant.productsVariantsId,
                      fk_storesBranchesId: firstBranchId,
                      createdBy: userID,
                    },
                  ]);
            })
            .then(async () => {
              if (variant.picture.oldURL) {
                await deleteFromS3(variant.picture.oldURL);
              }
            })
            .catch((err) => {
              console.error(err);
              toast.warning(
                '[SERVER_ERROR] No se pudo actualizar una variante'
              );
            });
        } else {
          variantsToCreate.push(
            (
              await generateVariantsStructure(
                [variant],
                formValues.price,
                discount
              )
            )[0]
          );
        }
      }

      if (variantsToCreate.length > 0) {
        api.create('/productsvariants', variantsToCreate).catch((error) => {
          console.error(error);
          toast.warning('No se pudo crear una variante');
        });
      }
    } else if (id && !hasVariants) {
      const { variants } = product;
      let action;

      const img = images.find((img) => !isEmptyObject(img));

      const body = {
        price: parseFloat(formValues.price),
        discount: parseFloat(discount),
        image: img.url
          ? img.url
          : await uploadPictureToS3(img.base64, img.type, img.name),
        sku: formValues.sku,
      };

      if (variants && variants.length > 0) {
        const existentUniqueTypeVariant = variants.find(
          (variant) =>
            variant.attributes.includes('Tipo') &&
            variant.values.includes('Unico')
        );
        const { productsVariantsId } = existentUniqueTypeVariant;

        localVariantsToDelete = variants
          .filter(
            (variant) => variant.productsVariantsId !== productsVariantsId
          )
          .map((variant) => variant.productsVariantsId);

        action = existentUniqueTypeVariant
          ? api.productVariants.update(productsVariantsId, {
              ...body,
              updatedBy: +userID,
            })
          : api.productVariants.create([
              {
                ...body,
                fk_productsId: id,
                attributes: 'Tipo',
                values: 'Unico',
                createdBy: userID,
              },
            ]);
      } else {
        action = api.productVariants.create([
          {
            ...body,
            fk_productsId: id,
            attributes: 'Tipo',
            values: 'Unico',
            createdBy: userID,
          },
        ]);
      }

      action
        .then(() => {})
        .catch((err) => {
          console.error(err);
          toast.warning(`[SERVER_ERROR] ${err}`);
        });
    }

    if (localVariantsToDelete?.length > 0) {
      const _variantsImagesToDelete = variantImages
        .filter((img) => localVariantsToDelete.includes(img.productsVariantsId))
        ?.map((img) => img.url);

      api
        .deleteWithBody('/productsvariants', localVariantsToDelete)
        .then(async () => {
          for (const url of _variantsImagesToDelete) {
            await deleteFromS3(url);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.warning('No se pudo eliminar una variante');
        });
    }

    action
      .then(() => {
        if (id && !hasVariants) {
          return product?.variants[0]?.stock[0]
            ? api.update(
                '/productsvariantsstocks',
                product?.variants[0]?.stock[0]?.productsVariantsStocksId,
                {
                  stock: parseInt(formValues.stock) || 0,
                  updatedBy: +userID,
                }
              )
            : api.create('/productsvariantsstocks', [
                {
                  stock: parseInt(formValues.stock),
                  fk_productsVariantsId:
                    product?.variants[0]?.productsVariantsId,
                  fk_storesBranchesId: firstBranchId,
                  createdBy: +userID,
                },
              ]);
        }
      })
      .then(() => {
        if (id) {
          if (isDonation) {
            const body = {
              name: formValues.receiverName,
              email: formValues.receiverEmail,
              fk_productsId: id,
              [product?.donation ? 'updatedBy' : 'createdBy']: +userID,
            };

            if (product?.donation) {
              return api.update(
                '/donationsdata',
                product?.donation?.donationsDataId,
                body
              );
            } else {
              return api.create('/donationsdata', body);
            }
          } else if (product?.donation) {
            return api.delete(
              '/donationsdata',
              product?.donation?.donationsDataId
            );
          }
        }
      })
      .then(() => {
        toast.success(`¡Producto ${id ? 'actualizado' : 'creado'}!`);
        setHasUnsavedChanges(false);
        if (isModal !== true)
          history.push(
            '/admin/products' +
              (id ? `/${id}/view` : '') +
              window.location.search
          );

        if (isModal) {
          modalCloseRequest();
        }
      })
      .catch((err) => {
        console.warn(
          `Error en la ${
            id ? 'actualización' : 'creación'
          } del producto. Intenta de nuevo más tarde`
        );
        console.error(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  const generateVariantsStructure = async (
    variations,
    defaultPrice,
    defaultDiscount
  ) => {
    let variants = await Promise.all(
      variations.map(async (variant) => ({
        attributes: variant.features.reduce(
          (acc, feature) => (acc += `${feature.key},`),
          ''
        ),
        values: variant.features.reduce(
          (acc, feature) => (acc += `${feature.value},`),
          ''
        ),
        sku: variant.sku,
        price: parseFloat(variant.price) || defaultPrice,
        discount: variant.discount
          ? parseFloat(variant.discount)
          : defaultDiscount,
        image: variant.picture
          ? variant.picture.url ||
            (variant.picture.base64
              ? await uploadPictureToS3(
                  variant.picture.base64,
                  variant.picture.type,
                  variant.picture.name
                )
              : null)
          : null,
        createdBy: +userID,
        stock: [
          {
            stock: parseInt(variant.stockValue),
            fk_storesBranchesId: firstBranchId,
            createdBy: +userID,
          },
        ],
      }))
    );

    if (id) {
      variants = variants.map((variant) => ({
        ...variant,
        fk_productsId: id,
      }));
    }

    return variants;
  };

  const getPermutations = (obj) => {
    return Object.entries(obj).reduce(
      (r, [k, v]) => {
        let temp = [];
        r.map((s) =>
          (Array.isArray(v) ? v : [v]).forEach((w) =>
            (w && typeof w === 'object' ? getPermutations(w) : [w]).forEach(
              (x) => {
                temp.push(Object.assign({}, s, { [k]: x }));
              }
            )
          )
        );
        return temp;
      },
      [{}]
    );
  };

  const generatePermutation = () => {
    swal({
      buttons: ['Cancelar', 'Continuar'],
      dangerMode: true,
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">
            ¿Deseas generar los atributos?
          </p>
          <p className="mt-3">Se borraran las variaciones anteriores</p>
        </div>
      ),
    })
      .then((res) => {
        if (res) {
          setVariantsToDelete(
            variants.map((variant) => variant.productsVariantsId)
          );
          const attributes = productAttributes.map((productAttribute) => ({
            key: productAttribute.attributes,
            value: productAttribute.values
              .split(',')
              .map((value) => value.trim()),
          }));
          setPermutations(getPermutations(attributes));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const addPicture = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      if (!types.includes(file.type.replace('image/', ''))) {
        toast.error('La imagen tiene un formato no soportado');
      } else {
        const { type, name } = file;
        let _base64;

        try {
          _base64 = await fileToBase64URL(file);
        } catch (err) {
          console.error(err);
          return;
        }

        setImages([
          ...images.slice(0, index),
          {
            base64: _base64,
            type,
            name,
          },
          ...images.slice(index + 1),
        ]);
      }
    }
  };

  const deletePicture = (index) => {
    const imageToDelete = { ...images[index] };

    if (imageToDelete.productsImagesId) {
      setImagesToDelete([...imagesToDelete, imageToDelete]);
    }

    setImages([...images.slice(0, index), {}, ...images.slice(index + 1)]);
  };

  const formRef = useRef(null);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleBlockedRoute = (nextLocation) => {
    swal({
      buttons: ['Cancelar', 'Salir sin guardar'],
      dangerMode: true,
      content: (
        <div className="p-4">
          <p className="font-weight-bold text-dark-blue font-size-2x">
            Tienes modificaciones sin guardar
          </p>
        </div>
      ),
    })
      .then((res) => {
        if (res) {
          setHasUnsavedChanges(false);
          history.push(nextLocation.pathname + window.location.search);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return false;
  };

  return (
    <>
      <Prompt when={hasUnsavedChanges} message={handleBlockedRoute} />
      <div id="new-product" className="content">
        <div className="row">
          <div className="col-6 col-md-3 col-xl-2">
            {isModal !== true && (
              <Link to={'/admin/products' + window.location.search}>
                <button
                  type="button"
                  className="w-100 bg-beige tuyo-btn px-3 py-2 mr-2 rounded-pill border d-inline-flex align-items-center justify-content-center"
                >
                  <img src={chevronLeftSVG} alt="" className="mr-2" />
                  Regresar
                </button>
              </Link>
            )}
          </div>

          <div className="col-6 col-md-3 order-md-3 col-xl-2">
            <button
              disabled={isSaving}
              type="button"
              className="w-100 bg-purple tuyo-btn px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
              onClick={handleSubmit}
            >
              {isSaving ? (id ? 'Actualizando' : 'Creando') : 'Guardar'}
              <BounceLoader color="#fff" loading={isSaving} size="18" />
            </button>
          </div>
          <div className="col-12 col-md-6 col-xl-8 mt-3 mt-md-0">
            <h3 className="text-dark-blue font-size-2x font-weight-bold text-center">
              {id ? product?.name : 'Nuevo producto'}
            </h3>
          </div>
        </div>

        <div className="row mt-3 mt-md-4" style={{ flexGrow: '1' }}>
          <div className="col-12">
            <Card className="p-4">
              {(id && product) || (!id && !product) ? (
                <>
                  <div className="row justify-content-center mb-3">
                    {images.map((img, index) => {
                      return isEmptyObject(img) ? (
                        <div
                          className="col-6 col-md-3 mt-3 mt-md-0"
                          key={index}
                        >
                          <button
                            className="w-100 add-picture-btn"
                            style={{
                              border: '2px solid rgba(0,0,0,0.16)',
                              paddingTop: '100%',
                              borderRadius: '1rem',
                            }}
                          >
                            <input
                              accept=".jpg,.jpeg,.png"
                              id={`new-pic-${index}`}
                              type="file"
                              className="d-none"
                              onChange={(event) => addPicture(event, index)}
                            />
                            <label
                              htmlFor={`new-pic-${index}`}
                              className="mb-0 cursor-pointer"
                              style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </button>
                        </div>
                      ) : (
                        <div
                          className="col-6 col-md-3 mt-3 mt-md-0"
                          key={index}
                        >
                          <div
                            className="w-100 h-100 product-picture"
                            style={{
                              backgroundImage:
                                "url('" +
                                (img.base64 ? img.base64 : img.url) +
                                "')",
                            }}
                          >
                            {((id && images.length > 1) || !id) && (
                              <button
                                className="delete w-100 h-100"
                                onClick={(_) => deletePicture(index)}
                              >
                                <img
                                  src={altDeleteSVG}
                                  alt=""
                                  className="w-50 h-50"
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <small className="font-style-italic text-gray mt-1">
                      Peso máximo por imagen: 1MB
                    </small>
                  </div>
                  <Formik
                    enableReinitialize={true}
                    innerRef={formRef}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().trim().required('Campo requerido'),
                      sku: Yup.string()
                        .trim()
                        .required('Campo requerido')
                        .min(5, 'Longitud mínima: ${min}')
                        .max(20, 'Longitud máxima: ${max}'),
                      price: Yup.number()
                        .required('Campo requerido')
                        .positive('El precio debe ser mayor a 0'),
                      discount: Yup.number()
                        .min(0, 'Porcentajes no pueden ser menor que 0')
                        .max(100, 'Porcentajes no pueden ser mayor a 100'),
                      ...(isDonation
                        ? {
                            receiverName: Yup.string()
                              .trim()
                              .required('Campo requerido'),
                            receiverEmail: Yup.string()
                              .trim()
                              .required('Campo requerido')
                              .email('Correo electrónico no válido'),
                          }
                        : {}),
                    })}
                    initialValues={{
                      name: id ? product?.name : '',
                      hasNotVariants: id ? !product?.hasVariant : false,
                      transportation:
                        id && product?.cargoType
                          ? product.cargoType
                          : transportationMethods[0],
                      sku: id ? product?.sku : '',
                      price: id ? product?.regularPrice : '',
                      discount: id ? product?.discount : '',
                      freeShipping: id ? product?.freeShipping : false,
                      fragile: id ? product?.isFragile : false,
                      description: id ? product?.description : '',
                      active: id ? product?.active != 1 : false,
                      attributes: false,
                      stock: id ? product?.variants[0]?.stock[0]?.stock : '',
                      preparationTime: id
                        ? product?.preparationTime / (24 * 60 * 60 * 1000)
                        : '',
                      receiverName: id ? product?.donation?.name : '',
                      receiverEmail: id ? product?.donation?.email : '',
                      canRefund: id ? product?.canRefund : true,
                      onlyCash: id ? product?.onlyCash : false,
                      onlyCard: id ? product?.onlyCard : false,
                      nationwide: id ? product?.nationwide : true,
                    }}
                    onSubmit={(values) => {
                      save(values);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <label htmlFor="name" className="d-block">
                              Nombre
                            </label>
                            <Field
                              name="name"
                              type="text"
                              placeholder="Producto Tuyo"
                              className="w-100 rounded p-2"
                            />
                            {errors.name && touched.name ? (
                              <p className="text-red font-size-075x pt-1 pl-1">
                                {errors.name}
                              </p>
                            ) : null}
                          </div>
                          <div
                            className={`col-6 col-md-3 d-flex flex-column justify-content-start align-items-center mb-3`}
                          >
                            <Field name="hasNotVariants">
                              {({ field, form: { setFieldValue } }) => (
                                <>
                                  <label className="d-block">
                                    Sin variaciones
                                  </label>
                                  <Switch
                                    onChange={(checked) => {
                                      setFieldValue('hasNotVariants', checked);
                                      setHasVariants(!checked);
                                      if (hasAttributes) {
                                        setFieldValue('attributes', false);
                                        setHasAttributes(false);
                                      }
                                    }}
                                    checked={field.value}
                                  />
                                </>
                              )}
                            </Field>
                          </div>

                          <div
                            className={`col-6 col-md-3 d-flex flex-column justify-content-start align-items-center mb-3`}
                          >
                            <Field name="canRefund">
                              {({ field, form: { setFieldValue } }) => (
                                <>
                                  <label className="d-block">
                                    Acepta reembolso
                                  </label>
                                  <Switch
                                    onChange={(checked) => {
                                      setFieldValue('canRefund', checked);
                                      setHasVariants(!checked);
                                      if (hasAttributes) {
                                        setFieldValue('attributes', false);
                                        setHasAttributes(false);
                                      }
                                    }}
                                    checked={field.value}
                                  />
                                </>
                              )}
                            </Field>
                          </div>

                          <div
                            className={`col-6 col-md-3 d-flex flex-column justify-content-start align-items-center mb-3`}
                          >
                            <Field name="onlyCash">
                              {({ field, form: { setFieldValue } }) => (
                                <>
                                  <label className="d-block">
                                    Solo efectivo
                                  </label>
                                  <Switch
                                    onChange={(checked) => {
                                      setFieldValue('onlyCash', checked);
                                      setHasVariants(!checked);
                                      if (hasAttributes) {
                                        setFieldValue('attributes', false);
                                        setHasAttributes(false);
                                      }
                                    }}
                                    checked={field.value}
                                  />
                                </>
                              )}
                            </Field>
                          </div>

                          <div
                            className={`col-6 col-md-3 d-flex flex-column justify-content-start align-items-center mb-3`}
                          >
                            <Field name="onlyCard">
                              {({ field, form: { setFieldValue } }) => (
                                <>
                                  <label className="d-block">
                                    Solo pago con tarjeta
                                  </label>
                                  <Switch
                                    onChange={(checked) => {
                                      setFieldValue('onlyCard', checked);
                                      setHasVariants(!checked);
                                      if (hasAttributes) {
                                        setFieldValue('attributes', false);
                                        setHasAttributes(false);
                                      }
                                    }}
                                    checked={field.value}
                                  />
                                </>
                              )}
                            </Field>
                          </div>

                          <div
                            className={`col-6 col-md-3 d-flex flex-column justify-content-start align-items-center mb-3`}
                          >
                            <Field name="nationwide">
                              {({ field, form: { setFieldValue } }) => (
                                <>
                                  <label className="d-block">
                                    Cobertura nacional
                                  </label>
                                  <Switch
                                    onChange={(checked) => {
                                      setFieldValue('nationwide', checked);
                                      setHasVariants(!checked);
                                      if (hasAttributes) {
                                        setFieldValue('attributes', false);
                                        setHasAttributes(false);
                                      }
                                    }}
                                    checked={field.value}
                                  />
                                </>
                              )}
                            </Field>
                          </div>

                          <div className="col-6 col-md-3 d-flex flex-column justify-content-center align-items-center mb-3">
                            <label className="d-block">Virtual</label>
                            <Switch
                              onChange={(checked) => {
                                setIsVirtual(checked);
                              }}
                              checked={isVirtual}
                            />
                            <small className="font-style-italic text-gray mt-1">
                              Tarjetas de regalo o donaciones
                            </small>
                          </div>
                          {isVirtual && (
                            <div className="col-12 w-100">
                              <div className="row">
                                <div className="col-12 col-md-2 d-flex flex-column justify-content-center align-items-start mb-3">
                                  <label className="d-block">Donación</label>
                                  <Switch
                                    onChange={(checked) => {
                                      setIsDonation(checked);
                                    }}
                                    checked={isDonation}
                                  />
                                </div>
                                {isDonation && (
                                  <>
                                    <div className="col-12 col-md-5 mb-3">
                                      <label
                                        htmlFor="receiverName"
                                        className="d-block"
                                      >
                                        Nombre del destinatario
                                      </label>
                                      <Field
                                        name="receiverName"
                                        type="text"
                                        placeholder="Tuyo"
                                        className="w-100 rounded p-2"
                                      />
                                      {errors.receiverName &&
                                      touched.receiverName ? (
                                        <p className="text-red font-size-075x pt-1 pl-1">
                                          {errors.receiverName}
                                        </p>
                                      ) : null}
                                    </div>
                                    <div className="col-12 col-md-5 mb-3">
                                      <label
                                        htmlFor="receiverEmail"
                                        className="d-block"
                                      >
                                        Correo electrónico del destinatario
                                      </label>
                                      <Field
                                        name="receiverEmail"
                                        type="text"
                                        placeholder="hola@tuyoapp.com"
                                        className="w-100 rounded p-2"
                                      />
                                      {errors.receiverEmail &&
                                      touched.receiverEmail ? (
                                        <p className="text-red font-size-075x pt-1 pl-1">
                                          {errors.receiverEmail}
                                        </p>
                                      ) : null}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="col-12 col-md-4 mb-3">
                            <label htmlFor="category" className="d-block">
                              Categoría
                            </label>
                            <select
                              className="w-100 rounded p-2"
                              name="category"
                              value={selectedCategory}
                              onChange={(e) =>
                                setSelectedCategory(e.target.value)
                              }
                            >
                              {categories.map((category) => (
                                <option
                                  key={category.productsCategoriesId}
                                  value={category.productsCategoriesId}
                                >
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-12 col-md-4 mb-3">
                            <label htmlFor="subcategory" className="d-block">
                              Subcategoría
                            </label>
                            <select
                              className="w-100 rounded p-2"
                              name="subcategory"
                              value={selectedSubcategory}
                              onChange={(e) =>
                                setSelectedSubcategory(e.target.value)
                              }
                            >
                              {subcategories.map((subcategory) => (
                                <option
                                  key={subcategory.productsSubcategoriesId}
                                  value={subcategory.productsSubcategoriesId}
                                >
                                  {subcategory.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-12 col-md-4 mb-3">
                            <label htmlFor="transportation" className="d-block">
                              Transporte
                            </label>
                            <Field
                              name="transportation"
                              className="w-100 rounded p-2"
                              as="select"
                              children={transportationMethods.map(
                                (transportationMethod) => (
                                  <option
                                    key={transportationMethod}
                                    value={transportationMethod}
                                  >
                                    {transportationMethod}
                                  </option>
                                )
                              )}
                            />
                          </div>
                          <div className="col-12 col-md-3 mb-3">
                            <Field name="sku">
                              {({
                                field,
                                form: { touched, errors, setFieldValue },
                              }) => (
                                <>
                                  <label htmlFor="sku" className="d-block">
                                    SKU
                                  </label>
                                  <input
                                    placeholder="A1B2C3"
                                    type="text"
                                    className="w-100 rounded p-2"
                                    {...field}
                                    onChange={(e) => {
                                      setFieldValue(
                                        'sku',
                                        validSKU(e.target.value)
                                      );
                                      setBaseSKU(validSKU(e.target.value));
                                    }}
                                  />
                                  <small className="font-style-italic text-gray">
                                    Longitud entre 5 y 20 caracteres
                                  </small>
                                  {errors.sku && touched.sku ? (
                                    <p className="text-red font-size-075x pt-1 pl-1">
                                      {errors.sku}
                                    </p>
                                  ) : null}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-12 col-md-3 mb-3">
                            <Field name="price">
                              {({
                                field,
                                form: { touched, errors, setFieldValue },
                              }) => (
                                <>
                                  <label htmlFor="price" className="d-block">
                                    Precio
                                  </label>
                                  <div
                                    className="input-group rounded w-100"
                                    style={{ flexWrap: 'nowrap' }}
                                  >
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        $
                                      </span>
                                    </div>
                                    <input
                                      {...field}
                                      type="text"
                                      inputMode="decimal"
                                      className="p-2"
                                      placeholder="19.99"
                                      style={{
                                        flexGrow: '1',
                                        minWidth: '10px',
                                      }}
                                      onChange={(e) => {
                                        setFieldValue(
                                          'price',
                                          validPrice(e.target.value)
                                        );
                                      }}
                                    />
                                  </div>
                                  {errors.price && touched.price ? (
                                    <p className="text-red font-size-075x pt-1 pl-1">
                                      {errors.price}
                                    </p>
                                  ) : null}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-12 col-md-2 mb-3">
                            <Field name="discount">
                              {({
                                field,
                                form: { touched, errors, setFieldValue },
                              }) => (
                                <>
                                  <label htmlFor="price" className="d-block">
                                    Descuento
                                  </label>
                                  <div
                                    className="input-group rounded w-100"
                                    style={{ flexWrap: 'nowrap' }}
                                  >
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        %
                                      </span>
                                    </div>
                                    <input
                                      {...field}
                                      type="text"
                                      inputMode="decimal"
                                      className="p-2"
                                      placeholder="3"
                                      style={{
                                        flexGrow: '1',
                                        minWidth: '10px',
                                      }}
                                      onChange={(e) => {
                                        setFieldValue(
                                          'discount',
                                          validPctg(e.target.value)
                                        );
                                      }}
                                    />
                                  </div>
                                  {errors.discount && touched.discount ? (
                                    <p className="text-red font-size-075x pt-1 pl-1">
                                      {errors.discount}
                                    </p>
                                  ) : null}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-6 col-md-2 d-flex flex-column justify-content-start mb-3">
                            <Field name="fragile">
                              {({ field, form: { setFieldValue } }) => (
                                <>
                                  <label className="d-block">Frágil</label>
                                  <Switch
                                    onChange={(checked) => {
                                      setFieldValue('fragile', checked);
                                    }}
                                    checked={field.value}
                                  />
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-12 mb-3">
                            <label className="d-block" htmlFor="description">
                              Descripción
                            </label>
                            <Field
                              placeholder="Descripción de tu producto"
                              name="description"
                              as="textarea"
                              className="rounded w-100 p-2"
                              rows="5"
                            />
                          </div>
                          <div className="col-12 col-md-4 mb-3">
                            <label className="d-block">Tags</label>
                            <ReactTagInput
                              name="tags"
                              placeholder="Presione Enter para agregar un Tag (max 10)"
                              tags={tags}
                              onChange={(newTags) => setTags(newTags)}
                              removeOnBackspace={true}
                              maxTags={10}
                            />
                            <small className="font-style-italic text-gray">
                              Maximo 10 tags por producto
                            </small>
                          </div>
                          <div className="col-12 col-md-2 d-flex flex-column justify-content-center align-items-center mb-3">
                            <Field name="active">
                              {({ field, form: { setFieldValue } }) => (
                                <>
                                  <label className="d-block">
                                    Desactivar Producto
                                  </label>
                                  <Switch
                                    onChange={(checked) => {
                                      setFieldValue('active', checked);
                                    }}
                                    checked={field.value}
                                  />
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="col-12 col-md-2 d-flex flex-column justify-content-center align-items-center mb-3">
                            {hasVariants && (
                              <Field name="attributes">
                                {({ field, form: { setFieldValue } }) => (
                                  <>
                                    <label className="d-block">Atributos</label>
                                    <Switch
                                      onChange={(checked) => {
                                        setFieldValue('attributes', checked);
                                        setHasAttributes(checked);
                                      }}
                                      checked={field.value}
                                    />
                                  </>
                                )}
                              </Field>
                            )}
                          </div>
                          <div className="col-12 col-md-4 d-flex flex-column justify-content-center align-items-center mb-3">
                            <Field name="stock">
                              {({ field, form: { setFieldValue } }) => (
                                <div className="d-flex flex-column justify-content-center">
                                  <label htmlFor="stock">Existencias</label>
                                  <input
                                    {...field}
                                    type="text"
                                    inputMode="numeric"
                                    className="p-2 rounded"
                                    placeholder="100"
                                    style={{ flexGrow: '1', minWidth: '10px' }}
                                    onChange={(e) => {
                                      setFieldValue(
                                        'stock',
                                        validQty(e.target.value)
                                      );
                                      setDefaultStock(validQty(e.target.value));
                                    }}
                                  />
                                </div>
                              )}
                            </Field>
                          </div>
                          <div className="col-12 col-md-6 d-flex flex-column mb-3">
                            <Field name="preparationTime">
                              {({ field }) => (
                                <div className="d-flex flex-column justify-content-center">
                                  <label htmlFor="preparationTime">
                                    Tiempo de preparacion
                                  </label>
                                  <input
                                    {...field}
                                    type="number"
                                    inputMode="numeric"
                                    className="p-2 rounded"
                                    placeholder="1"
                                    style={{
                                      flexGrow: '1',
                                      minWidth: '10px',
                                    }}
                                  />
                                  <small className="font-style-italic text-gray">
                                    Cantidad en dias
                                  </small>
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  {hasAttributes && (
                    <div className="row">
                      <div className="col-12 my-3">
                        <div
                          className="w-100"
                          style={{
                            borderBottom: '2px dashed var(--light-gray)',
                          }}
                        />
                      </div>
                      <div style={{ overflowX: 'auto' }} className="col-12">
                        <p className="text-dark-blue font-weight-bold font-size-125x text-center">
                          Atributos
                        </p>
                        <small className="font-style-italic text-gray text-center w-100 d-block">
                          (Los valores de cada atributo se ingresan en el mismo
                          campo separados por una coma)
                        </small>
                        <button
                          type="button"
                          className="w-100 bg-purple tuyo-btn px-4 py-2 rounded text-light font-weight-bold d-flex align-items-center justify-content-around"
                          onClick={generatePermutation}
                        >
                          Generar
                        </button>
                        <ProductAttributes
                          onProductAttributeChange={(attributes) =>
                            setProductAttributes(attributes)
                          }
                        />
                      </div>
                    </div>
                  )}
                  {hasVariants && (
                    <div className="row">
                      <div className="col-12 my-3">
                        <div
                          className="w-100"
                          style={{
                            borderBottom: '2px dashed var(--light-gray)',
                          }}
                        />
                      </div>
                      <div style={{ overflowX: 'auto' }} className="col-12">
                        <p className="text-dark-blue font-weight-bold font-size-125x text-center">
                          Variaciones
                        </p>
                        <small className="font-style-italic text-gray text-center w-100 d-block">
                          (Las variaciones sin precio establecido tendrán, por
                          defecto, el precio del producto padre. Las imagenes no
                          deben superar los 2 mb.)
                        </small>
                        {((id && product) || (!id && !product)) && (
                          <VariationsTable
                            rows={product?.variants}
                            onVariationsChange={(variations) =>
                              setVariants(variations)
                            }
                            onVariantDelete={
                              id
                                ? (id) => {
                                    setVariantsToDelete([
                                      ...variantsToDelete,
                                      id,
                                    ]);
                                  }
                                : null
                            }
                            editable
                            permutations={permutations}
                            defaultStock={defaultStock}
                            baseSKU={baseSKU}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="p-5 m-5 d-flex justify-content-center align-items-center"
                  style={{ flexGrow: '1' }}
                >
                  <ClimbingBoxLoader color={colors.green} size="25" />
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewProduct;
