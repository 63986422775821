import React from 'react';

function Image({ src, alt, className }) {
  const _placeholder = 'https://via.placeholder.com/200?text=Sin+imagen';

  const onError = (e) => {
    e.target.src = _placeholder;
  };

  if (!src) {
    src = _placeholder;
  }

  return <img src={src} alt={alt} className={className} onError={onError} />;
}

export default Image;
