import React, { useEffect, useState, useRef } from 'react';
import { Chart } from 'chart.js';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Datetime from 'react-datetime';
import * as moment from 'moment';

import {
  formatPrice,
  formatAmount,
  genChartGradient,
  getMonthsDates,
  getWeeksDates,
  getDaysDates,
} from 'utils/misc';
import api from '../utils/api';
import colors from 'assets/resources/colors';
import { STATUS } from '../assets/resources/status';

import DashboardTotalCard from 'components/DashboardTotalCard/DashboardTotalCard';
import Tabs from 'components/Tabs/Tabs';
import Table from 'components/Table/Table';
import Orders from './Orders';
import ToggleButtons from 'components/ToggleButtons/ToggleButtons';
import Returns from './Returns';

import totalIncomeSVG from 'assets/icons/total-income.svg';
import totalSalesSVG from 'assets/icons/total-sales.svg';
import pendingApprovalSVG from 'assets/icons/pending-approval.svg';
import pendingReturnSVG from 'assets/icons/pending-return.svg';

const monthTemplate = {
  sales: {
    dataset: [],
    total: 0,
  },
  income: {
    dataset: [],
    total: 0,
  },
  name: '',
};

const today = new Date();
const prevMonth = new Date();
prevMonth.setMonth(prevMonth.getMonth() - 1);

let chartJS;
let chartCtx;

const labels = [];
for (let i = 1; i <= 31; i++) {
  labels.push(i);
}

function Dashboard() {
  const { REACT_APP_COOKIES_STORE_ID, REACT_APP_TITLE } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_STORE_ID]);
  useEffect(() => {
    document.title = `Inicio  | ${REACT_APP_TITLE}`;

    setTablesActiveTab('pending-approval');
  }, []);

  const storeId = cookies[REACT_APP_COOKIES_STORE_ID];

  const [month, setMonth] = useState(today.getMonth());
  const [year, setYear] = useState(today.getFullYear());

  /* TOTAL CARDS */
  const [totalSales, setTotalSales] = useState(0);
  const [prevPeriodTotalSales, setPrevPeriodTotalSales] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [prevPeriodTotalIncome, setPrevPeriodTotalIncome] = useState(0);
  const [totalCardsPeriod, setTotalCardsPeriod] = useState('month');
  const [totalCardsComparison, setTotalCardsComparison] = useState('');
  const [totalPendingApproval, setTotalPendingApproval] = useState(0);
  const [totalPendingReturn, setTotalPendingReturns] = useState(0);
  const [salesChangePctg, setSalesChangePctg] = useState(0);
  const [incomeChangePctg, setIncomeChangePctg] = useState(0);
  const [periodsDates, setPeriodsDates] = useState();

  const [comparativeMonths, setComparativeMonths] = useState(
    `${prevMonth.getMonth()}/${prevMonth.getFullYear()}-${today.getMonth()}/${today.getFullYear()}`
  );
  const [comparativeMonthsDates, setComparativeMonthsDates] = useState();

  const [monthsVisibilityOnCharts, setMonthsVisibilityOnCharts] = useState({
    first: false,
    second: false,
  });

  const [bestSelling, setBestSelling] = useState([]);

  const [chartsActiveTab, setChartsActiveTab] = useState('sales');
  const [tablesActiveTab, setTablesActiveTab] = useState('pending-returns');

  const [firstMonth, setFirstMonth] = useState({ ...monthTemplate });
  const [secondMonth, setSecondMonth] = useState({ ...monthTemplate });

  const chart = useRef(null);

  const bestSellingTable = {
    columns: [
      { text: '', key: 'image', isImage: true, className: 'text-center' },
      { text: 'Producto', key: 'name', className: 'text-center' },
      { text: 'Cantidad', key: 'qty', className: 'text-center', isQty: true },
      {
        text: 'Ingreso',
        key: 'income',
        className: 'text-center',
        isCurrency: true,
      },
      { text: 'Categoría', key: 'category', className: 'text-center' },
      { text: 'Subcategoría', key: 'subcategory', className: 'text-center' },
    ],
    options: {
      id: 'fk_productsVariantsId',
    },
  };

  useEffect(() => {
    const options = {
      animation: {
        duration: 0,
      },
      tooltips: {
        titleFontSize: 0,
        displayColors: false,
        callbacks: {
          label: (tooltipItem, data) => {
            return tooltipItem.yLabel;
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: 'rgba(0,0,0,0)',
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              color: 'rgba(0,0,0,0)',
            },
          },
        ],
      },
    };

    chartCtx = chart.current.getContext('2d');
    chartJS = new Chart(chartCtx, {
      type: 'line',
      options: { ...options },
      data: {
        labels,
        datasets: [
          {
            lineTension: 0.1,
            label: firstMonth.name,
            data:
              chartsActiveTab === 'sales'
                ? firstMonth.sales.dataset
                : firstMonth.income.dataset,
            borderColor: colors.light_blue,
            backgroundColor: genChartGradient(
              chartCtx,
              0,
              0,
              0,
              400,
              '129, 149, 255'
            ),
            pointBackgroundColor: colors.light_blue,
          },
          {
            lineTension: 0.1,
            label: secondMonth.name,
            data:
              chartsActiveTab === 'sales'
                ? secondMonth.sales.dataset
                : secondMonth.income.dataset,
            borderColor: colors.green,
            fill: false,
            pointBackgroundColor: colors.green,
          },
        ],
      },
    });
  }, []);

  useEffect(() => {
    chartJS.data.datasets[0].data =
      chartsActiveTab === 'sales'
        ? firstMonth.sales.dataset
        : firstMonth.income.dataset;
    chartJS.data.datasets[1].data =
      chartsActiveTab === 'sales'
        ? secondMonth.sales.dataset
        : secondMonth.income.dataset;
    chartJS.update();
  }, [chartsActiveTab, firstMonth, secondMonth]);

  /* Get dates for select months on comparative charts */
  useEffect(() => {
    const [firstMonthSelect, secondMonthSelect] = comparativeMonths
      .split('-')
      .map((month) => month.split('/'));
    const [month, year] = secondMonthSelect;

    setFirstMonth({
      ...firstMonth,
      name: new Date(
        firstMonthSelect[1],
        firstMonthSelect[0],
        1
      ).toLocaleString('default', { month: 'long' }),
    });
    setSecondMonth({
      ...secondMonth,
      name: new Date(
        secondMonthSelect[1],
        secondMonthSelect[0],
        1
      ).toLocaleString('default', { month: 'long' }),
    });

    setComparativeMonthsDates(getMonthsDates(new Date(year, month, 1)));
  }, [comparativeMonths]);

  const getMonthData = (month) => {
    api
      .findAll(
        '/orderdetails?filter=' +
          JSON.stringify({
            where: {
              fk_storesId: storeId,
              createdAt: {
                $gt:
                  month === '2nd'
                    ? comparativeMonthsDates.currPeriod1stDay
                    : comparativeMonthsDates.prevPeriod1stDay,
                $lt:
                  month === '2nd'
                    ? comparativeMonthsDates.currPeriodLastDay
                    : comparativeMonthsDates.currPeriod1stDay,
              },
              fk_purchaseStatusesId: STATUS.FOR_INCOME_AND_SALES,
            },
            attributes: ['createdAt', 'qty', 'price'],
          })
      )
      .then((res) => {
        const salesDataset = new Array(31).fill(0);
        const incomeDataset = new Array(31).fill(0);

        res.data.forEach((detail) => {
          const date = new Date(detail.createdAt).getDate();
          salesDataset[date - 1] += detail.qty;
          incomeDataset[date - 1] += detail.qty * detail.price;
        });

        const action = month === '2nd' ? setSecondMonth : setFirstMonth;
        const object = month === '2nd' ? secondMonth : firstMonth;
        action({
          ...object,
          sales: {
            ...object.sales,
            dataset: salesDataset,
            total: res.data.reduce((acc, detail) => acc + detail.qty, 0),
          },
          income: {
            ...object.income,
            dataset: incomeDataset,
            total: res.data.reduce(
              (acc, detail) => acc + detail.qty * detail.price,
              0
            ),
          },
        });
      })
      .catch((err) => {
        console.error(err);
        toast.warning('[SERVER_ERROR] No se pudo obtener detalles de órdenes');
      });
  };

  /* Get ordersDetails for select months for comparative charts */
  useEffect(() => {
    if (comparativeMonthsDates) {
      getMonthData('1st');
      getMonthData('2nd');
    }
  }, [comparativeMonthsDates]);

  useEffect(() => {
    setPeriodsDates(
      totalCardsPeriod === 'month'
        ? getMonthsDates(today)
        : totalCardsPeriod === 'week'
        ? getWeeksDates(today)
        : getDaysDates(today)
    );
  }, [totalCardsPeriod]);

  /* Get ordersDetails for current period */
  useEffect(() => {
    periodsDates &&
      api.ordersDetails
        .getByStore(storeId, {
          dateRange: {
            gt: periodsDates.currPeriod1stDay,
            lt: periodsDates.currPeriodLastDay,
          },
          status: STATUS.FOR_INCOME_AND_SALES,
        })
        .then((res) => {
          setTotalSales(res.data.reduce((acc, detail) => acc + detail.qty, 0));

          setTotalIncome(
            res.data.reduce((acc, detail) => acc + detail.qty * detail.price, 0)
          );
        })
        .catch((err) => {
          console.error(err);
          toast.warning(
            '[SERVER_ERROR] No se pudo obtener detalles de órdenes'
          );
        });
  }, [periodsDates]);

  /* Get ordersDetails for prev period */
  useEffect(() => {
    periodsDates &&
      api.ordersDetails
        .getByStore(storeId, {
          dateRange: {
            gt: periodsDates.prevPeriod1stDay,
            lt: periodsDates.currPeriod1stDay,
          },
          status: STATUS.FOR_INCOME_AND_SALES,
        })
        .then((res) => {
          setPrevPeriodTotalSales(
            res.data.reduce((acc, detail) => acc + detail.qty, 0)
          );

          setPrevPeriodTotalIncome(
            res.data.reduce((acc, detail) => acc + detail.qty * detail.price, 0)
          );
        })
        .catch((err) => {
          console.error(err);
          toast.warning(
            '[SERVER_ERROR] No se pudo obtener detalles de órdenes'
          );
        });
  }, [periodsDates]);

  /* Update sales changePctg whenever totalSales or prevPeriodTotalSales change */
  useEffect(() => {
    setSalesChangePctg(parseInt((totalSales / prevPeriodTotalSales - 1) * 100));
  }, [totalSales, prevPeriodTotalSales]);

  /* Update income changePctg whenever totalIncome or prevPeriodTotalIncome change */
  useEffect(() => {
    setIncomeChangePctg(
      parseInt((totalIncome / prevPeriodTotalIncome - 1) * 100)
    );
  }, [totalIncome, prevPeriodTotalIncome]);

  /* Change comparison text showed on total cards */
  useEffect(() => {
    const totalCardsComparisonText = {
      month: 'el último mes',
      week: 'la última semana',
      day: 'ayer',
    };

    setTotalCardsComparison(totalCardsComparisonText[totalCardsPeriod]);
  }, [totalCardsPeriod]);

  useEffect(() => {
    chartJS.data = {
      ...chartJS.data,
      datasets: [
        {
          ...chartJS.data.datasets[0],
          hidden: monthsVisibilityOnCharts.first,
        },
        {
          ...chartJS.data.datasets[1],
          hidden: monthsVisibilityOnCharts.second,
        },
      ],
    };

    chartJS.update();
  }, [monthsVisibilityOnCharts]);

  useEffect(() => {
    api.ordersDetails
      .getByStore(storeId, { status: STATUS.FOR_INCOME_AND_SALES })
      .then((res) => {
        const bestSelling = [];
        res.data.forEach((detail) => {
          const product = bestSelling.find(
            (product) =>
              product.fk_productsVariantsId === detail.fk_productsVariantsId
          );

          if (product) {
            product.qty += detail.qty;
            product.income += detail.qty * detail.price;
          } else {
            bestSelling.push({ ...detail, income: detail.price });
          }
        });

        setBestSelling(
          bestSelling
            .map((product) => ({
              ...product,
              category: product?.variant?.product?.category?.name,
            }))
            .sort((a, b) => {
              return a.qty > b.qty ? 1 : a.qty < b.qty ? -1 : 0;
            })
            .reverse()
            .slice(0, 10)
        );
      })
      .catch((err) => {
        console.error(err);
        toast.warning(
          '[SERVER_ERROR] No se pudo obtener los detalles de órdenes'
        );
      });
  }, []);

  const toggleMonthVisibilityOnCharts = (month) => {
    setMonthsVisibilityOnCharts({
      ...monthsVisibilityOnCharts,
      [month]: !monthsVisibilityOnCharts[month],
    });
  };

  return (
    <div className="content">
      <div>
        <div className="row justify-content-start">
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <ToggleButtons
              activeButton={totalCardsPeriod}
              onChange={(value) => setTotalCardsPeriod(value)}
              buttons={[
                { key: 'day', text: 'Día' },
                { key: 'week', text: 'Semana' },
                { key: 'month', text: 'Mes' },
              ]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <DashboardTotalCard
              desc="Ventas totales"
              amount={totalSales}
              changePctg={salesChangePctg}
              icon={totalSalesSVG}
              comparisonText={totalCardsComparison}
              showComparison
            />
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <DashboardTotalCard
              desc="Ingresos totales"
              amount={totalIncome}
              isMoney="true"
              changePctg={incomeChangePctg}
              icon={totalIncomeSVG}
              comparisonText={totalCardsComparison}
              showComparison
            />
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <Link to="/admin/orders">
              <DashboardTotalCard
                desc="Pendientes de aprobación"
                amount={totalPendingApproval}
                icon={pendingApprovalSVG}
                comparisonText={totalCardsComparison}
              />
            </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-3 mb-3">
            <Link to="/admin/returns">
              <DashboardTotalCard
                desc="Pendientes de devolución"
                amount={totalPendingReturn}
                icon={pendingReturnSVG}
                comparisonText={totalCardsComparison}
              />
            </Link>
          </div>
        </div>
        <div className="row mt-3 mt-md-4">
          <div className="col">
            <Tabs
              onChange={(tab) => setChartsActiveTab(tab)}
              tabs={[
                { text: 'Ventas totales', key: 'sales' },
                { text: 'Ingresos totales', key: 'income' },
              ]}
              activeTab={chartsActiveTab}
            />
          </div>
        </div>
        <div className="row mt-3 mt-md-4">
          <div className="col">
            <div className="pt-2">
              <div>
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-12 col-md-4 col-lg-3 d-flex justify-content-md-center mt-3 mt-md-0">
                    <button
                      className="d-flex align-items-center"
                      onClick={() => toggleMonthVisibilityOnCharts('first')}
                    >
                      <div
                        className="rounded"
                        style={{
                          width: '2rem',
                          height: '1.5rem',
                          backgroundColor: colors.light_blue,
                          display: 'inline-block',
                          marginRight: '0.5rem',
                        }}
                      />
                      {firstMonth.name}
                      <span
                        className="font-weight-bold"
                        style={{ marginLeft: '0.5rem' }}
                      >
                        {chartsActiveTab === 'sales'
                          ? formatAmount(firstMonth.sales.total)
                          : formatPrice(firstMonth.income.total)}
                      </span>
                    </button>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 d-flex justify-content-md-center mt-2 mt-md-0">
                    <button
                      className="d-flex align-items-center"
                      onClick={() => toggleMonthVisibilityOnCharts('second')}
                    >
                      <div
                        className="rounded"
                        style={{
                          width: '2rem',
                          height: '1.5rem',
                          backgroundColor: colors.green,
                          display: 'inline-block',
                          marginRight: '0.5rem',
                        }}
                      />
                      {secondMonth.name}
                      <span
                        className="font-weight-bold"
                        style={{ marginLeft: '0.5rem' }}
                      >
                        {chartsActiveTab === 'sales'
                          ? formatAmount(secondMonth.sales.total)
                          : formatPrice(secondMonth.income.total)}
                      </span>
                    </button>
                  </div>
                  <div className="col-12 col-md-4 col-xl-3 mt-2 mt-md-0 d-flex align-items-center">
                    {moment(`${year}-${month + 1}-1`)
                      .subtract('1', 'month')
                      .year()}
                    -
                    {(
                      moment(`${year}-${month + 1}-1`)
                        .subtract('1', 'month')
                        .month() + 1
                    )
                      .toString()
                      .padStart(2, '0')}
                    <Datetime
                      className="w-50 ml-4"
                      inputProps={{ style: { textAlign: 'center' } }}
                      initialValue={today}
                      closeOnSelect={true}
                      isValidDate={(date) => date.isBefore(moment())}
                      onChange={(date) => {
                        const selectedMonthDate = date;
                        const prevMonthDate = moment(date).subtract(
                          '1',
                          'month'
                        );
                        setComparativeMonths(
                          `${prevMonthDate.month()}/${prevMonthDate.year()}-${selectedMonthDate.month()}/${selectedMonthDate.year()}`
                        );
                        setMonth(date.month());
                        setYear(date.year());
                      }}
                      timeFormat={false}
                      dateFormat="YYYY-MM"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12" style={{ overflowX: 'auto' }}>
                    <div
                      className="d-flex align-items-center"
                      style={{ minWidth: '800px' }}
                    >
                      <div
                        className="text-light-gray"
                        style={{
                          display: 'inline-block',
                          transform: 'rotate(-90deg)',
                          letterSpacing: '0.75rem',
                          width: '2%',
                        }}
                      >
                        {chartsActiveTab === 'sales' ? 'CANTIDAD' : 'MONTO'}
                      </div>
                      <div style={{ display: 'inline-block', width: '98%' }}>
                        <canvas
                          className="mt-2"
                          ref={chart}
                          style={{
                            width: '100%',
                            height: '400px',
                          }}
                        />
                      </div>
                    </div>
                    <p
                      className="text-light-gray text-center"
                      style={{ letterSpacing: '0.75rem' }}
                    >
                      TIEMPO
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 mt-md-4">
          <div className="col">
            <Tabs
              onChange={(tab) => setTablesActiveTab(tab)}
              tabs={[
                {
                  text: 'Pedidos pendientes de aprobación',
                  badge: totalPendingApproval,
                  key: 'pending-approval',
                },
                {
                  text: 'Pendientes de devolución o reembolso',
                  badge: totalPendingReturn,
                  key: 'pending-returns',
                },
                { text: 'Productos más vendidos', key: 'best-selling' },
              ]}
              activeTab={tablesActiveTab}
            />
          </div>
        </div>
        <div className="row mt-3 mt-md-4">
          <div className="col">
            {tablesActiveTab === 'pending-approval' ? (
              <Orders
                onTotalChange={(value) => setTotalPendingApproval(value)}
                onlyPending={true}
              />
            ) : tablesActiveTab === 'pending-returns' ? (
              <Returns
                onlyPending
                onTotalChange={(total) => setTotalPendingReturns(total)}
              />
            ) : (
              <div className="pt-2">
                <div className="row justify-content-end">
                  <div className="col-12 mt-3 mt-md-4">
                    <Table
                      showFilters
                      columns={bestSellingTable.columns}
                      data={bestSelling}
                      options={bestSellingTable.options}
                      pageSize={10}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
