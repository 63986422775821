import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';

import AdminNavbarLinks from '../Navbars/AdminNavbarLinks.jsx';

import logo from 'assets/img/tuyo_dashboard.png';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      userRoleName: jwt_decode(
        this.props.cookies.get(process.env.REACT_APP_COOKIES_JWT)
      ).user.role.name,
    };
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  /* Remove div generated by responsive sidebar */
  removeBodyClickDiv() {
    const bodyClickDiv = document.getElementById('bodyClick');
    bodyClickDiv && bodyClickDiv.parentElement.removeChild(bodyClickDiv);
  }

  render() {
    const sidebarBackground = {
      backgroundImage: 'url(' + this.props.image + ')',
    };
    return (
      <div id="sidebar" className="sidebar" data-image={this.props.image}>
        {this.props.hasImage ? (
          <div className="sidebar-background" style={sidebarBackground} />
        ) : null}
        <div className="logo">
          <NavLink to="/admin/dashboard">
            <div className="logo-img">
              <img src={logo} alt="logo_image" />
            </div>
          </NavLink>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav h-100 flex-column">
            {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
            {this.props.routes
              .filter((route) => route.roles.includes(this.state.userRoleName))
              .map((route, index) => {
                if (!route.redirect)
                  return (
                    <li
                      className={
                        this.activeRoute(route.layout + route.path) +
                        ' ' +
                        route.className
                      }
                      style={{
                        flexGrow: route.flexGrow,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                      }}
                      key={index}
                    >
                      <NavLink
                        to={route.layout + route.path}
                        className="nav-link"
                        activeClassName="active"
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={(_) => {
                          this.removeBodyClickDiv();
                        }}
                      >
                        <div className="d-flex align-items-center text-dark-blue">
                          <i className="material-icons-round align-middle mx-3">
                            {route.icon}
                          </i>
                        </div>
                        <div>
                          <p className="text-dark-blue text-lg">{route.name}</p>
                        </div>
                      </NavLink>
                    </li>
                  );
                return null;
              })}
          </ul>
        </div>
      </div>
    );
  }
}

export default withCookies(Sidebar);
