import { DateRangePicker, DefinedRange } from 'react-date-range';

import { Popover } from 'react-tiny-popover';
import React from 'react';
import { dateStaticRanges } from 'utils/misc';
import { parseDate } from 'utils/misc';

function DateSelector({ onChange, onClose, value, className }) {
  const onRangeChange = (item) => {
    const { selection } = item;

    const _selection = {
      ...selection,
      startDate: new Date(selection?.startDate),
      endDate: new Date(selection?.endDate),
    };

    _selection.startDate.setHours(0, 0, 0, 0);
    _selection.endDate.setHours(23, 59, 59, 999);

    const _item = {
      ...item,
      selection: _selection,
    };

    if (onChange) onChange(_item);
  };

  return (
    <Popover
      isOpen={value.open}
      positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
      padding={4}
      align="center"
      containerStyle={{
        margin: '0 0 0 0',
      }}
      onClickOutside={onClose}
      containerClassName="shadow-lg -left-md-4"
      content={
        <div className="d-relative">
          <div
            style={{
              width: 50,
              height: 40,
              right: 0,
              top: -40,
              borderRadius: '16px 16px 0 0 ',
            }}
            className="d-flex bg-white justify-content-center align-items-center items-center position-absolute"
          >
            <button
              onClick={(evt) => {
                evt.stopPropagation();
                if (onClose) onClose();
              }}
            >
              <span className="material-icons">close</span>
            </button>
          </div>
          <DefinedRange
            className="d-lg-none"
            onChange={onChange}
            ranges={[value]}
            staticRanges={dateStaticRanges}
          />
          <DateRangePicker
            className="d-none d-lg-inline-flex"
            onChange={onRangeChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[value]}
            staticRanges={dateStaticRanges}
            direction="vertical"
            rangeColors={['#06c965']}
            color="#06c965"
          />
        </div>
      }
    >
      <div
        onClick={onClose}
        className={`cursor-pointer rounded position-relative ${
          className ?? ''
        }`}
      >
        <div className="text-center font-bold d-flex justify-content-end">
          <div className="d-flex flex-column align-items-start mr-4">
            <small>Inicio</small>
            <span className="rounded-lg bg-green-mint py-2 px-4">
              {parseDate(value.startDate, {
                dateStyle: 'medium',
                timeStyle: null,
              })}
            </span>
          </div>
          <div className="d-flex flex-column align-items-start">
            <small>Final</small>
            <span className="rounded-lg bg-green-mint py-2 px-4">
              {parseDate(value.endDate, {
                dateStyle: 'medium',
                timeStyle: null,
              })}
            </span>
          </div>
        </div>
      </div>
    </Popover>
  );
}

export default DateSelector;
