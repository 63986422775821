import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { useHistory, useParams } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

import Card from '../components/Card/Card';

import colors from '../assets/resources/colors';
import api from '../utils/api';

import markerSVG from '../assets/icons/marker.svg';

const SVDBID = 59; // El Salvador DB ID

let map;

function NewBranchOffice() {
  const formRef = useRef(null);

  const history = useHistory();
  const { id } = useParams();

  const { REACT_APP_COOKIES_USER_ID, REACT_APP_COOKIES_STORE_ID } = process.env;

  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID, REACT_APP_COOKIES_STORE_ID]);

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const [location, setLocation] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [branchOffice, setBranchOffice] = useState();

  useEffect(() => {
    const renderMap = () => {
      const mapContainer = document.getElementById('mapContainer');

      map = new window.google.maps.Map(mapContainer, {
        zoom: 16,
        center: { lng: -89.2365921, lat: 13.7034519 },
        disableDefaultUI: true,
      });

      map.addListener('center_changed', () => {
        setLocation(map.getCenter().toJSON());
      });

      const input = document.getElementById('pac-input');
      const searchBox = new window.google.maps.places.SearchBox(input);
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

      searchBox.addListener('places_changed', () => {
        map.panTo(searchBox.getPlaces()[0].geometry.location.toJSON());
      });
    };

    const checkForGoogleMaps = setInterval(() => {
      if (window.google.maps) {
        renderMap();
        clearInterval(checkForGoogleMaps);
      }
    }, 1 * 1000);
  }, []);

  /* Get branchOffice info if editing */
  useEffect(() => {
    id &&
      api.branchOffices
        .getOne(id)
        .then(res => {
          setBranchOffice(res.data);
        })
        .catch(err => {
          console.error(err);
          toast.warning('[SERVER_ERROR] No se pudo obtener información de la sucursal');
        });
  }, []);

  /* Update select values when branchOffice changes */
  useEffect(() => {
    if (branchOffice) {
      const [lng, lat] = branchOffice.location.coordinates;

      setSelectedState(branchOffice.fk_statesId);
      setLocation({ lng, lat });

      const checkForGoogleMaps = setInterval(() => {
        if (map) {
          map.setCenter({ lng, lat });
          clearInterval(checkForGoogleMaps);
        }
      }, 1 * 1000);
    }
  }, [branchOffice]);

  /* Get states list on componentDidMount */
  useEffect(() => {
    api.states
      .getByCountry(SVDBID)
      .then(res => {
        setStates(res.data);
      })
      .catch(err => {
        console.error(err);
        toast.warning('[SERVER_ERROR] No se pudo obtener listado de estados');
      });
  }, []);

  /* Set first of states list as selectedState */
  useEffect(() => {
    id ? branchOffice && setSelectedState(branchOffice.fk_statesId) : states.length > 0 && setSelectedState(states[0].statesId);
  }, [states, branchOffice]);

  /* Get cities list when selectedState changes */
  useEffect(() => {
    selectedState &&
      api.cities
        .getByState(selectedState)
        .then(res => {
          setCities(res.data);
        })
        .catch(err => {
          console.error(err);
          toast.warning('[SERVER_ERROR] No se pudo obtener listado de ciudades');
        });
  }, [selectedState]);

  /* Set first of cities list as selectedCity */
  useEffect(() => {
    id ? branchOffice && setSelectedCity(branchOffice.fk_citiesId) : cities.length > 0 && setSelectedCity(cities[0].citiesId);
  }, [cities, branchOffice]);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const save = values => {
    setIsSaving(true);

    const body = {
      name: values.name,
      phone: values.phone.replace(' ', ''),
      address: values.address,
      location: [location.lng, location.lat],
      fk_storesId: parseInt(cookies[REACT_APP_COOKIES_STORE_ID]),
      fk_statesId: selectedState,
      fk_citiesId: selectedCity,
      fk_countriesId: SVDBID,
      [id ? 'updatedBy' : 'createdBy']: parseInt(cookies[REACT_APP_COOKIES_USER_ID]),
    };

    const action = id ? api.branchOffices.update(id, body) : api.branchOffices.create(body);

    action
      .then(() => {
        toast.success(`¡Sucursal ${id ? 'actualizada' : 'creada'}!`);
        history.push('/admin/profile');
      })
      .catch(err => {
        setIsSaving(false);
        console.error(err);
        toast.warning(`[SERVER_ERROR] No se pudo ${id ? 'actualizar' : 'crear'} la sucursal`);
      });
  };

  return (
    <div className='content d-flex flex-column' style={{ flexGrow: '1' }}>
      <div className='row align-items-center'>
        <div className='col-12 col-md-8 col-lg-9 d-flex align-items-center'>
          <p className='text-dark-blue font-size-2x font-weight-bold w-100 text-center text-md-left'>{id ? branchOffice?.name : 'Nueva sucursal'}</p>
        </div>
        <div className='col-12 col-md-4 col-lg-3 mt-2 mt-md-0'>
          <button
            disabled={isSaving}
            onClick={handleSubmit}
            type='button'
            className='tuyo-btn bg-purple text-white font-weight-bold rounded w-100 py-2 d-flex align-items-center justify-content-around'>
            {isSaving ? (id ? 'Actualizando' : 'Creando') : 'Guardar'}
            <BounceLoader color='#fff' loading={isSaving} size='18' />
          </button>
        </div>
      </div>
      <div className='row mt-4' style={{ flexGrow: '1' }}>
        <div className='col-12'>
          <Card>
            {id && !branchOffice ? (
              <div className='p-5 m-5 d-flex justify-content-center align-items-center' style={{ flexGrow: '1' }}>
                <ClimbingBoxLoader color={colors.green} size='25' />
              </div>
            ) : (
              <>
                <div className='map-wrapper' style={{ position: 'relative' }}>
                  <input
                    id='pac-input'
                    placeholder='Buscar'
                    type='text'
                    style={{ width: '300px', marginTop: '10px', marginLeft: '10px' }}
                    className='rounded px-2 py-1'
                  />
                  <div
                    id='map-center'
                    style={{
                      position: 'absolute',
                      zIndex: '99',
                      height: '40px',
                      width: '40px',
                      top: '50%',
                      left: '50%',
                      marginLeft: '-21px',
                      marginTop: '-41px',
                    }}>
                    <img src={markerSVG} alt='' style={{ width: '100%' }} />
                  </div>
                  <div style={{ width: '100%', height: '400px', borderRadius: '1rem' }} id='mapContainer' />
                </div>
                <div className='row mt-4'>
                  <div className='col-12'>
                    <Formik
                      enableReinitialize={true}
                      innerRef={formRef}
                      validationSchema={Yup.object().shape({
                        name: Yup.string().trim().required('Campo requerido'),
                        address: Yup.string().trim().required('Campo requerido'),
                        phone: Yup.string()
                          .trim()
                          .required('Campo requerido')
                          .matches(/(2|6|7)[0-9]{3}[ ]?[0-9]{4}/, 'Número de teléfono no válido'),
                      })}
                      initialValues={{
                        name: branchOffice?.name || '',
                        address: branchOffice?.address || '',
                        phone: branchOffice?.phone || '',
                      }}
                      onSubmit={formValues => save(formValues)}>
                      {({ errors, touched }) => (
                        <Form>
                          <div className='row'>
                            <div className='col-12 col-md-6 mb-3'>
                              <label htmlFor='name'>Nombre</label>
                              <Field name='name' type='text' placeholder='Nombre de la sucursal' className='w-100 rounded p-2' />
                              {errors.name && touched.name ? <p className='text-red font-size-075x pt-1 pl-1'>{errors.name}</p> : null}
                            </div>
                            <div className='col-12 col-md-6 mb-3'>
                              <label htmlFor='phone'>Teléfono</label>
                              <Field name='phone'>
                                {({ field, form: { setFieldValue, touched, errors } }) => (
                                  <>
                                    <InputMask
                                      {...field}
                                      onChange={event => setFieldValue('phone', event.target.value)}
                                      className='p-2 rounded w-100'
                                      maskChar={null}
                                      formatChars={{
                                        9: '[0-9]',
                                        7: '(2|6|7)',
                                      }}
                                      mask='7999 9999'
                                    />
                                    {errors.phone && touched.phone ? <p className='text-red font-size-075x pt-1 pl-1'>{errors.phone}</p> : null}
                                  </>
                                )}
                              </Field>
                            </div>
                            <div className='col-12 mb-3'>
                              <label htmlFor='address'>Dirección</label>
                              <Field name='address' type='text' placeholder='Dirección de la sucursal' className='w-100 rounded p-2' />
                              {errors.address && touched.address ? <p className='text-red font-size-075x pt-1 pl-1'>{errors.address}</p> : null}
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                    <div className='row'>
                      <div className='col-12 col-md-6'>
                        <label className='d-block' htmlFor='state'>
                          Departamento
                        </label>
                        <select
                          onChange={event => setSelectedState(parseInt(event.target.value))}
                          value={selectedState}
                          className='p-2 w-100 rounded'
                          id='state'>
                          {states.map(state => (
                            <option key={state.statesId} value={state.statesId}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className='col-12 col-md-6'>
                        <label className='d-block' htmlFor='city'>
                          Ciudad
                        </label>
                        <select onChange={event => setSelectedCity(parseInt(event.target.value))} value={selectedCity} className='p-2 w-100 rounded' id='city'>
                          {cities.map(city => (
                            <option key={city.citiesId} value={city.citiesId}>
                              {city.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}

export default NewBranchOffice;
