import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import BarLoader from 'react-spinners/BarLoader';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';

import Modal from 'components/Modal/Modal';
import Table from 'components/Table/Table';
import Status from 'components/Status/Status';

import {
  formatPrice,
  formatTimestamp,
  getGoogleMapsUrFromCoordinates,
  getOrderCode,
  verifyAmazonASIN,
} from '../../utils/misc';
import colors from 'assets/resources/colors';
import { STATUS } from '../../assets/resources/status';
import api from '../../utils/api';
import Ticket from 'components/Ticket';
import SendNotification from 'components/SendNotification';
import OrderInvoice from 'components/OrderInvoice';

const OrderUpdateStatus = {
  Idle: 'Idle',
  Saving: 'Saving',
  Error: 'Error',
  Success: 'Success',
};

const shippingTypes = {
  BASIC: 'Envío básico',
  TURBO: 'Envío turbo',
  SCHEDULED: 'Envío programado',
  PICKUP: 'Recoger en tienda',
};

const orderStatusTexts = [
  {
    ids: [1],
    text: 'Orden ingresada',
  },
  {
    ids: [2],
    text: 'Orden aceptada',
  },
  {
    ids: [3],
    text: 'Orden en camino',
  },
  {
    ids: [4],
    text: 'Orden entregada',
  },
  {
    ids: [5],
    text: 'Orden no recolectada',
  },
  {
    ids: [6],
    text: 'Orden no entregada',
  },
  {
    ids: [7, 8],
    text: 'Orden cancelada',
  },
  {
    ids: [9],
    text: 'Peticion de cambio',
  },
  {
    ids: [10],
    text: 'Peticion de reembolso',
  },
  {
    ids: [11],
    text: 'Cambio aprobado',
  },
  {
    ids: [12],
    text: 'Cambio denegado',
  },
  {
    ids: [13],
    text: 'Reembolso aprobado',
  },
  {
    ids: [14],
    text: 'Reembolso denegado',
  },
  {
    ids: [15],
    text: 'Orden lista',
  },
  {
    ids: [16],
    text: 'Entregado en bodega',
  },
  {
    ids: [17],
    text: 'En ruta',
  },
  {
    ids: [18],
    text: 'Cancelado en punto de entrega',
  },
  {
    ids: [19],
    text: 'De camino al comercio',
  },
  {
    ids: [20],
    text: 'Espera en comercio',
  },
];

function OrderApproval({
  order,
  onCloseRequest,
  updateProductStock,
  isSaving,
  requestGetOrders,
  isTurboShipping,
}) {
  const { REACT_APP_COOKIES_USER_ID } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_USER_ID]);

  const userId = cookies[REACT_APP_COOKIES_USER_ID];

  const [products, setProducts] = useState([]);
  const [newOrderStatus, setNewOrderStatus] = useState();
  const [possibleOrderStatus, setPossibleOrderStatus] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const [updateOrderStatusStatus, setUpdateOrderStatusStatus] = useState(
    OrderUpdateStatus.Idle
  );

  useEffect(() => {
    setProducts(
      order.details.map((detail) => ({
        ordersDetailsId: detail.ordersDetailsId,
        image: detail?.variant?.image,
        name: detail?.variant?.product?.name,
        sku: detail?.variant?.sku,
        variant: detail?.variant?.values.split(',').join(' '),
        branchOffice: detail.store_branch.name,
        price: detail.price * (1 - detail.discount / 100),
        qty: detail.qty,
        status: detail.fk_purchaseStatusesId,
        productsVariantsId: detail.variant.productsVariantsId,
        isTurboShipping: detail.isTurboShipping,
        type: (
          <span
            className="px-2 py-1 font-weight-bold rounded"
            style={{
              backgroundColor:
                detail?.variant?.product?.flavor?.productsTypesId === 1
                  ? colors.green
                  : colors.goodies_pink,
            }}
          >
            {detail?.variant?.product?.flavor?.name.toUpperCase()}
          </span>
        ),
      }))
    );
  }, [order]);

  useEffect(() => {
    if (order) {
      order.details.forEach((detail) => {
        api.productVariants
          .getOne(detail?.variant?.productsVariantsId)
          .then((res) =>
            setProductVariants((productVariants) => [
              ...productVariants,
              res.data[0],
            ])
          )
          .catch((err) => console.error(err));
      });
    }
  }, []);

  // Get available order status
  useEffect(() => {
    api.purchasestatuses
      .getAll()
      .then((res) => {
        const statuses = res.data;

        if (statuses.length) {
          setPossibleOrderStatus(statuses);
          setNewOrderStatus(
            statuses.find((status) => status.purchaseStatusesId === 1)
          );
        }
      })
      .catch((error) => {
        console.error(error);
        toast.warning(
          '[SERVER_ERROR] No se pudo obtener lista de de status de órden'
        );
      });
  }, []);

  //get payment method
  useEffect(() => {
    if (order?.paymentType) {
      getPaymentMethod();
    }
  }, [order]);

  async function updateOrderStatus(
    orderId,
    orderStatus,
    purchaseStatusName,
    userId
  ) {
    await api.update('/orders', orderId, {
      orderStatus,
      updatedBy: userId,
    });

    return api.create('/ordershistories', {
      // status: purchaseStatusName,
      status: orderStatus,
      fk_ordersId: orderId,
      createdBy: userId,
    });
  }

  async function updateOrderDetailsStatus(details, statusId, userId) {
    await Promise.all(
      details?.map((detail) =>
        api.update('/orderdetails', detail.ordersDetailsId, {
          fk_purchaseStatusesId: statusId,
          updatedBy: userId,
        })
      )
    );

    return Promise.all(
      details?.map((detail) =>
        api.create('/ordersdetailshistories', {
          fk_purchaseStatusesId: statusId,
          fk_ordersDetailsId: detail.ordersDetailsId,
          createdBy: userId,
        })
      )
    );
  }

  function updateWholeOrderStatus() {
    setUpdateOrderStatusStatus(OrderUpdateStatus.Saving);

    updateOrderStatus(
      order.ordersId,
      orderStatusTexts.find((status) =>
        status.ids.includes(newOrderStatus?.purchaseStatusesId)
      )?.text,
      newOrderStatus?.name,
      userId
    )
      .then(() =>
        updateOrderDetailsStatus(
          order?.details,
          newOrderStatus?.purchaseStatusesId,
          userId
        )
      )
      .then(() => {
        toast.success('Estado de orden actualizado');
        setUpdateOrderStatusStatus(OrderUpdateStatus.Success);
        onCloseRequest();
      })
      .catch((error) => {
        toast.warning('No se pudo actualizar el estado de la orden');
        console.error(error);
        setUpdateOrderStatusStatus(OrderUpdateStatus.Error);
      })
      .finally(() => {
        requestGetOrders();
      });
  }

  function getPaymentMethod() {
    const type = order?.paymentType?.split('|')[0];
    return api.paymentMethods
      .getByType(type)
      .then(({ data }) => setPaymentMethod(data?.[0] ?? {}))
      .catch((err) => {
        console.error(err);
        toast.warning('Error al obtener método de pago');
      });
  }

  function genTicket() {
    const ticket = window.open(
      '',
      '_blank',
      'location=yes,height=900,width=1000,scrollbars=yes,status=yes'
    );

    ticket.document.open();

    ticket.document.write(`<html lang="en-US"><head><title>Ticket</title>
      <link href="https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
      <style>
      body{font-family: Muli,sans-serif;}
      @page {
        size: 102mm 152mm; 
        margin: 21mm 16mm 21mm 16mm; 
      }
      </style>
      </head><body>`);
    ticket.document.write(
      ReactDOMServer.renderToString(<Ticket order={order} />)
    );
    ticket.document.write('</body></html>');

    ticket.document.close();

    ticket.onload = () => {
      setTimeout(() => {
        ticket.focus();
        ticket.print();
        setTimeout(function () {
          ticket.close();
        }, 500);
      }, 100);
    };
  }

  function showInvoice(id) {
    swal({
      content: (
        <OrderInvoice
          id={order?.ordersId}
          order={order}
          paymentMethod={paymentMethod}
          onClose={() => swal.close()}
        />
      ),
      buttons: false,
    });
  }

  const productsTable = {
    options: {
      id: 'ordersDetailsId',
    },
    columns: [
      { text: '', key: 'image', isImage: true, className: 'text-center' },
      { text: 'Producto', key: 'name', className: 'text-center' },
      { text: 'SKU', key: 'sku', className: 'text-center' },
      { text: 'Variación', key: 'variant', className: 'text-center' },
      { text: 'Sucursal', key: 'branchOffice', className: 'text-center' },
      {
        text: 'Precio',
        key: 'price',
        className: 'text-center',
        isCurrency: true,
      },
      { text: 'Cantidad', key: 'qty', className: 'text-center', isQty: true },
      { text: 'Tipo', key: 'type', className: 'text-center' },
      {
        text: 'Estado',
        key: 'status',
        className: 'text-center',
        isStatus: true,
      },
      {
        text: 'Acciones',
        key: 'sku',
        className: 'text-center',
        isCustom: true,
        render: (sku) => {
          console.log(sku, verifyAmazonASIN(sku));
          return verifyAmazonASIN(sku) ? (
            <div className="d-flex justify-content-center">
              <a
                className="font-weight-bold bg-amazon w-100 h-100 rounded px-2 py-1 tuyo-btn text-white"
                onClick={(e) => e.stopPropagation()}
                href={`http://www.amazon.com/dp/${sku.substring(0, 10)}`}
                target="_blank"
              >
                Amazon
              </a>
            </div>
          ) : (
            <></>
          );
        },
      },
    ],
  };

  return (
    <Modal onCloseRequest={onCloseRequest} className="space-y-3">
      <div className="row">
        <div className="col-12 col-md-8 col-lg-9">
          <p className="font-weight-bold text-dark-blue text-2xl text-center text-md-left">
            ORDEN TY-{getOrderCode(order.ordersId)}
          </p>
        </div>

        {order?.shippingType === 'TURBO' ? (
          <div className="col-12 col-md-4 col-lg-3 mt-2 mt-md-0 d-flex justify-content-end align-items-center">
            <Status className="w-100" dataTip={`Turbo Envio`} value={17} />
          </div>
        ) : (
          <div className="col-12 col-md-4 col-lg-3 mt-2 mt-md-0 d-flex justify-content-end align-items-center">
            <Status
              className="w-100"
              dataTip={`Compra realizada: ${formatTimestamp(order.createdAt)}`}
              value={order.status}
            />
          </div>
        )}
      </div>

      {/* <hr />

      <div className="row h-10">
        <div className="col-12 col-md-6 d-flex">
          <select
            name="newOrderStatus"
            id="newOrderStatus"
            className="w-100"
            value={newOrderStatus?.purchaseStatusesId}
            onInput={(event) =>
              setNewOrderStatus(
                possibleOrderStatus.find(
                  (status) =>
                    status.purchaseStatusesId === parseInt(event.target.value)
                )
              )
            }
          >
            {possibleOrderStatus?.map((status) => (
              <option
                key={status.purchaseStatusesId}
                value={status.purchaseStatusesId}
              >
                {status.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-6 d-flex">
          <button
            disabled={
              !newOrderStatus ||
              updateOrderStatusStatus === OrderUpdateStatus.Saving
            }
            type="button"
            className="tuyo-btn w-100 rounded bg-red text-light font-weight-bold"
            onClick={updateWholeOrderStatus}
          >
            {updateOrderStatusStatus === OrderUpdateStatus.Saving
              ? 'Fijando...'
              : 'Fijar estado'}
          </button>
        </div>
      </div> */}

      <hr />

      <div className="row mx-0 border rounded py-2 space-y-3">
        <p className="col-12 text-indigo-dark font-extrabold">Detalle:</p>

        <div className="col-6 col-md-2">
          <p className="font-weight-bold text-green">Tipo en envío</p>
          <p>{shippingTypes[order?.shippingType]}</p>
        </div>

        <div className="col-12 col-md-3">
          <p className="font-weight-bold text-green">Comprador</p>
          <p>
            {[order?.user?.firstname, order?.user?.lastname].join(' ')} (
            {order?.user?.phone})
          </p>
        </div>

        <div className="col-12 col-md-3">
          <p className="font-weight-bold text-green">Correo</p>
          <p>{order?.user?.email}</p>
        </div>

        {[order?.apartment, order?.address, order?.city, order?.state].filter(
          (string) => string?.trim()
        ).length > 0 && (
          <div className="col-12 col-md-6 ">
            <p className="font-weight-bold text-green">Dirección de envío</p>
            <p>
              {order?.referencePoint && <b>{`${order?.referencePoint}, `}</b>}
              {[order?.apartment, order?.address, order?.city, order?.state]
                .filter((string) => string?.trim())
                .join(', ')}
            </p>
          </div>
        )}

        {order?.location?.coordinates && (
          <div className="col-6 col-md-3">
            <p className="font-weight-bold text-green">Ubicacion</p>
            <a
              href={getGoogleMapsUrFromCoordinates(
                order?.location?.coordinates[1],
                order?.location?.coordinates[0]
              )}
              target="_blank"
            >
              Ver puntero
            </a>
          </div>
        )}

        {order?.distance > 0 && (
          <div className="col-6 col-md-3">
            <p className="font-weight-bold text-green">Distancia</p>
            <p>{`${order?.distance} Km`}</p>
          </div>
        )}

        {order?.reason && (
          <div className="col-12 col-md-6">
            <p className="font-weight-bold text-green">
              Razón de reembolso/cambio
            </p>
            <p className="text-red">{order?.reason}</p>
          </div>
        )}

        {order?.eta && (
          <div className="col-12 col-md-3">
            <p className="font-weight-bold text-green">Tiempo de entrega</p>
            <p>
              {order?.eta
                .replace('|1', '')
                .split('|')
                .filter((string) => string?.trim())
                .join(', ')}
            </p>
          </div>
        )}

        <div className="col-12 col-md-3">
          <p className="font-weight-bold text-green">Fecha de creación</p>
          <p>{new Date(order?.createdAt).toLocaleString()}</p>
        </div>

        {order?.note && order?.note.trim() && (
          <div className="col-12">
            <p className="font-weight-bold text-green">Notas</p>
            {order?.completed === 'gc'
              ? Object.entries(JSON.parse(order?.note))?.map(
                  ([key, value], index) => (
                    <p key={index}>
                      {key}: {value}
                    </p>
                  )
                )
              : order?.note}
          </div>
        )}
      </div>

      <div className="row mx-0 border rounded py-2 space-y-3">
        <p className="col-12 text-indigo-dark font-extrabold">Montos:</p>

        <div className="col-6 col-md-2">
          <p className="font-weight-bold text-green">Método de pago</p>
          <p>{paymentMethod?.title ?? ''}</p>
        </div>

        {order?.paymentType == 'CARD_INSTALLMENT' && (
          <div className="col-6 col-md-2">
            <p className="font-weight-bold text-green">Meses</p>
            <p>{order?.months}</p>
          </div>
        )}

        <div className="col-6 col-md-2">
          <p className="font-weight-bold text-green">Subtotal</p>
          <p>
            {formatPrice(
              order?.details?.reduce((acc, detail) => {
                const { price, qty, discount } = detail;
                const discountPrice = price * ((parseInt(discount) || 0) / 100);

                return acc + (price - discountPrice) * qty;
              }, 0)
            )}
          </p>
        </div>

        <div className="col-6 col-md-2">
          <p className="font-weight-bold text-green">Costo de envío</p>
          <p>{formatPrice(order?.freeShipping ? 0 : order?.shipping)}</p>
        </div>

        {order?.bookingFee > 0 && (
          <div className="col-6 col-md-2">
            <p className="font-weight-bold text-green">Tarifa de servicios</p>
            <p>{formatPrice(order?.bookingFee)}</p>
          </div>
        )}

        {order?.completed == 'pickup' ? (
          <div className="col-6 col-md-2">
            <p className="font-weight-bold text-green">Booking fee</p>
            <p>{formatPrice(order?.bookingFee)}</p>
          </div>
        ) : (
          ''
        )}

        {order?.coupon && (
          <div className="col-6 col-md-2">
            <p className="font-weight-bold text-green">
              Cupón ({order?.coupon.split('|')[0]})
            </p>
            <p className="text-red">
              - {formatPrice(order?.coupon.split('|')[1])}
            </p>
          </div>
        )}

        {order?.balance ? (
          <div className="col-6 col-md-2">
            <p className="font-weight-bold text-green">Balance</p>
            <p className="text-red">- {formatPrice(order?.balance)}</p>
          </div>
        ) : (
          ''
        )}

        {order?.cardDiscount?.trim() && (
          <div className="col-6 col-md-3">
            <p className="font-weight-bold text-green">
              Descuentos con tarjeta
            </p>
            {order?.cardDiscount
              ?.split('|')
              ?.map((discount) => discount.split(','))
              ?.map((discount) => {
                const [, type, cardType, bank, value, totalDiscount] = discount;
                return (
                  <p className="text-red">{`-$${parseFloat(
                    totalDiscount
                  ).toFixed(2)} ${
                    value < 1 ? `(${value * 100}%) ` : ''
                  } Tarjeta ${cardType} de ${bank} en ${type}`}</p>
                );
              })}
          </div>
        )}

        {order?.millas ? (
          <div className="col-6 col-md-2">
            <p className="font-weight-bold text-green">Millas</p>
            <p>{formatPrice(order?.millas)}</p>
          </div>
        ) : (
          ''
        )}

        <div className="col-6 col-md-2">
          <p className="font-weight-bold text-green">Monto total</p>
          <b>{formatPrice(order?.total)}</b>
        </div>
      </div>

      <hr />

      <div className="row w-full mx-0">
        <Table
          showFilters
          {...productsTable}
          data={products}
          pageSize={10}
          className="w-full"
        />
      </div>

      <hr />

      {[STATUS.ORDER_PENDING, STATUS.ORDER_ACCEPTED].includes(order.status) && (
        <>
          <div className="row justify-content-center align-items-center">
            {order.status === STATUS.ORDER_PENDING && !isSaving && (
              <div className="col-12 col-md-5 col-xl-4">
                <button
                  onClick={() => updateOrderStatus(STATUS.ORDER_ACCEPTED)}
                  type="button"
                  className="w-100 font-weight-bold text-white mr-md-3 bg-purple tuyo-btn py-2 px-5 rounded"
                >
                  Aceptar orden
                </button>
              </div>
            )}

            {isSaving && (
              <div className="col-12 col-md-6 d-flex align-items-center">
                <BarLoader
                  height="8"
                  width="100%"
                  loading={isSaving}
                  color={colors.green}
                />
              </div>
            )}

            {order.status === STATUS.ORDER_ACCEPTED && !isSaving && (
              <div className="col-12 col-md-5 col-xl-3">
                <button
                  onClick={() => updateOrderStatus(STATUS.READY)}
                  type="button"
                  className="w-100 font-weight-bold text-white mr-md-3 bg-green tuyo-btn py-2 px-5 rounded"
                >
                  Orden lista
                </button>
              </div>
            )}

            <div className="col-12 row px-0 mx-0 mt-6">
              <div className="col-12 col-md-6">
                <button
                  className="bg-green tuyo-btn w-100 px-4 py-2 rounded text-light font-weight-bold"
                  onClick={genTicket}
                >
                  Imprimir ticket
                </button>
              </div>
              {/* <div className="col-12 col-md-4">
                <button
                  className="bg-green tuyo-btn w-100 px-4 py-2 rounded text-light font-weight-bold"
                  onClick={() => {
                    setShowNotificationModal(true);
                  }}
                >
                  Enviar notificacion
                </button>
                {showNotificationModal && (
                  <SendNotification
                    usersIds={[order?.user?.usersId]}
                    appURL={`tuyoapp://order/${order?.ordersId}`}
                    webURL={`https://tuyoapp.com/orders/TY-${getOrderCode(
                      order?.ordersId
                    )}`}
                    onCloseRequest={() => {
                      setShowNotificationModal(false);
                    }}
                  />
                )}
              </div> */}
              <div className="col-12 col-md-6">
                <button
                  className="bg-green tuyo-btn w-100 px-4 py-2 rounded text-light font-weight-bold"
                  onClick={showInvoice}
                >
                  Generar comprobante
                </button>
              </div>
            </div>
          </div>
          <hr />
        </>
      )}
    </Modal>
  );
}

export default OrderApproval;
