import 'react-toastify/dist/ReactToastify.css';
import 'react-datetime/css/react-datetime.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0';
import './assets/css/demo.css';
import './assets/css/pe-icon-7-stroke.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import AdminLayout from 'layouts/Admin.jsx';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { CookiesProvider } from 'react-cookie';
import EnvironmentProvider from 'context/environment/Provider';
import ForgotPassword from 'views/ForgotPassword';
import Login from 'views/Login.jsx';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';

Bugsnag.start({
  apiKey: 'f20206b4627a2d66a371a60d3c9094ea',
  plugins: [new BugsnagPluginReact()],
});

ReactDOM.render(
  <EnvironmentProvider>
    <CookiesProvider>
      <BrowserRouter>
        <Switch>
          <Route
            path="/forgot-password"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route path="/login" render={(props) => <Login {...props} />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Redirect from="/" to="/login" />
        </Switch>
        <ToastContainer transition={Slide} />
      </BrowserRouter>
      <ReactTooltip effect="solid" />
    </CookiesProvider>
  </EnvironmentProvider>,
  document.getElementById('root')
);
