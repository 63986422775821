import React, { useEffect, useState } from 'react';

import Card from 'components/Card/Card';
import DateSelector from 'components/DateSelector';
import Table from 'components/Table/Table';
import { VOUCHER_ICONS } from 'utils/misc';
import { VOUCHER_STATUS } from 'utils/misc';
import api from 'utils/api';
import { formatAmount } from 'utils/misc';
import { useCookies } from 'react-cookie';

const _startDate = new Date();
const _endDate = new Date();

_startDate.setHours(0, 0, 0, 0);
_startDate.setDate(1);
_endDate.setHours(23, 59, 59, 999);

function DashboardPay() {
  const { REACT_APP_COOKIES_PAYMENT_STORE_ID } = process.env;
  const [cookies] = useCookies([REACT_APP_COOKIES_PAYMENT_STORE_ID]);
  const [lastVoucher, setLastVoucher] = useState([]);
  const [paymentData, setPaymentData] = useState({
    amount: 0,
    total: 0,
  });

  const [selectedDate, setSelectedDate] = useState({
    startDate: _startDate,
    endDate: _endDate,
    key: 'selection',
    open: false,
  });

  const tableColumns = {
    columns: [
      {
        text: 'Pagador',
        className: 'text-center',
        isCustom: true,
        render(payer) {
          return (
            <span>
              {payer?.firstname} {payer?.lastname}
            </span>
          );
        },
      },
      {
        text: 'Fecha',
        key: 'createdAt',
        className: 'text-center',
        isDate: true,
      },
      {
        text: 'Estado',
        key: 'status',
        className: 'text-center',
        isCustom: true,
        render(status) {
          return (
            <div
              style={{
                backgroundColor: VOUCHER_ICONS[status].color,
                fontWeight: 600,
                width: 150,
                margin: 'auto',
              }}
              className="rounded p-1 text-white text-center"
            >
              {VOUCHER_STATUS[status] || ''}
            </div>
          );
        },
      },
      {
        text: 'Concepto',
        key: 'concept',
        className: 'text-center',
      },
      {
        text: 'Monto',
        key: 'amount',
        className: 'text-center',
        isCurrency: true,
      },
    ],
    options: {
      id: 'paymentVouchersId',
    },
  };

  const getVouchersByDate = (startDate = new Date(), endDate = new Date()) => {
    api.payment
      .getPaymentVouchersByDate({
        start: startDate,
        end: endDate,
        paymentStoreId: cookies[REACT_APP_COOKIES_PAYMENT_STORE_ID],
        attributes: [
          'paymentVouchersId',
          'amount',
          'ref',
          'createdAt',
          'status',
          'store',
          'firstname',
          'lastname',
          'concept',
          // 'fistname',
          // 'lastname',
          // 'email',
        ],
      })
      .then((res) => {
        const data = res.data ?? [];
        let _amount = 0;
        let _qty = 0;

        data.forEach((voucher) => {
          if (voucher.status === 'COMPLETED') {
            _amount += voucher.amount;
            _qty += 1;
          }
        });

        setPaymentData({
          amount: _amount,
          total: _qty,
        });
        setLastVoucher(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getVouchersByDate(selectedDate.startDate, selectedDate.endDate);
  }, [selectedDate]);

  return (
    <div>
      <div className="w-full">
        <div className="w-full row">
          <h3 className="col-12 col-md-6 col-lg-4 mb-4 text-dark-blue font-size-2x font-weight-bold">
            Resumen de pagos
          </h3>
          <DateSelector
            className="col-12 offset-lg-4 col-md-6 col-lg-4"
            value={selectedDate}
            onChange={(item) =>
              setSelectedDate({
                ...item.selection,
                edited: true,
              })
            }
            onClose={() =>
              setSelectedDate((prev) => ({ ...prev, open: !prev.open }))
            }
          />
        </div>
        <div className="w-full row">
          <div className="col-12 col-md-6 col-xl-3">
            <Card className="p-4 w-100 mb-4 mb-md-0">
              <i className="material-icons ml-auto text-dark-blue font-size-175x">
                attach_money
              </i>
              <p className="text-dark-blue font-size-175x font-extrabold">
                ${formatAmount(paymentData.amount)}
              </p>
              <small>Ingresos totales</small>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <Card className="p-4 w-100">
              <i className="material-icons ml-auto text-dark-blue">
                receipt_long
              </i>
              <p className="text-dark-blue font-size-175x font-extrabold font-size-175x">
                {paymentData.total}
              </p>
              <small>Total de pagos</small>
            </Card>
          </div>
        </div>
      </div>

      <p className="text-dark-blue font-weight-bold font-size-125x mb-2 mt-4">
        Pagos recientes
      </p>
      <Table
        columns={tableColumns.columns}
        options={tableColumns.options}
        data={lastVoucher}
        pageSize={10}
        errorComponent={
          <div className="text-center mb-2 mt-4">
            <span className="material-icons text-dark-blue font-size-175x">
              error_outline
            </span>
            <p className="text-dark-blue font-weight-bold font-size-125x ">
              Parece que no has recibido pagos en este periodo
            </p>
          </div>
        }
      />
    </div>
  );
}

export default DashboardPay;
