import React from 'react';

function Card(props) {
  return (
    <div
      onClick={event => props.onClick && props.onClick(event)}
      className={'tuyo-card d-flex flex-column ' + (props.className || '')}>
      {props.children}
    </div>
  );
}

export default Card;
