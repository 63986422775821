import React from "react";
import Card from 'components/Card/Card';

import { formatPrice, formatAmount } from '../../utils/misc';

import chartUp from '../../assets/icons/chart-up.svg';
import chartDown from '../../assets/icons/chart-down.svg';

function DashboardTotalCard({
  desc,
  isMoney,
  amount,
  changePctg,
  icon,
  comparisonText,
  showComparison
}) {
  const amountRender = isMoney
    ? formatPrice(amount)
    : formatAmount(amount);

  const changePctgNumber = parseFloat(changePctg);

  return (
    <Card className="h-100">
      <div className="row" style={{ flexGrow: '1' }}>
        <div className="col-8 col-lg-9 col-xl-8 d-flex flex-column h-100">
          <p className="text-gray" style={{ opacity: '0.7', flexGrow: '1' }}>
            {desc}
          </p>
          <p className="text-dark-blue font-size-175x font-weight-bold">
            {amountRender}
          </p>
        </div>
        <div className="col-4 col-lg-3 col-xl-4 d-flex align-items-top justify-content-center">
          <div className="rounded bg-beige w-100 d-flex justify-content-center align-items-center" style={{ height: '60px' }}>
            <img src={icon} alt="" style={{ height: '40px' }} />
          </div>
        </div>
      </div>
      {
        showComparison &&
        <div className="row mt-1">
          <div className="col text-gray" style={{ opacity: 0.7 }}>
            {
              changePctgNumber && changePctgNumber !== 0
                ? (
                  <>
                    <img src={changePctgNumber > 0 ? chartUp : chartDown} style={{ marginRight: '0.5rem' }} alt="" />
                    <small>
                      <span className={changePctgNumber > 0
                        ? 'text-green'
                        : 'text-red'}>
                        {changePctgNumber > 0
                          ? changePctgNumber
                          : changePctgNumber * -1}%
                      </span> {changePctgNumber < 0
                        ? 'menos'
                        : 'más'} que {comparisonText}
                    </small>
                  </>
                )
                : (
                  <small>
                    {
                      changePctgNumber === 0
                        ? 'No hay cambio'
                        : 'Sin datos para comparar'
                    }
                  </small>
                )
            }
          </div>
        </div>
      }
    </Card>
  );
}

export default DashboardTotalCard;
