import React, { useContext, useEffect, useState } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import { Link } from 'react-router-dom';

import api from 'utils/api';

import Card from '../components/Card/Card';

import bgSVG from '../assets/img/login-bg.svg';
import logoSVG from '../assets/img/tuyo_icon.svg';
import PasswordInput from 'components/PasswordInput/PasswordInput';
import { ENVIROMENTS } from 'utils/misc';
import EnvironmentContext from 'context/environment';

const USER_ROLES = {
  STORE_ADMIN: 2,
  STORE_EMPLOYEE: 9, // STORES
};

function Login() {
  const { init } = useContext(EnvironmentContext);
  useEffect(() => {
    document.title = `Inicio de sesión | ${process.env.REACT_APP_TITLE}`;
  }, []);
  const history = useHistory();

  const {
    REACT_APP_COOKIES_JWT,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_STORE_ID,
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
    REACT_APP_VALID_ENVIROMENTS,
  } = process.env;
  const [cookies, setCookie] = useCookies([
    REACT_APP_COOKIES_JWT,
    REACT_APP_COOKIES_USER_ID,
    REACT_APP_COOKIES_PAYMENT_STORE_ID,
    REACT_APP_VALID_ENVIROMENTS,
  ]);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [tryingLogin, setTryingLogin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [attempts, setAttempts] = useState(0);
  const [lastCodeError, setLastCodeError] = useState(null);

  /* componentDidMount */
  useEffect(() => {
    if (cookies[REACT_APP_COOKIES_JWT]) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const state = history.location.state;
      const { nextLocation } = state || {};

      history.push(nextLocation || '/admin/dashboard');
    }
  }, [isLoggedIn]);

  const tryLogin = (event) => {
    event.preventDefault();
    setTryingLogin(true);

    api.employees
      .login({
        username: username.trim(),
        password,
      })
      .then((res) => {
        const JWT = res.data.token;
        const decodedJWT = jwt_decode(JWT);

        const storeId =
          decodedJWT?.user?.store?.storesId ||
          decodedJWT?.user?.store_employee?.store?.storesId;

        const paymentStoreId =
          decodedJWT?.user?.payment_stores?.[0]?.paymentStoresId;

        const validEnv =
          storeId && paymentStoreId
            ? ENVIROMENTS.BOTH
            : storeId
            ? ENVIROMENTS.MARKETPLACE
            : paymentStoreId
            ? ENVIROMENTS.PAYMENT
            : '';

        if (
          ![USER_ROLES.STORE_ADMIN, USER_ROLES.STORE_EMPLOYEE].includes(
            decodedJWT.user.role.rolesId
          )
        ) {
          setTryingLogin(false);
          toast.error('El usuario no tiene suficientes permisos');
          return;
        }

        setCookie(REACT_APP_COOKIES_JWT, JWT, { path: '/' });
        setCookie(REACT_APP_COOKIES_USER_ID, decodedJWT.user.usersId, {
          path: '/',
        });
        setCookie(REACT_APP_COOKIES_STORE_ID, storeId, {
          path: '/',
        });

        setCookie(REACT_APP_COOKIES_PAYMENT_STORE_ID, paymentStoreId, {
          path: '/',
        });

        setCookie(REACT_APP_VALID_ENVIROMENTS, validEnv, {
          path: '/',
        });

        setTimeout(() => {
          init('', validEnv);
        }, 500);

        setIsLoggedIn(true);
      })
      .catch((err) => {
        if (!err.response) {
          console.error(err);
          return;
        }

        const errorCode =
          err.response.status === 401
            ? err.response.data.response === 'Invalid username'
              ? '401U'
              : err.response.data.response === 'Password incorrect.'
              ? '401P'
              : err.response.data.status || err.response.status
            : err.response.data.status || err.response.status;

        const errors = {
          '401U': {
            type: 'error',
            text: 'Usuario incorrecto, intente de nuevo',
          },
          '401P': {
            type: 'error',
            text: 'Contraseña incorrecta, intente de nuevo',
          },
          401: {
            type: 'error',
            text: 'Usuario o contraseña incorrectos, intente de nuevo',
          },
          498: {
            type: 'error',
            text: 'Error en la hora o fecha del dispositivo (ERROR 498)',
          },
          499: {
            type: 'error',
            text: 'Token expirado (ERROR 499)',
          },
          500: {
            type: 'warning',
            text: '[SERVER_ERROR] Intente de nuevo más tarde',
          },
        };

        const error = errors[errorCode];
        toast[error?.type](error?.text);
        setAttempts((prev) => prev + 1);
        setLastCodeError(err.response.data.status || err.response.status);
      })
      .finally(() => {
        setTryingLogin(false);
      });
  };

  return (
    isLoggedIn === false && (
      <div
        className="tuyo-login d-flex align-items-center justify-content-center bg-beige"
        style={{
          backgroundImage: `url('${bgSVG}')`,
        }}
      >
        <div className="row w-100 justify-content-center">
          <div className="col-11 col-md-8 col-lg-6 col-xl-4 ">
            <Card className="py-5 px-4 px-md-5">
              <form onSubmit={(e) => tryLogin(e)}>
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <img
                      src={logoSVG}
                      alt=""
                      style={{ width: '128px', height: '128px' }}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <p className="text-center text-gray">Hola, de nuevo</p>
                    <p
                      style={{ lineHeight: '1.25' }}
                      className="text-center font-size-2x font-weight-bold text-dark-blue"
                    >
                      Ingrese a su cuenta
                    </p>
                  </div>
                  <div className="col-12 mt-4">
                    <label
                      className="d-block text-green font-weight-bold"
                      htmlFor="username"
                    >
                      Usuario
                    </label>
                    <input
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="w-100 p-2 rounded"
                      type="text"
                      autoComplete="username"
                      autoFocus
                    />

                    <label
                      className="d-block text-green font-weight-bold mt-3"
                      htmlFor="password"
                    >
                      Contraseña
                    </label>
                    <PasswordInput
                      id="password"
                      autoComplete="current-pasword"
                      onChange={(value) => setPassword(value)}
                    />
                  </div>
                  <div className="col-12 mt-4 mt-md-5">
                    <button
                      disabled={
                        !username.trim() || !password.trim() || tryingLogin
                      }
                      type="submit"
                      onClick={tryLogin}
                      className="text-white font-weight-bold bg-green w-100 p-2 rounded tuyo-btn d-flex align-items-center justify-content-around"
                    >
                      {tryingLogin ? 'Iniciando' : 'Iniciar'} sesión
                      <BounceLoader
                        color="#fff"
                        loading={tryingLogin}
                        size="18"
                      />
                    </button>
                  </div>
                  {attempts >= 3 && (
                    <div className="col-12">
                      <p className="text-center text-red">
                        {lastCodeError === 498 ? (
                          <>
                            Puedes verificar la hora y fecha de tu dispositivo
                            en el siguiente link:&nbsp;
                            <a
                              href="https://time.is/"
                              target="_blank"
                              rel="noreferrer"
                              className="text-red underline"
                            >
                              <u>time.is</u>
                            </a>
                          </>
                        ) : lastCodeError === 499 ? (
                          'Parece que tu sesión ha expirado, por favor recarga la página'
                        ) : (
                          <>
                            Parece que tienes problemas para iniciar
                            sesión,&nbsp; intenta recuperar tu contraseña o
                            contacta a nuestro&nbsp;
                            <a
                              href="https://api.whatsapp.com/send?phone=50378087476&text=Hola,%20tengo%20problemas%20para%20iniciar%20sesión%20en%20Dashboard%20de%20Comercios"
                              target="_blank"
                              rel="noreferrer"
                              className="text-red underline"
                            >
                              <u>equipo de soporte</u>
                            </a>
                          </>
                        )}
                      </p>
                    </div>
                  )}
                  <div className="col-12 mt-3">
                    <Link to="/forgot-password">
                      <p className="text-right">Olvidé mi contraseña</p>
                    </Link>
                  </div>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    )
  );
}

export default Login;
