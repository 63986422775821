import S3 from 'aws-sdk/clients/s3';
import { createStaticRanges } from 'react-date-range';
import jwt_decode from 'jwt-decode';
import imageCompression from 'browser-image-compression';
import { toast } from 'react-toastify';
import { STATUS } from 'assets/resources/status';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const amountFormatter = new Intl.NumberFormat('en-US');

const genRandomData = (qty) => {
  let data = [];

  /* Fill an array of length 31 with random data between 0 and 100 */
  for (let i = 1; i <= qty; i++) {
    data.push(parseInt(Math.random() * 100));
  }

  /* Constrain data bewteen 30 and 70 */
  data = data.map((point) => (point < 30 ? point + 30 : point));
  data = data.map((point) => (point > 70 ? point - 30 : point));

  return data;
};

function validSKU(value) {
  const SKUMaxLength = 20;

  return value.length <= SKUMaxLength
    ? value.toUpperCase()
    : value.slice(0, SKUMaxLength);
}

const validPrice = (value) => value.replace(/[^0-9.]/g, '');

const validQty = (value) => value.replace(/[^0-9]/g, '');

const validPctg = (value) => {
  const numbers = value.replace(/[^0-9.]/g, '');
  return numbers > 100 ? numbers.substring(0, numbers.length - 1) : numbers;
};

const fileToBase64URL = async (file) => {
  const maxFileSize = 2;
  const maxCompressionSize = 0.9;

  const _imageSize = file.size / 1024 / 1024;

  if (_imageSize > maxFileSize) {
    toast.error('El tamaño de la imagen no puede ser mayor a 2MB');
    return Promise.reject('Image too big');
  }

  const _compresedFile = await compressImage(file);
  const _compresedFileSize = _compresedFile.size / 1024 / 1024;

  if (_compresedFileSize > maxCompressionSize) {
    toast.error(
      'Imagen demasiado grande, comprime la imagen y vuelve a intentarlo'
    );
    return Promise.reject('Image too big');
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(_compresedFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject('Error converting file to base64 URL');
  });
};

const uploadPictureToS3 = (base64file, type, name) => {
  const {
    REACT_APP_IMAGE_KIT_URL,
    REACT_APP_SPACES_ENDPOINT,
    REACT_APP_SPACES_ACCESS_KEY_ID,
    REACT_APP_SPACES_SECRET_ACCESS_KEY,
  } = process.env;

  const spacesBucket = new S3({
    endpoint: REACT_APP_SPACES_ENDPOINT,
    accessKeyId: REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: REACT_APP_SPACES_SECRET_ACCESS_KEY,
  });

  const fileKey = `${new Date().getTime().toString()}-${name
    .trim()
    .replace(/\s/g, '-')}`;

  return new Promise(async (resolve, reject) => {
    spacesBucket.putObject(
      {
        Bucket: 'tuyomedia',
        Key: fileKey,
        Body: await (await fetch(base64file)).blob(),
        ACL: 'public-read',
        ContentType: type,
      },
      (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(`https://${REACT_APP_IMAGE_KIT_URL}/${fileKey}`);
        }
      }
    );
  });
};

const deleteFromS3 = (objectKey) => {
  const {
    REACT_APP_SPACES_ENDPOINT,
    REACT_APP_SPACES_ACCESS_KEY_ID,
    REACT_APP_SPACES_SECRET_ACCESS_KEY,
  } = process.env;

  const spacesBucket = new S3({
    endpoint: REACT_APP_SPACES_ENDPOINT,
    accessKeyId: REACT_APP_SPACES_ACCESS_KEY_ID,
    secretAccessKey: REACT_APP_SPACES_SECRET_ACCESS_KEY,
  });

  return new Promise((resolve, reject) => {
    const imageToDelete = objectKey.split('/').pop();

    spacesBucket.deleteObject(
      {
        Bucket: 'tuyomedia',
        Key: imageToDelete,
      },
      (err, data) => {
        if (err) {
          reject(err);
          console.error(err);
        } else {
          resolve(data);
        }
      }
    );
  });
};

const formatPrice = (value) => {
  return currencyFormatter.format(value);
};

const formatAmount = (value) => {
  return amountFormatter.format(value);
};

const formatTimestamp = (timestamp) => {
  return new Date(timestamp).toLocaleString(undefined, {
    dateStyle: 'medium',
    timeStyle: 'short',
    hour12: true,
  });
};

const sortByDate = (array, field, mode) => {
  return array.sort((a, b) => {
    const aDate = new Date(a[field]);
    const bDate = new Date(b[field]);

    if (mode === 'asc') {
      return aDate > bDate ? 1 : aDate < bDate ? -1 : 0;
    } else if (mode === 'desc') {
      return aDate < bDate ? 1 : aDate > bDate ? -1 : 0;
    }
  });
};

const genChartGradient = (ctx2d, x0, y0, x1, y1, rgbValues) => {
  const gradient = ctx2d.createLinearGradient(x0, y0, x1, y1);

  gradient.addColorStop(0, `rgba(${rgbValues}, 1)`);
  gradient.addColorStop(0.25, `rgba(${rgbValues}, 0.75)`);
  gradient.addColorStop(1, 'rgba(255,255,255,0)');

  return gradient;
};

/* Set date's time to  00:00:00 */
const setTimeTo0 = (date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
};

/* Return first day of previous month, and first and last day of date's month */
const getMonthsDates = (date) => {
  setTimeTo0(date);
  const currMonth1stDay = new Date(date);
  currMonth1stDay.setDate(1);

  const currMonthLastDay = new Date(date);
  currMonthLastDay.setMonth(date.getMonth() + 1);
  currMonthLastDay.setDate(1);

  const prevMonth1stDay = new Date(date);
  prevMonth1stDay.setMonth(date.getMonth() - 1);
  prevMonth1stDay.setDate(1);

  return {
    currPeriod1stDay: currMonth1stDay,
    currPeriodLastDay: currMonthLastDay,
    prevPeriod1stDay: prevMonth1stDay,
  };
};

/* Return first day of previous weeek, and first and last day of date's week */
const getWeeksDates = (date) => {
  setTimeTo0(date);
  const day = date.getDay();
  const currWeek1stDay = new Date(date);
  currWeek1stDay.setDate(date.getDate() - day);

  const currWeekLastDay = new Date(date);
  currWeekLastDay.setDate(date.getDate() + (8 - day));

  const prevWeek1stDay = new Date(date);
  prevWeek1stDay.setDate(prevWeek1stDay.getDate() - 7);

  return {
    currPeriod1stDay: currWeek1stDay,
    currPeriodLastDay: currWeekLastDay,
    prevPeriod1stDay: prevWeek1stDay,
  };
};

/* Return today, yesterday and tomorrow dates */
const getDaysDates = (date) => {
  setTimeTo0(date);

  const tomorrow = new Date(date);
  tomorrow.setDate(date.getDate() + 1);

  const yesterday = new Date(date);
  yesterday.setDate(date.getDate() - 1);

  return {
    currPeriod1stDay: date,
    currPeriodLastDay: tomorrow,
    prevPeriod1stDay: yesterday,
  };
};

const isEmptyObject = (object) => Object.keys(object).length === 0;
const getOrderCode = (orderId) =>
  (orderId * 64).toString(16).padStart(6, '0').toUpperCase();

function updateSearchParam(name, value) {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has(name)) {
    searchParams.set(name, value);
  } else {
    searchParams.append(name, value);
  }

  window.history.replaceState(
    null,
    '',
    `${window.location.pathname}?${searchParams.toString()}`
  );
}

const parseDate = (
  date,
  { timeStyle = 'short', dateStyle = 'short', hour12 = true } = {}
) => {
  return new Date(date).toLocaleString('en', {
    ...(dateStyle && { dateStyle }),
    ...(timeStyle && { timeStyle }),
    ...(hour12 && { hour12 }),
    timeZone: 'America/El_Salvador',
  });
};

const getStoreNameFromJWT = (token) => {
  const jwt = jwt_decode(token);
  const {
    user: { payment_stores },
  } = jwt;

  if (payment_stores.length > 0) {
    return payment_stores[0].name;
  }
};

const VOUCHER_STATUS = {
  COMPLETED: 'COMPLETADO',
  PENDING: 'PENDIENTE',
  UNALLOWED: 'NO PERMITIDO',
  REFUNDED: 'RECHAZADO',
  ERRORED: 'ERROR',
};

const VOUCHER_ICONS = {
  COMPLETED: {
    name: 'check_circle',
    color: '#5bc989',
  },
  PENDING: {
    name: 'watch_later',
    color: '#f7c574',
  },
  UNALLOWED: {
    name: 'warning',
    color: '#f7c574',
  },
  REFUNDED: {
    name: 'change_circle',
    color: '#ffc0cb',
  },
  ERRORED: {
    name: 'error',
    color: '#ff9cad',
  },
};

const ENVIROMENTS = {
  PAYMENT: 'payment',
  MARKETPLACE: 'marketplace',
  BOTH: 'both',
};

const dateStaticRanges = createStaticRanges([
  {
    label: 'Hoy',
    range: () => {
      const end = new Date();
      const start = new Date();

      return {
        startDate: start,
        endDate: end,
      };
    },
  },
  {
    label: 'Ayer',
    range: () => {
      const end = new Date();
      const start = new Date();
      start.setDate(start.getDate() - 1);
      end.setDate(end.getDate() - 1);
      return {
        startDate: start,
        endDate: end,
      };
    },
  },
  {
    label: 'Últimos 7 días',
    range: () => {
      const end = new Date();
      const start = new Date();
      start.setDate(start.getDate() - 6);

      return {
        startDate: start,
        endDate: end,
      };
    },
  },
  {
    label: 'Últimos 30 días',
    range: () => {
      const end = new Date();
      const start = new Date();
      start.setDate(start.getDate() - 29);

      return {
        startDate: start,
        endDate: end,
      };
    },
  },
  {
    label: 'Este mes',
    range: () => {
      const end = new Date();
      const start = new Date();
      start.setDate(1);

      return {
        startDate: start,
        endDate: end,
      };
    },
  },
  {
    label: 'Mes pasado',
    range: () => {
      const end = new Date();
      const start = new Date();
      start.setMonth(start.getMonth() - 1);
      start.setDate(1);
      end.setDate(0);

      return {
        startDate: start,
        endDate: end,
      };
    },
  },
  {
    label: 'Este año',
    range: () => {
      const end = new Date();
      const start = new Date();
      start.setMonth(0);
      start.setDate(1);

      return {
        startDate: start,
        endDate: end,
      };
    },
  },
  {
    label: 'Año pasado',
    range: () => {
      const end = new Date();
      const start = new Date();
      start.setFullYear(start.getFullYear() - 1);
      start.setMonth(0);
      start.setDate(1);
      end.setFullYear(end.getFullYear() - 1);
      end.setMonth(11);
      end.setDate(31);

      return {
        startDate: start,
        endDate: end,
      };
    },
  },
]);

async function compressImage(file) {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(file, options);

    return compressedFile;
  } catch (error) {
    console.error(error);
    return file;
  }
}

const isEmail = (email) => {
  // eslint-disable-next-line prefer-regex-literals
  const regex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
  return regex.test(email)
}

function validCouponCode(value) {
  return value.replace(/\s/g, '').toUpperCase();
}

function deconvertId(id) {
  return parseInt(id, 16) - 32;
}

function isValidHttpUrl(string) {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

function getGoogleMapsUrFromCoordinates(lat, lng) {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
}

const getOrderStatus = (details) => {
  const {
    ORDER_PENDING,
    ORDER_ACCEPTED,
    PICKED_UP,
    FINISHED,
    NON_PICKED_UP,
    NON_FINISHED,
    ORDER_U_CANCELED,
    ORDER_S_CANCELED,
    RETURN_PENDING,
    RETURN_APPROVED,
    RETURN_DENIED,
    REFUND_PENDING,
    REFUND_APPROVED,
    REFUND_DENIED,
    READY,
    DELIVERED_IN_WAREHOUSE,
    ON_ROUTE,
    CANCELED_IN_DELIVERY_POINT,
    ON_THE_WAY_TO_STORE,
    WAITING_IN_STORE,
  } = STATUS;

  const statuses = [
    ORDER_PENDING,
    ORDER_ACCEPTED,
    PICKED_UP,
    FINISHED,
    NON_PICKED_UP,
    NON_FINISHED,
    ORDER_U_CANCELED,
    ORDER_S_CANCELED,
    RETURN_PENDING,
    RETURN_APPROVED,
    RETURN_DENIED,
    REFUND_PENDING,
    REFUND_APPROVED,
    REFUND_DENIED,
    READY,
    DELIVERED_IN_WAREHOUSE,
    ON_ROUTE,
    CANCELED_IN_DELIVERY_POINT,
    ON_THE_WAY_TO_STORE,
    WAITING_IN_STORE,
  ];
  let orderStatus;
  statuses.forEach((status) => {
    if (details.some((detail) => detail.fk_purchaseStatusesId === status)) {
      orderStatus = status;
    }
  });

  if (!orderStatus) orderStatus = STATUS.ORDER_S_CANCELED;

  return orderStatus;
};

const verifyAmazonASIN = (asin = '') => {
  const regex = /B[0-9]{2}[0-9A-Z]{7}|[0-9]{9}(X|0-9])/;
  return regex.test(asin);
};

export {
  genRandomData,
  validSKU,
  validPrice,
  validPctg,
  compressImage,
  fileToBase64URL,
  uploadPictureToS3,
  formatPrice,
  formatAmount,
  formatTimestamp,
  sortByDate,
  validQty,
  genChartGradient,
  deleteFromS3,
  getMonthsDates,
  getWeeksDates,
  getDaysDates,
  isEmptyObject,
  getOrderCode,
  updateSearchParam,
  parseDate,
  VOUCHER_STATUS,
  VOUCHER_ICONS,
  getStoreNameFromJWT,
  ENVIROMENTS,
  dateStaticRanges,
  isEmail,
  validCouponCode,
  deconvertId,
  isValidHttpUrl,
  getGoogleMapsUrFromCoordinates,
  getOrderStatus,
  verifyAmazonASIN,
};
