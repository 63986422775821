import { DateRange } from 'react-date-range';
import { Popover } from 'react-tiny-popover';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { dateStaticRanges } from 'utils/misc';
import { parseDate } from 'utils/misc';
import { useState } from 'react';

function TableDateSelector({ onChange, className }) {
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
    selected: false,
  });

  const clean = () => {
    setSelection((prev) => ({
      ...prev,
      selected: false,
    }));
  };

  const onRangeChange = (item) => {
    const { selection } = item;

    const _selection = {
      ...selection,
      selected: true,
      startDate: new Date(selection?.startDate),
      endDate: new Date(selection?.endDate),
    };

    _selection.startDate.setHours(0, 0, 0, 0);
    _selection.endDate.setHours(23, 59, 59, 999);

    const _item = {
      ...item,
      selection: _selection,
    };

    setSelection(_item.selection);
    if (onChange) onChange(_item.selection);
  };

  return (
    <Popover
      isOpen={open}
      positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
      padding={4}
      align="center"
      containerStyle={{
        margin: '-1rem 0 0 0',
      }}
      onClickOutside={() => setOpen(false)}
      containerClassName="shadow-lg"
      content={
        <div className="d-relative">
          <DateRange
            onChange={onRangeChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={[selection]}
            staticRanges={dateStaticRanges}
            direction="vertical"
            rangeColors={['#06c965']}
            color="#06c965"
          />
        </div>
      }
    >
      <div
        onClick={() => setOpen(true)}
        className={`cursor-pointer rounded position-relative ${
          className ?? ''
        }`}
      >
        <div className="text-center font-bold d-flex">
          <div className="d-flex">
            <input
              type="text"
              className="text-center"
              value={
                selection?.selected
                  ? `${parseDate(selection.startDate, {
                      timeStyle: null,
                    })} - ${parseDate(selection.endDate, {
                      timeStyle: null,
                    })}`
                  : ''
              }
            />
            {selection?.selected && (
              <button
                data-tip="Limpiar"
                className="ml-2"
                onClick={(evt) => {
                  evt.stopPropagation();
                  clean();
                }}
              >
                <span className="material-icons">delete</span>
                <ReactTooltip />
              </button>
            )}
          </div>
        </div>
      </div>
    </Popover>
  );
}

export default TableDateSelector;
